import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';

const SmallWrap = styled.div`
  ${media.mobileSmall`
    transform: scale(0.8);
    transform-origin: right bottom;
  `}
`;


class Legend extends Component {
  render() {
    return (
      <SmallWrap>
        <svg width="303" height="186" viewBox="0 0 303 186" fill="none">
          <g filter="url(#filter0_d)">
          <rect width="300" height="183" rx="10" fill="black"/>
          <rect x="0.5" y="0.5" width="299" height="182" rx="9.5" stroke="#343434"/>
          </g>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="12" y="152.66">Less&#10;</tspan><tspan x="12" y="164.16">Changes</tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="64.813" y="84.6605"> Click the gray&#x2028;</tspan><tspan x="18.4336" y="96.1605">spots to identify changes.</tspan></text>
          <text fill="white" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="25.314" y="84.6605">No Data:</tspan></text>
          <circle cx="18" cy="127" r="7" fill="#051E0E"/>
          <circle cx="41" cy="127" r="7" fill="#434721"/>
          <circle cx="64" cy="127" r="7" fill="#726028"/>
          <circle cx="86" cy="127" r="7" fill="#9B7031"/>
          <circle cx="109" cy="127" r="7" fill="#C3793A"/>
          <circle cx="132" cy="127" r="7" fill="#FF6044"/>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="157" y="83.6605">Year of </tspan><tspan x="157" y="95.1605">Appear</tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="159" y="152.66">Disappeared</tspan><tspan x="159" y="164.16">Stores&#10;</tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="249.903" y="83.6605">Year of</tspan><tspan x="236.368" y="95.1605">Disappear</tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="194.421" y="65.6605">2014-2019</tspan></text>
          <text fill="#E1E1E1" fontFamily="Source Sans Pro" fontSize="12" fontWeight="600" letterSpacing="0em"><tspan x="167.455" y="53.016">Spreadhouse Coffee</tspan></text>
          <text fill="#FF6746" fillOpacity="0.6" fontFamily="Source Sans Pro" fontSize="12" fontWeight="600" letterSpacing="0em"><tspan x="167.455" y="130.016">Spreadhouse Coffee</tspan></text>
          <text fill="#FF6746" fontFamily="Source Sans Pro" fontSize="13" fontWeight="600" letterSpacing="0em"><tspan x="12" y="28.3715">Street View Spots</tspan></text>
          <circle cx="75" cy="54" r="7" fill="#C4C4C4"/>
          <text fill="#FF6746" fontFamily="Source Sans Pro" fontSize="13" fontWeight="600" letterSpacing="0em"><tspan x="152" y="28.3715">Places</tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" fontSize="11" letterSpacing="0em"><tspan x="114.937" y="152.66">More&#10;</tspan><tspan x="98.6836" y="164.16">Changes</tspan></text>
          <path d="M201 83C206.707 83 210 81.3704 210 75.6667V72" stroke="#AFAFAF"/>
          <path d="M206 76.5L210 71L214 76.5" stroke="#AFAFAF"/>
          <path d="M219 151C224.707 151 228 149.37 228 143.667V140" stroke="#AFAFAF"/>
          <path d="M224 144.5L228 139L232 144.5" stroke="#AFAFAF"/>
          <path d="M240 83C236.195 83 234 81.3704 234 75.6667V72" stroke="#AFAFAF"/>
          <path d="M238 76L234 71L230 76" stroke="#AFAFAF"/>
          <path d="M55.5 50L61 54L55.5 58" stroke="#AFAFAF"/>
          <path d="M61 54C48 54 40.5 56 40.5 63V67.5" stroke="#AFAFAF"/>
          <g transform="translate(265 17)"  style={{cursor: "pointer"}} onClick={this.props.handleMinimize}>
            <rect x="0.5" y="0.5" width="17" height="17" stroke="#333" fill="black"/>
            <path d="M4 9H14" stroke="#C8C8C8"/>
          </g>
          <defs>
          <filter id="filter0_d" x="0" y="0" width="303" height="186" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dx="3" dy="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.403922 0 0 0 0 0.27451 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
        </svg>
      </SmallWrap>
     

    )
  }
}

export default Legend;