import React, { Component } from 'react'
import { connect } from 'react-redux';
import { PoiLabel, StoryWindow, Story, NearestStreetview } from './';
import { changeActivePanoIDScreen, changeGSVPOV, changeIdentificationCategory, changeCurrentStreetview, changeIdentifyPlaceStep, changeIdentifyPov, changeStoryStep, changeStreetviewTab, changeSelectedPanoidIndex } from '../actions';
import styled from 'styled-components';
import { STORY_CATEGORY, ZOOMFACTOR } from '../constants/defaults';
import moment from 'moment';
import { DateIndicator, PositionIndicator } from '../stylesheets/components';
import { findNearestPanoID, toRadians, panoDateToRichDate } from '../utils';
import PointCloud from './PointCloud';

const Container = styled.div`
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  color: white;
  overflow: hidden;
`;


class StoryPanoIDViewer extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.handlePOVChanged = this.handlePOVChanged.bind(this);
    this.handlePositionChanged = this.handlePositionChanged.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);

    this.state = {
      mouseLocation: [0, 0],
      lastMouseLocation: [0, 0]
    };

    this.pointCloud = new PointCloud();

    this.pov = null;
  }

  removeDevSigns(){
    this.intervalCount = 0;
    this.devSignIntervalId = setInterval(() => {
      let iterateCheck = false;

      var xpath = "//div[text()='For development purposes only']";
      var matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
      var dismissButtonElem = document.querySelector(".dismissButton");

      if (dismissButtonElem || 
          matchingElement) {
          
        if (dismissButtonElem) {
          dismissButtonElem.parentElement.parentElement.parentElement.parentElement.remove();
        } 
        if (matchingElement) {
          matchingElement.remove();
        }
        
      }
      
      this.intervalCount++;

      if (this.intervalCount > 20){
        clearInterval(this.devSignIntervalId);
      }

    }, 500);

  }


  componentDidMount() {
    this.removeDevSigns();
    let { currentPanoID } = this.props;
    let domElement = this.containerRef.current;
    this.panorama = new google.maps.StreetViewPanorama(domElement, {
      pano: currentPanoID.pano_id,
      visible: true,
      disableDefaultUI: true,
      clickToGo: false
    });

    this.pointCloud.getPointCloud(currentPanoID.pano_id);
    google.maps.event.addListener(this.panorama, 'pov_changed', this.handlePOVChanged);
    google.maps.event.addListener(this.panorama, 'zoom_changed', this.handlePOVChanged);

    domElement.addEventListener('mousemove', this.handleMouseMove, false);
    document.addEventListener('mouseup', this.handlePointerUp, false);


  }

  componentWillUnmount() {
    let domElement = this.containerRef.current;
    google.maps.event.clearListeners(this.panorama, 'pov_changed');
    google.maps.event.clearListeners(this.panorama, 'zoom_changed');

    clearInterval(this.devSignIntervalId);

    

    // debugger;
  }

  handlePositionChanged(e) {
    // debugger;
  }


  handlePOVChanged(e) {

    let { activePanoIDScreen, screenID } = this.props;

    let pov = this.panorama.getPov();

    this.props.dispatch(changeGSVPOV(pov));


  }


  componentDidUpdate(prevProps) {
    let { width, windowHeight, currentPanoID, currentStreetview, activePanoIDScreen, screenID, isCurrentPanoNow } = this.props;

    let domElement = this.containerRef.current;
    let gmStyle = domElement.querySelector(".gm-style");
    if (gmStyle) {

      if (this.props.isCurrentPanoNow) {
        gmStyle.style.filter = "hue-rotate(180deg)";
      } else {
        gmStyle.style.filter = "none";
      }
      
    }

    if (this.props.currentPanoID) {
      if (prevProps.currentPanoID) {
        if (prevProps.currentPanoID.id !== this.props.currentPanoID.id) {
          this.updateCurrentPanoID();
        }
      } else {
        this.updateCurrentPanoID();
      }
    }


    if (this.isPOVChanged(prevProps.GSVPOV, this.props.GSVPOV)) {
      this.panorama.setOptions({
        pov: this.props.GSVPOV
      });
    }

    if (this.props.identificationActivated) {

      _.each(document.querySelectorAll(".widget-scene"), elem => {
        elem.style.cursor = "crosshair !important";
      });
    }

    if (!prevProps.currentPoi && this.props.currentPoi) {
      
      this.updateDirection(this.props.currentPoi);

    } else {
      
      if (prevProps.currentPoi && this.props.currentPoi) {

        if (prevProps.currentPoi.gid !== this.props.currentPoi.gid) {

          this.updateDirection(this.props.currentPoi);

        }

      }
    }
  }

  updateDirection(currentPoi) {
    let { currentPanoID, GSVPOV } = this.props;
    let panoIDPoint = new google.maps.LatLng(currentPanoID.location[1], currentPanoID.location[0]);
    let poiPoint = new google.maps.LatLng(currentPoi.location[1], currentPoi.location[0]);

    let heading = google.maps.geometry.spherical.computeHeading(panoIDPoint, poiPoint);
    let newPov = {
      heading: heading,
      pitch: GSVPOV.pitch,
      zoom: GSVPOV.zoom
    };
    this.panorama.setOptions({
      pov: newPov
    });

    this.props.dispatch(changeGSVPOV(newPov));

    // now change to first appearance of the poi, if available
    let panoIdIdx = findNearestPanoID(currentPoi, this.props.currentStreetview);
    this.props.dispatch(changeSelectedPanoidIndex(panoIdIdx));
  }


  isPOVChanged(prevGSVPOV, GSVPOV) {
    return prevGSVPOV.zoom !== GSVPOV.zoom ||
      prevGSVPOV.pitch !== GSVPOV.pitch ||
      prevGSVPOV.heading !== GSVPOV.heading;
  }

  updateCurrentPanoID() {
    let { currentPanoID } = this.props;
    this.panorama.setPano(currentPanoID.pano_id);
    this.pointCloud.getPointCloud(currentPanoID.pano_id);
  }


  handlePosClick(e) {

    let { storyStep, GSVPOV, currentPoi, mode } = this.props;

    if (storyStep === 1) {

      let pos = [e.clientX, e.clientY];
      let { windowWidth, screenID } = this.props;


      if (mode === "half") {
        pos[0] = pos[0] - windowWidth * 0.5;
      }

      let pov = this.unproject(pos[0], pos[1]);
      let imagePos = this.calculateImageCoordinateFromPointPov({
        ...pov,
        zoom: GSVPOV.zoom
      });
      let latLng = this.toLatLng(imagePos, this.pointCloud);
      

      this.props.dispatch(changeIdentifyPov({
        pov: pov,
        imageCoordinate: imagePos,
        pickedLatLng: latLng
      }));


      if (currentPoi) {
        this.props.dispatch(changeStoryStep(3));
      } else {
        this.props.dispatch(changeStoryStep(2));
      }


      this.setState({
        lastMouseLocation: pos
      });
    }
  }

  scaleImageCoordinate(x, y, r) {
    var x_ = x * r;
    var y_ = (3328 - y) * r;
    return { x: x_, y: y_ };
  }

  latlngOffset(lat, dx, dy) {
    var dlat = dy / 111111;
    var dlng = dx / (111111 * Math.cos(toRadians(lat)));
    return { dlat: dlat, dlng: dlng };
  }

  toLatLng(imageCoordinate, pointCloud) {
    let { currentPanoID } = this.props;
    var minDx = 1000, minDy = 1000, i, delta, latlng,
      p, idx, dx, dy, r, minR;

    p = this.scaleImageCoordinate(imageCoordinate.x, imageCoordinate.y, 1 / 26);
    let pc = pointCloud.getPointCloud(currentPanoID.pano_id);

    idx = 3 * (Math.ceil(p.x) + 512 * Math.ceil(p.y));
    dx = pc.pointCloud[idx];
    dy = pc.pointCloud[idx + 1];
    r = dx * dx + dy * dy;
    minR = minDx * minDx + minDy + minDy;

    if (r < minR) {
      minDx = dx;
      minDy = dy;
    }
    delta = this.latlngOffset(currentPanoID.location[1], dx, dy);
    latlng = { lat: currentPanoID.location[1] + delta.dlat, lng: currentPanoID.location[0] + delta.dlng };
    return latlng;
  }

  calculateImageCoordinateFromPointPov(pov) {
    const SV_IMAGE_HEIGHT = 6656;
    const SV_IMAGE_WIDTH = 13312;

    var heading = pov.heading,
      pitch = pov.pitch,
      zoom = Math.max(1, pov.zoom);

    var imageX, imageY;
    var zoomFactor = ZOOMFACTOR[Math.floor(zoom)];

    var svImageWidth = SV_IMAGE_WIDTH * zoomFactor;
    var svImageHeight = SV_IMAGE_HEIGHT * zoomFactor;

    imageX = (svImageWidth * (heading / 360) + ((svImageWidth / 360) / 2)) / zoomFactor;
    imageY = ((svImageHeight / 2) * (pitch / 90)) / zoomFactor;

    return {
      x: imageX,
      y: imageY
    };
  }

  unproject(u, v) {
    function sgn(x) {
      return x >= 0 ? 1 : -1;
    }

    let { GSVPOV, width, windowHeight } = this.props;
    
    var PI = Math.PI;
    var cos = Math.cos;
    var sin = Math.sin;
    var tan = Math.tan;
    var sqrt = Math.sqrt;
    var atan2 = Math.atan2;
    var asin = Math.asin;

    var fov = 180 / Math.pow(2, GSVPOV.zoom) * PI / 180.0;
    var height = windowHeight;

    var h0 = GSVPOV.heading * PI / 180.0;
    var p0 = GSVPOV.pitch * PI / 180.0;

    var f = 0.5 * width / tan(0.5 * fov);

    var x0 = f * cos(p0) * sin(h0);
    var y0 = f * cos(p0) * cos(h0);
    var z0 = f * sin(p0);
    // debugger;

    var du = u - width / 2;
    var dv = height / 2 - v;

    var ux = sgn(cos(p0)) * cos(h0);
    var uy = -sgn(cos(p0)) * sin(h0);
    var uz = 0;

    var vx = -sin(p0) * sin(h0);
    var vy = -sin(p0) * cos(h0);
    var vz = cos(p0);

    var x = x0 + du * ux + dv * vx;
    var y = y0 + du * uy + dv * vy;
    var z = z0 + du * uz + dv * vz;

    var R = sqrt(x * x + y * y + z * z);
    var h = atan2(x, y);
    var p = asin(z / R);

    return {
      heading: h * 180.0 / PI,
      pitch: p * 180.0 / PI
    };
  }




  handleMouseMove(e) {
    let { storyStep, mode } = this.props;

    if (storyStep > 0) {

      let pos = [e.clientX, e.clientY];
      let { windowWidth, screenID } = this.props;

      if (mode === "half") {
        pos[0] = pos[0] - windowWidth * 0.5;
      }
      
      this.setState({
        mouseLocation: pos
      });
    }
  }

  render() {
    let { isCurrentPanoNow, currentPanoID, currentStreetview, width, storyCategory, storyStep } = this.props;
    let { mouseLocation, lastMouseLocation } = this.state;
    let indicatorLocation = mouseLocation;
    
    if (storyStep > 1) { 
      indicatorLocation = lastMouseLocation;
    }
    
    // console.log(indicatorLocation);


    // let pois = _.filter(currentStreetview.pois, poi => poi.is_public);)
    let pois = _.filter(currentStreetview.pois, poi => {
      // // debugger
      // console.log(isCurrentPanoNow, new Date().getTime() / 1000, currentPanoID);
      let panoTimestamp = isCurrentPanoNow ? new Date().getTime() / 1000 : moment.unix(currentPanoID.timestamp).startOf('month').unix();
      

      if (poi.disappeared_at && poi.first_showed_at) {
      
        let poiDisappearedTimestamp = moment(poi.disappeared_at).startOf('month').unix();
        let poiAppearedTimestamp = moment(poi.first_showed_at).startOf('month').unix();
        
        return panoTimestamp >= poiAppearedTimestamp && 
               panoTimestamp <= poiDisappearedTimestamp && 
               poi.is_public;  
      
      } else if (!poi.first_showed_at && poi.disappeared_at){
       
        let poiDisappearedTimestamp = moment(poi.disappeared_at).startOf('month').unix();
        return panoTimestamp <= poiDisappearedTimestamp && 
               poi.is_public;  

      } else if (poi.first_showed_at && !poi.disappeared_at){

        let poiAppearedTimestamp = moment(poi.first_showed_at).startOf('month').unix();
        
        return panoTimestamp >= poiAppearedTimestamp && 
               poi.is_public;  
      } else if (!poi.first_showed_at && !poi.disappeared_at){
        return poi.is_public;
      }
     
    });

    let stories = currentStreetview.stories;
    // _.filter(currentStreetview.stories, story => {
      
    //   let panoTimestamp = now ? new Date().getTime() / 1000 : currentPanoID.timestamp;
      
    //   return story.timestamp <= panoTimestamp;

    // });

    return (
      <Container ref={this.containerRef} style={{left: this.props.mode === "half" ? "50vw" : 0, width: width}} onClick={this.handlePosClick.bind(this)}>
        {
          isCurrentPanoNow ? 
          <DateIndicator>
            Now
          </DateIndicator> :
          (currentPanoID ?
          <DateIndicator>
            { panoDateToRichDate(currentPanoID.date) }
          </DateIndicator> : null)
        }


        {
          _.map(currentStreetview.nearest_streetviews, nearestStreetview => {
            return (
              <NearestStreetview nearestStreetview={nearestStreetview} containerWidth={width} key={nearestStreetview.gid} panoid={currentPanoID} />
            )
          })
        }
        {
          storyStep > 1  ?
          <StoryWindow currentPanoID={currentPanoID} /> : null
        }
        {
          storyStep > 0 ? 
          <PositionIndicator style={{ left: indicatorLocation[0], top: indicatorLocation[1] }}>
            { STORY_CATEGORY[storyCategory].label } 
          </PositionIndicator> : null
        }
        {
          _.map(pois, poi => {


            return (
              <PoiLabel poi={poi} containerWidth={width} key={poi.gid} panoid={currentPanoID} />
            )
          })
        }
        {
          _.map(stories, story => {


            return (
              <Story {...story} containerWidth={width} key={story.id} panoid={currentPanoID} />
            )
          })
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    GSVPOV: state.GSVPOV,
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    storyStep: state.storyStep,
    storyCategory: state.storyCategory,
    currentPoi: state.currentPoi,
    isCurrentPanoNow: state.isCurrentPanoNow
  };
}

export default connect(mapStateToProps)(StoryPanoIDViewer);