import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import moment from 'moment';
import { changeSelectedPanoidIndex, changeStreetviewTab, changeIsCurrentPanoNow, changeStreetviewTutorialPopup } from '../actions';
import { withRouter } from "react-router-dom";
import { BlueButton } from '../stylesheets/components';
import { RED_COLOR, SHADOW_COLOR } from '../constants/defaults';

const Fragment = React.Fragment;

const HeaderContainer = styled.div`
  position: fixed;
  left: ${props => props.mode === "half" ? "calc(50% + 15px)" : "50%" };
  top: 10px;
  transform: ${props => props.mode === "half" ? "translate(0, 0)" : "translate(-50%, 0)" };
  z-index:7;
  padding: 10px 15px;
  width: calc(50% - 30px);
  display: flex;
  min-width: ${props => props.mode === "half" ? "auto" : "800px" };
  justify-content: space-between;
  align-items: center;
  
  background: #000000;
  border: 1px solid ${SHADOW_COLOR};
  box-sizing: border-box;
  box-shadow: 2px 2px 0px #404040;
  border-radius: 10px;

  div.left-wrap {
    display: flex;
    align-items: center;
    width: 200px;
  }
`;

const TitleArea = styled.div`
  font-family: 'Heldane Display';
  color: ${RED_COLOR};
  font-size:1.2em;
  margin-right: 15px;
  a.tutorial {
    margin-top: 5px;
    font-family: "Source Sans Pro";
    font-size: 0.7em;
    color: #AAA;
    text-decoration: underline;

  }
`;

const StreetButton = styled.div`

  color: white;
`;


const Tabmenu = styled.div`
  ${'' /* width: 300px; */}
  color: white;
`;

const Timeline = styled.div`
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  div.left, div.right {
    text-align: center;
    font-family: "Source Sans Pro";
    color: white;
    font-size:0.8em;
    padding: 0 10px;
    width: 100px;
  }
`;

const ClosebtnArea = styled.div`
  
  color: white;
`;


const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#FFFFFF',
    },
  }
})(props => <Tabs {...props} TabIndicatorProps={{ style: { color: "white" }, children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontFamily: "Source Sans Pro",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
  textColorPrimary: {
    color: "#888"
  },
  selected: {
    color: "#FFFFFF !important"
  }
}))(props => <Tab {...props} />);

const IconWhiteButton = withStyles({
  root: {
    color: RED_COLOR
  }
})(IconButton);

const CustomSlider = withStyles({
  thumb: {
    backgroundColor: RED_COLOR
  },
  valueLabel: {
    left: 'calc(-50% - 10px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: "white",
      width: 60,
      fontFamily: "Source Sans Pro"
    },
  },
  track: {
    background: RED_COLOR
  },
  colorPrimary: {
    color: RED_COLOR
  },
  markLabel: {
    display: "none"
  },
  mark: {
    width: 6,
    height: 6,
    transform: "translate(0, -2px)",
    borderRadius: 4
  },
  markActive: {
    background: "rgba(44, 44, 44, 0.5)"
  },
  marked: {
    margin: 0
  },
  rail: {
    background: "rgba(255, 255, 255, 0.5)"
  },
})(Slider);

// const FixedTabButton = styled.button`
//   border:none;
//   background: #E2E2E2;
//   box-shadow: 2px 2px 0px #CFCFCF;
//   border-radius: 10px;
//   color: #A8A8A8;
//   width: 150px;
//   border-radius: 5px;
//   padding: 12px 15px;

//   font-family: "Source Sans Pro";
//   font-weight: 600;
//   font-size: 14px;
//   margin-right: 10px;
//   text-align: center;
//   cursor: pointer;

//   &.selected {
//     background: #1125AA;
//     box-shadow: 2px 2px 0px #E0F7F7;
//     color: white;
//   }
// `;

const TabArea = styled.div`
  position: fixed;
  left: calc(50% - 14px);
  transform: translate(-50%, 0);
  top: 80px;
  z-index: 20;
`;

const TopTabButton = styled(BlueButton)`
  border-radius: 0 0 5px 5px;
  padding-top: 10px;
  background: ${props => props.selected ? "Black" : "#222222"};
`;

class StreetviewHeader extends Component {
  constructor(props){
    super(props);
  }
  handleTabChange(value){

    let { currentStreetview } = this.props;
    
    if (value === 0) {

      this.props.history.push(`/streetviews/${currentStreetview.gid}`);

    } else if (value === 1) {
      
      this.props.history.push(`/streetviews/${currentStreetview.gid}/stories`);

    }
    
  }

  handleClose(e) {
    let { currentStreetview } = this.props;
    document.location.href = currentStreetview.neighborhood_permalink;
    // this.props.history.push(currentStreetview.neighborhood_permalink);

  }
  
  handleNearestStreetViewClick(e) {

    // this.props.history.push(currentStreetview.neighborhood_permalink);
    
  }

  handleTutorialClick(e){
    e.preventDefault();
    this.props.dispatch(changeStreetviewTutorialPopup(true));
  }

  render() {
    let { currentStreetview, selectedPanoIDIndex, streetviewTab, mode } = this.props;
    let marks = _.map(currentStreetview.panoids, (panoid, idx) => {
      return {
        value: panoid.timestamp,
        date: panoid.date,
        label: panoid.date,
        idx: idx
      }
    });

    marks.push({
      value: new Date().getTime() / 1000,
      date: "Now",
      label: "Now",
      idx: marks.length
    });
    
    // console.log("selectedPanoIDIndex", selectedPanoIDIndex, "marks.length", marks.length);

    return (
      <Fragment>
        <HeaderContainer mode={mode}>
          <div className="left-wrap">
          <TitleArea>
            { currentStreetview.street } <br />
            <a href="#" onClick={this.handleTutorialClick.bind(this)} className="tutorial">
              How to contribute?
            </a>
          </TitleArea>

          {/* <StreetButton>
            <Tooltip title="Nearest Street View" placement="top">  
              <IconWhiteButton onClick={this.handleNearestStreetViewClick.bind(this)} color="primary" label="Next"  aria-label="next streetview">
                <NavigateNextIcon fontSize="default" />
              </IconWhiteButton>
            </Tooltip>
          </StreetButton> */}
          </div>
          <Timeline>
            <div className="left">
              {_.first(marks).date}
            </div>
            <CustomSlider
              value={marks[selectedPanoIDIndex].value}
              marks={marks}
              step={null}
              aria-labelledby="discrete-slider-restrict"
              onChange={(e, value) => {
                let { currentStreetview } = this.props;

                let selectedLabelIdx = _.findIndex(currentStreetview.panoids, mark => {
                  return mark.timestamp === value;
                });

                if (selectedLabelIdx === -1) {

                  console.log("you choose NOW");
                  this.props.dispatch(changeIsCurrentPanoNow(true));
                  this.props.dispatch(changeSelectedPanoidIndex(marks.length - 1));

                } else {
                  this.props.dispatch(changeSelectedPanoidIndex(selectedLabelIdx));
                  this.props.dispatch(changeIsCurrentPanoNow(false));
                }
              }}
              min={_.first(marks).value }
              max={_.last(marks).value }
              valueLabelDisplay="on" 
              valueLabelFormat={x => {
                let selectedLabel = _.find(marks, mark => {
                  return mark.value === x;
                })
                try {
                  return selectedLabel.date;
                } catch(e){
                  return "";
                }
              }}
            />
            <div className="right">
              {_.last(marks).date}
            </div>
          </Timeline>


          <ClosebtnArea>
            <IconWhiteButton color="primary" aria-label="prev streetview" onClick={this.handleClose.bind(this)}>
              <CloseIcon fontSize="default" />
            </IconWhiteButton>
          </ClosebtnArea>
        </HeaderContainer>
            
        {
          this.props.mode === "full" ?
          <TabArea>
            <TopTabButton onClick={this.handleTabChange.bind(this, 0)} selected={streetviewTab === 0}>
              Identify Changes
            </TopTabButton>

            <TopTabButton onClick={this.handleTabChange.bind(this, 1)} selected={streetviewTab === 1}>
              Add Stories
            </TopTabButton>
          </TabArea> : null
        }
        

      </Fragment>

    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    selectedPanoIDIndex: state.selectedPanoIDIndex,
    streetviewTab: state.streetviewTab
  }
}

export default withRouter(connect(mapStateToProps)(StreetviewHeader));