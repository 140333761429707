import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Logo } from './';
import { IDENTIFICATION_CATEGORY } from '../constants/defaults';
import { withRouter } from 'react-router';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { LogoLink, ContentLink, PoiStreetView, LabelArea, LeftContainer, Gutter, SmallTitle, BlueButton, ButtonCenter, Tab, TabButton, ListTitle as Title, TitleArea as GlobalTitleArea } from '../stylesheets/components';
import { NeighborhoodIndicator, NeighborhoodSearch, PoiList, IdentificationList, StoryList, StreetviewList } from './';
import { convertTitle, panoDateToRichDate } from '../utils';
import { changeLeftContainerScrollTop, changeProgress } from '../actions';
import { RED_COLOR, SHADOW_COLOR, STORY_CATEGORY } from '../constants/defaults';
import moment from 'moment';
import { truncate } from '../utils';
import { sizes as mobileSizes } from '../stylesheets/media';
const Fragment = React.Fragment;

const MinizedContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 95px;
  z-index: 3;
  padding: 10px;
  background: white;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px #1125AA;
  border-radius: 10px;
`;


const WindowBarArea = styled.div`
  background: white;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid black;
  padding: 7px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WindowBarTitle = styled.div`
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
`;

const FocusedNeighborhood = styled.div`
  padding: 10px 10px 0 10px;
  h2.title {
    font-family: "Heldane Display";
    font-size: 20px;
    line-height: 1.2;
  }
  p.collabo {  
    margin-top: 5px;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  div.neighborhoods-subtitle {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 7px 0;
    margin: 7px 0; 
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;
  }
`;




const NeighborhoodList = styled(Link)`

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #999;
  padding-bottom: 10px;
  padding-top: 0;
  margin-bottom: 10px;
  padding-left: 20px;

  &:last-child {
    border-bottom: none;
  }

  div.left {
    font-family: "Heldane Display";
    font-size: 15px;
    line-height: 1.0;
  }
  
  div.right {
    text-align: right;
  }

  span.small {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;
    color: #828282;
  }

`;

const MinimizeButton = styled.button`
  border: 1px solid black;
  padding: 5px;
  cursor: pointer;
  color: white;
`;


const TutorialArea = styled.div`
  margin: 15px 0 0 0;
  color: #777;
  font-size:0.9em;

  span.white {
    font-weight: 600;
  }
`;


const ContentArea = styled.div`
  height: auto;
`;

const TitleArea = styled(GlobalTitleArea)`
  div.right {
    width: auto;
    text-align: right;
  }
`
class NeighborhoodWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      poiPages: [],
      currentPoiPage: 1,

      identificationPages: [],
      currentIdentificationPage: 1,

      storyPages: [],
      currentStoryPage: 1,

      streetviewPages: [],
      currentStreetviewPages: 1,

      minimize: false,
      pristine: true,
      selectedTab: "notable_changes",
      scrollTop: 0
    }
  }

  componentDidMount() {
  }


  handleMinimize(e) {
    this.setState({
      minimize: true
    });
  }

  handleMaximize(e) {
    this.setState({
      minimize: false
    });
  }

  handleTabButtonClick(value){
    this.setState({
      selectedTab: value
    });
  }

  handlePrev(e){
    this.props.history.push("/contribute");
  }

  togglePristine(pristine){
    this.setState({
      pristine: pristine
    });
  }

  async handleStoryLoadMore(e) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    
    this.props.dispatch(changeProgress(true));
    const response = await fetch(`/neighborhoods/${this.props.currentNeighborhood.permalink}/stories/${this.state.currentStoryPage + 1}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();

      this.setState({
        currentStoryPage: this.state.currentStoryPage + 1,
        storyPages: this.state.storyPages.concat(data.stories)
      });
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }
  }

  async handleIdentificationLoadMore(e){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));

    const response = await fetch(`/neighborhoods/${this.props.currentNeighborhood.permalink}/identifications/${this.state.currentIdentificationPage + 1}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();

      this.setState({
        currentIdentificationPage: this.state.currentIdentificationPage + 1,
        identificationPages: this.state.identificationPages.concat(data.identifications)
      });
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }
  }


  async handleStreetviewLoadMore(e){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    this.props.dispatch(changeProgress(true));

    const response = await fetch(`/neighborhoods/${this.props.currentNeighborhood.permalink}/full_streetviews/${this.state.currentStreetviewPages + 1}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();

      this.setState({
        currentStreetviewPages: this.state.currentStreetviewPages + 1,
        streetviewPages: this.state.streetviewPages.concat(data.full_streetviews)
      });
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }
  }

  async handlePoiLoadMore(e){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));

    const response = await fetch(`/neighborhoods/${this.props.currentNeighborhood.permalink}/pois/${this.state.currentPoiPage + 1}`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      
      this.setState({
        currentPoiPage: this.state.currentPoiPage + 1,
        poiPages: this.state.poiPages.concat(data.pois)
      });
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }

  }

  handleScroll(e){
    this.props.dispatch(changeLeftContainerScrollTop(e.target.scrollTop));
  }

  fullWindow() {
    let { currentNeighborhood, neighborhoodSearchResults } = this.props;

    if (currentNeighborhood) {
      return (
        <LeftContainer onScroll={this.handleScroll.bind(this)}>
            
          <Gutter h={30} />

          <TitleArea>
            <div className="left">
              <h2 className="title">
                {currentNeighborhood.ntaname}
              </h2>
              <p className="small">
                {currentNeighborhood.boro_name}
              </p>
            </div>
            <div className="right">
              <p className="small">
                <span className="white">{currentNeighborhood.identifications_count}</span> changes identified<br/>
                <span className="white">{currentNeighborhood.stories_count}</span> stories added 
              </p>
              <div className="indicator-area">
                {
                  currentNeighborhood ? 
                  <NeighborhoodIndicator totalChanges={currentNeighborhood.total_changes} /> : null
                }
              </div>
            </div>
          </TitleArea>
          {
            this._renderTutorial()
          }
          <NeighborhoodSearch togglePristine={this.togglePristine.bind(this)}/>


          {
            neighborhoodSearchResults.length > 0 ?
            this._renderSearchResults() : this._renderExamples()
          } 

        </LeftContainer>
      );
    } else {
      return null;
    }
   
  }

  _renderTutorial(){
    let { windowWidth } = this.props;
    if (windowWidth >= mobileSizes.mobileSmall) {
      return (
        <TutorialArea>
          <span className="white">Navigate to the streetview points on the map or the "Streetviews" tab to find your favorite (but disappeared) deli, cafe, and bookstore.</span> To see some of the examples of contribution, please click <span className="white">"Notable Changes,"</span> where you may find which store was disappeared and when, and <span className="white">"Memories and Stories"</span> where you can find the stories related to places. 
        </TutorialArea>
      )
    } else {
      return null;
    }
    
  }

  _renderSearchResults(){
    let { neighborhoodSearchResults } = this.props;
    return (
      <Fragment>
        <SmallTitle>
          <span className="blue">{neighborhoodSearchResults.length}</span> results found.
        </SmallTitle>
        <Gutter h={10} />

        <ContentArea>
          {
            _.map(neighborhoodSearchResults, entity => {
              if (entity.type === "Poi") {
                return (
                  <PoiList poi={entity} key={entity.gid} />
                );
              } else if (entity.type === "streetview") {
                return (
                  <StreetviewList streetview={entity} key={entity.gid} />
                )
              }
             
            })
          }
        </ContentArea> 

      </Fragment>
    );
  }

  _renderExamples() {
    let { currentNeighborhood } = this.props;
    // console.log("currentNeighborhood.full_streetviews", currentNeighborhood.full_streetviews);


    return (
      <Fragment>
        {
          this.state.pristine ?
          null :
          <SmallTitle>
            No results found.
          </SmallTitle>
        }
        <SmallTitle>
          Or, view some <span className="blue">stores submitted by people</span> or <span className="blue">notable changes</span> identified by people.
        </SmallTitle>
        <Gutter h={10} />
        <Tab>
          <TabButton selected={this.state.selectedTab === "identified_places"} onClick={this.handleTabButtonClick.bind(this, "identified_places")}>
            Stores
          </TabButton>

          <TabButton selected={this.state.selectedTab === "notable_changes"} onClick={this.handleTabButtonClick.bind(this, "notable_changes")}>
            Notable Changes
          </TabButton>

          <TabButton selected={this.state.selectedTab === "memories_stories"} onClick={this.handleTabButtonClick.bind(this, "memories_stories")}>
            Memories and Stories
          </TabButton>

          <TabButton selected={this.state.selectedTab === "streetviews"} onClick={this.handleTabButtonClick.bind(this, "streetviews")}>
            Streetviews
          </TabButton>
        </Tab>

        {
          this.state.selectedTab === "identified_places" ?
            <ContentArea>
              {
                _.map(currentNeighborhood.pois, poi => {
                  return (
                    <PoiList poi={poi} key={poi.gid} />
                  );
                })
              }
              {
                _.map(this.state.poiPages, poi => {
                  return (
                    <PoiList poi={poi} key={poi.gid} />
                  );
                })
              }
              <ButtonCenter>
                {
                  currentNeighborhood.pois_count <= currentNeighborhood.pois.length + this.state.poiPages.length ? 
                  <Fragment>
                    ·
                  </Fragment> :
                  <BlueButton selected={true} onClick={this.handlePoiLoadMore.bind(this)}>
                    Load More...
                  </BlueButton>
                }
                
              </ButtonCenter>

            </ContentArea> : null
        }

        {
          this.state.selectedTab === "notable_changes" ?
            <ContentArea>
              {
                _.map(currentNeighborhood.identifications, identification => {
                  return (
                    <IdentificationList identification={identification} key={identification.id} />
                  );
                })
              }
              {
                _.map(this.state.identificationPages, identification => {
                  return (
                    <IdentificationList identification={identification} key={identification.id} />
                  );
                })
              }
              <ButtonCenter>
                {
                  currentNeighborhood.identifications_count <= currentNeighborhood.identifications.length + this.state.identificationPages.length ?
                  <Fragment>
                    ·
                  </Fragment> :
                    <BlueButton selected={true} onClick={this.handleIdentificationLoadMore.bind(this)}>
                      Load More...
                  </BlueButton>
                }
              </ButtonCenter>
            </ContentArea> : null
        }

        {
          this.state.selectedTab === "memories_stories" ? 
          <ContentArea>
              {
                _.map(currentNeighborhood.stories, story => {
                  return (
                    <StoryList story={story} key={story.id} />
                  );
                })
              }
              {
                _.map(this.state.storyPages, story => {
                  return (
                    <StoryList story={story} key={story.id} />
                  );
                })
              }

              <ButtonCenter>
                {
                  currentNeighborhood.stories_count <= currentNeighborhood.stories.length + this.state.storyPages.length ?
                  <Fragment>
                    ·
                  </Fragment> :
                  <BlueButton selected={true} onClick={this.handleStoryLoadMore.bind(this)}>
                    Load More...
                  </BlueButton>
                }

              </ButtonCenter>

          </ContentArea> : null
        }

        {
          this.state.selectedTab === "streetviews" ? 
          <ContentArea>
              {
                _.map(currentNeighborhood.full_streetviews, streetview => {
                  return (
                    <StreetviewList streetview={streetview} key={streetview.gid} />
                  );
                })
              }
              {
                _.map(this.state.streetviewPages, streetview => {
                  return (
                    <StreetviewList streetview={streetview} key={streetview.gid} />
                  );
                })
              }

              <ButtonCenter>
                {
                  currentNeighborhood.full_streetviews_count <= currentNeighborhood.full_streetviews.length + this.state.storyPages.length ?
                  <Fragment>
                    ·
                  </Fragment> :
                  <BlueButton selected={true} onClick={this.handleStreetviewLoadMore.bind(this)}>
                    Load More...
                  </BlueButton>
                }

              </ButtonCenter>

          </ContentArea> : null
        }
      </Fragment>
    );
  }




  render() {
    return this.fullWindow();
  }


}

let mapStateToProps = state => {
  return {
    currentNeighborhood: state.currentNeighborhood,
    windowWidth: state.windowWidth,
    neighborhoodSearchResults: state.neighborhoodSearchResults
  }
}

export default withRouter(connect(mapStateToProps)(NeighborhoodWindow));