import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { convertTitle } from '../../utils';
import { changeIdentifyPlaceStep, changeSnackBar, changeCurrentStreetview, changeNoticeMessage, changeIdentificationCategory, changeProgress } from '../../actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as turf from '@turf/turf'
import { POI_CATEGORY } from '../../constants/defaults';
import { Gutter, BlueButton, WindowsSubtitle, IconWhiteButton, ScreenNameArea, BlueInput } from '../../stylesheets/components';
import CloseIcon from '@material-ui/icons/Close';

const Header = styled.div`
  padding: 5px 10px;
  background: black;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div.title {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: white;
    margin-left: 5px;
  }

  div.left-wrap {
    display: flex;
    align-items: center;
  }
`;


const Content = styled.div`
  padding: 10px 15px;
  p.desc {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: black;
  }
`;

const PLACE_LABEL = {
  1: { value: 1, label: "the survived place" },
  2: { value: 2, label: "the vanished place" },
  3: { value: 3, label: "the new place" },
  4: { value: 4, label: "the new chain store" }
}

const CustomButtonGroup = withStyles({
  vertical: {
    width: "100%"
  },
  groupedContainedPrimary: {
    "&:not(:last-child)": {
      borderColor: "#EEE"
    }
  }
})(ButtonGroup);

const CustomButton = withStyles({
  containedPrimary: {
    width: "100%",
    color: "#333",
    backgroundColor: "white",
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    "&:hover": {
      backgroundColor: "#EEE"
    }
  }
})(Button);


class NewStoreIdentificationSection extends Component {
  // handleClose(e){
  // }
  constructor(props) {
    super(props);
    this.state = {
      identifyPlaceName: "",
      identifyPlaceType: "",
      identifyVanishedPlaceName: "",
      identifyVanishedPlaceType: "",
      vanishedFormPristine: true,
      appearFormPristine: true,
      vanishedFormSubmitting: false,
      appearFormSubmitting: false
    };

    this.formData = {

    }
  }

  handleVanishedClick(vanished_poi_id_data) {
    this.formData.vanished_poi_id = "";


      if (vanished_poi_id_data !== "cannot-recognize") {

        this.formData.vanished_poi_id = vanished_poi_id_data;
  
      }
  
      if (vanished_poi_id_data === "none-of-them") {
  
        this.props.dispatch(changeIdentifyPlaceStep(5));
  
      } else {
  
        this.postIdentification();
  
      }
  
  }


  handleAppearedClick(poi_id_data) {
    this.formData.poi_id = "";

    if (poi_id_data !== "cannot-recognize") {
      this.setState({
        identifyPlaceName: "",
        identifyPlaceType: ""
      });
      this.formData.poi_id = poi_id_data;

    }

    if (poi_id_data === "none-of-them") {

      this.props.dispatch(changeIdentifyPlaceStep(3));

    } else {

      if (this.props.activePanoIDScreen === "left") {
        this.postIdentification();

      } else {
        this.props.dispatch(changeIdentifyPlaceStep(4));
      }

    }

  }

  generateFormData() {
    let formData = new FormData();
    let { isCurrentPanoNow, user_signed_in, current_user, currentPanoID, identificationCategory, currentStreetview, selectedPanoIDIndex, identifyPov, pickedLatLng } = this.props;
    let leftPanoID;

    if (currentStreetview) {
      if (currentStreetview.panoids.length > 0) {

          if (selectedPanoIDIndex === 0) {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex];

          } else {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex - 1];

          }
        
      }
    }
    
    formData.append("streetview_id", currentStreetview.gid);
    formData.append("heading", identifyPov.heading);
    formData.append("pitch", identifyPov.pitch);
    formData.append("category_id", identificationCategory);
    formData.append("panoid_id", currentPanoID.id);
    formData.append("poi_lat", pickedLatLng.lat);
    formData.append("poi_lng", pickedLatLng.lng);
    if (isCurrentPanoNow) {
      formData.append("appeared_timestamp", new Date().getTime() / 1000);
    } else {
      formData.append("appeared_timestamp", currentPanoID.timestamp);
    }

    formData.append("vanished_timestamp", leftPanoID.timestamp);

    if (user_signed_in) {
      formData.append("user_id", current_user.id);
    }

    _.each(this.formData, (value, key) => {
      formData.append(key, value);
    })

    return formData;
  }

  async postIdentification() {

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };

    if (!this.state.appearFormSubmitting && !this.state.vanishedFormSubmitting) {

      this.setState({
        appearFormSubmitting: true,
        vanishedFormSubmitting: true
      });
  
      let formData = this.generateFormData();
      this.props.dispatch(changeProgress(true));
      const response = await fetch(`/identifications.json`, { method: "post", body: formData, headers });
      
      if (response.ok) {
        let data = await response.json();
        // this.props.dispatch(changeCSRFToken(data.csrfToken));
        this.props.dispatch(changeIdentifyPlaceStep(0));
        this.props.dispatch(changeCurrentStreetview(data.streetview));
        this.props.dispatch(changeNoticeMessage("Successfully added!"));
        this.props.dispatch(changeProgress(false));
  
        this.formData = {};
  
        this.setState({
          appearFormSubmitting: false,
          vanishedFormSubmitting: false
        });
      } else {
        let data = await response.json();
        console.log(data);
  
        this.setState({
          appearFormSubmitting: false,
          vanishedFormSubmitting: false
        });
        this.props.dispatch(changeProgress(false));
        return data;
  
      }
    }
  }

  renderPoiListIdentifyVanishedPlaces(){
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;

    let finalPois = _.filter(currentStreetview.near_pois, near_poi => {
      return near_poi.gid !== this.formData.poi_id;
    });




    return (
      <Content>
        {
          currentStreetview.pois.length > 0 ?
          <Fragment>
            <WindowsSubtitle>
              Did you see <span className="blue">any disappeared place</span> in the left street view because of the appearance of the new place? If you did, is <span className="blue">the disappeared place one of the following?</span>
            </WindowsSubtitle>

            <p className="desc">
            </p>
            
            <Gutter h={10} />

            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              {
                _.map(finalPois, (near_poi, key) => {
                  return (
                    <CustomButton size="small" key={key} onClick={this.handleVanishedClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.fclass ? near_poi.fclass : near_poi.category)})</CustomButton>
                  )
                })
              }
            </CustomButtonGroup>
            
            <Gutter h={15} />
            <WindowsSubtitle> 
              Or if none of the list are not matched, 
              Could you recognize the name of <span className="blue">the disappeared place</span>?
            </WindowsSubtitle>
            <Gutter h={5} />

            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >

              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "none-of-them")}>I can recognize the name of the disappeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>I cannot recognize the name of the disappeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>No, it was an empty space before.</CustomButton>
            </CustomButtonGroup>
          </Fragment> :
          <Fragment>
            <WindowsSubtitle> 
              Do you see <span className="blue">any disappeared place</span> in the left street view because of the appearance of new place? If you did, could you recognize the name of <span className="blue">the disappeared place</span>?
            </WindowsSubtitle>
            <Gutter h={5} />

            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >

              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "none-of-them")}>I can recognize the name of the disappeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>I cannot recognize the name of the disappeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>No, it was an empty space before.</CustomButton>
            </CustomButtonGroup>
          </Fragment>
        }


        
      </Content>
    );
  }

  renderPoiListIdentifyPlaces() {
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;

    let finalPois = currentStreetview.pois;


    _.each(currentStreetview.near_pois, near_poi => {
      if (_.findIndex(currentStreetview.pois, poi => poi.gid === near_poi.gid) === -1){
        finalPois.push(near_poi);
      }
    });

    return (
      <Content>
        {
          finalPois.length > 0 ?
          <Fragment>
            <WindowsSubtitle>
              Great! Thanks for the contribution. <br />
              Is <span className="blue">the newly appeared place</span> one of the following? 
            </WindowsSubtitle>

            <Gutter h={10} />

            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              {
                _.map(finalPois, (near_poi, key) => {
                  return (
                    <CustomButton size="small" key={key} onClick={this.handleAppearedClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.fclass ? near_poi.fclass : near_poi.category)})</CustomButton>
                  )
                })
              }
            </CustomButtonGroup>
            <Gutter h={15} />
            <WindowsSubtitle> 
              Or if none of the list are not matched, 
              Could you recognize the name of <span className="blue">the newly appeared place</span>?
            </WindowsSubtitle>
            <Gutter h={5} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >

              <CustomButton size="small" onClick={this.handleAppearedClick.bind(this, "none-of-them")}>I can recognize the name of the newly appeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleAppearedClick.bind(this, "cannot-recognize")}>I cannot recognize the name of the newly appeared place.</CustomButton>
            </CustomButtonGroup>
          </Fragment> :
          <Fragment>
            <WindowsSubtitle> 
              Great! Thanks for the contribution. <br />
              Could you recognize the name of <span className="blue">the newly appeared place</span>?
            </WindowsSubtitle>
            <Gutter h={5} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >

              <CustomButton size="small" onClick={this.handleAppearedClick.bind(this, "none-of-them")}>I can recognize the name of the newly appeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleAppearedClick.bind(this, "cannot-recognize")}>I cannot recognize the name of the newly appeared place.</CustomButton>
            </CustomButtonGroup>  
          </Fragment>
        }
        
      </Content>
    );
  }

  renderEnterNameIdentifyVanishedPlaces(){
    let { current_user, user_signed_in, mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;
    let options = POI_CATEGORY;

    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;

    return (
      <Content>

        <WindowsSubtitle>
          Could you add <span className="blue">the name of the vanished place?</span>
        </WindowsSubtitle>

        <Gutter h={5} />

        {
          user_signed_in ? 
          <ScreenNameArea>
            Contributed by<br/>
            <b>{ current_user.screen_name }</b>
          </ScreenNameArea> : null
        }

        <TextField 
          required
          value={identifyVanishedPlaceName} 
          onChange={(event) => {
            const { value } = event.target;
            this.setState({
              identifyVanishedPlaceName: value,
              vanishedFormPristine: false
            });
          }} 
          InputProps={{
            style: {
              fontFamily: "Source Sans Pro",
              fontSize: "1.0em",
              lineHeight: 1.1,
              color: "black"
            }
          }} 
          label="Name"
          variant="outlined" 
          fullWidth />
        <Gutter h={20} />
        <Autocomplete
          id="combo-box-demo"
          options={options}
          onChange={(event, value) => {
            this.setState({
              identifyVanishedPlaceType: value,
              vanishedFormPristine: false
            });
          }}
          value={identifyVanishedPlaceType}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
          )}
        />

        <Gutter h={20} style={{
          borderBottom: "1px dotted #ccc",
          marginBottom: 20
        }} />

        <BlueInput type="submit" value="Submit" selected={true} disabled={this.state.vanishedFormSubmitting || this.state.vanishedFormPristine || !this.vanishedFormValidate()} onClick={this.handleVanishedPlaceNameSubmit.bind(this)} />
        <Gutter h={20} />
      </Content>
    );
  }

  vanishedFormValidate() {
    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;
    if (identifyVanishedPlaceName.length === 0) {
      return false;
    }

    if (_.isNull(identifyVanishedPlaceType) || _.isUndefined(identifyVanishedPlaceType) || identifyVanishedPlaceType === "") {
      return false;
    }
    return true;
  }

  renderEnterNameIdentifyPlaces() {
    let { user_signed_in, current_user, mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;
    let options = POI_CATEGORY;

    let { identifyPlaceName, identifyPlaceType } = this.state;

    return (
      <Content>
        <WindowsSubtitle>
          Could you add <span className="blue">the name of the newly appeared place?</span>
        </WindowsSubtitle>
        <Gutter h={5} />
        
        {
          user_signed_in ? 
          <ScreenNameArea>
            Contributed by<br/>
            <b>{ current_user.screen_name }</b>
          </ScreenNameArea> : null
        }

        <TextField 
          value={identifyPlaceName} 
          onChange={(event) => {
            const { value } = event.target;
            this.setState({
              identifyPlaceName: value,
              appearFormPristine: false
            })
          }} 
          label="Name" 
          InputProps={{
            style: {
              fontFamily: "Source Sans Pro",
              fontSize: "1.0em",
              lineHeight: 1.1,
              color: "black"
            }
          }}
          variant="outlined" 
          fullWidth />
        <Gutter h={20} />
        <Autocomplete
          id="combo-box-demo"
          options={options}
          onChange={(event, value) => {
            this.setState({
              identifyPlaceType: value
            });
          }}
          value={identifyPlaceType}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
          )}
        />
        <Gutter h={20} style={{
          borderBottom: "1px dotted #ccc",
          marginBottom: 20
        }} />


        <BlueInput selected={true} type="submit" vlaue="Submit" disabled={this.state.appearFormSubmitting || this.state.appearFormPristine || !this.appearFormValidate()} onClick={this.handlePlaceNameSubmit.bind(this)} />
        <Gutter h={20} />
      </Content>
    );
  }

  appearFormValidate() {
    let { identifyPlaceName, identifyPlaceType } = this.state;
    if (identifyPlaceName.length === 0) {
      return false;
    }

    if (_.isNull(identifyPlaceType) || _.isUndefined(identifyPlaceType) || identifyPlaceType === "") {
      return false;
    }
    return true;
  }


  handlePlaceNameSubmit(e) {

    let { currentPanoID, identifyPov, GSVPOV } = this.props;
    let { identifyPlaceName, identifyPlaceType } = this.state;

    this.formData.poi_id = "";
    this.formData.poi_name = identifyPlaceName;
    this.formData.poi_type = identifyPlaceType.value;

    this.setState({
      appearFormSubmitting: true
    });

    if (this.props.activePanoIDScreen === "left") {
      this.postIdentification();

    } else {
      this.props.dispatch(changeIdentifyPlaceStep(4));
    }
    

    this.setState({
      appearFormSubmitting: false
    });
  }


  handleVanishedPlaceNameSubmit(e) {

    let { currentPanoID, identifyPov, GSVPOV } = this.props;
    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;

    this.formData.vanished_poi_id = "";
    this.formData.vanished_poi_name = identifyVanishedPlaceName;
    this.formData.vanished_poi_type = identifyVanishedPlaceType.value;
    
    if (!this.state.vanishedFormSubmitting) {
      this.postIdentification();
    }
    
  }


  handlePrev(e) {
    let { identifyPlaceStep } = this.props;

    if (identifyPlaceStep === 4) {
      if (this.formData.poi_id === "" || this.state.identifyPlaceName) {
        // it means a user entered place name

        this.props.dispatch(changeIdentifyPlaceStep(3));
      } else {

        this.props.dispatch(changeIdentifyPlaceStep(2));
      }
    } else {

      this.props.dispatch(changeIdentifyPlaceStep(identifyPlaceStep - 1));
    }
   

  }

  handleClose(e) {
    this.props.dispatch(changeIdentifyPlaceStep(0));
    this.props.dispatch(changeIdentificationCategory(null));
  }

  render() {
    let { mouseLocation, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;

    return (
      <Fragment>
        <Header>
          <div className="left-wrap">
            
            {
              identifyPlaceStep > 2 ?
                <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handlePrev.bind(this)}>
                  <ChevronLeftIcon fontSize="small" />
                </IconWhiteButton> : null
            }
            <div className="title">
              Identify the place
            </div>
          </div>

          <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handleClose.bind(this)}>
            <CloseIcon fontSize="small" />
          </IconWhiteButton>
        </Header>
        {
          identifyPlaceStep === 2 ?
          this.renderPoiListIdentifyPlaces() : null
        }

        {
          identifyPlaceStep === 3 ?
          this.renderEnterNameIdentifyPlaces() : null
        }

        {
          identifyPlaceStep === 4 ?
          this.renderPoiListIdentifyVanishedPlaces() : null
        }

        {
          identifyPlaceStep === 5 ?
          this.renderEnterNameIdentifyVanishedPlaces() : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    activePanoIDScreen: state.activePanoIDScreen,
    currentStreetview: state.currentStreetview,
    identificationCategory: state.identificationCategory,
    identifyPlaceStep: state.identifyPlaceStep,
    identifyPov: state.identifyPov,
    imageCoordinate: state.imageCoordinate,
    pickedLatLng: state.pickedLatLng,
    GSVPOV: state.GSVPOV,
    selectedPanoIDIndex: state.selectedPanoIDIndex,
    csrfToken: state.csrfToken,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    isCurrentPanoNow: state.isCurrentPanoNow
  }
}

export default connect(mapStateToProps)(NewStoreIdentificationSection);
    