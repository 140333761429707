import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const Header = styled.div`
  padding: 5px 10px;
  background: black;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div.title {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: white;
    margin-left: 5px;
  }

  div.left-wrap {
    display: flex;
    align-items: center;
  }
`;

const IconWhiteButton = withStyles({
  root: {
    color: "white"
  }
})(IconButton);

const Content = styled.div`
  padding: 10px 15px;
  p.desc {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: black;
  }
`;


const CustomButtonGroup = withStyles({
  vertical: {
    width: "100%"
  },
  groupedContainedPrimary: {
    "&:not(:last-child)": {
      borderColor: "#EEE"
    }
  }
})(ButtonGroup);

const CustomButton = withStyles({
  containedPrimary: {
    width: "100%",
    color: "#333",
    backgroundColor: "white",
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    "&:hover": {
      backgroundColor: "#EEE"
    }
  }
})(Button);


export {
  CustomButton,
  CustomButtonGroup,
  Content,
  IconWhiteButton,
  Header
}