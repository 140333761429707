import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { convertTitle, vincentyDirect } from '../../utils';
import { Gutter } from '../../stylesheets/components';
import { changeIdentifyPlaceStep, changeSnackBar, changeCurrentStreetview } from '../../actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as turf from '@turf/turf'
import { POI_CATEGORY } from '../../constants/defaults';

const Header = styled.div`
  padding: 5px 10px;
  background: black;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div.title {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: white;
    margin-left: 5px;
  }

  div.left-wrap {
    display: flex;
    align-items: center;
  }
`;

const IconWhiteButton = withStyles({
  root: {
    color: "white"
  }
})(IconButton);

const Content = styled.div`
  padding: 10px 15px;
  p.desc {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: black;
  }
`;

const PLACE_LABEL = {
  1: { value: 1, label: "the survived place" },
  2: { value: 2, label: "the vanished place" },
  3: { value: 3, label: "the new place" },
  4: { value: 4, label: "the new chain store" }
}

const CustomButtonGroup = withStyles({
  vertical: {
    width: "100%"
  },
  groupedContainedPrimary: {
    "&:not(:last-child)": {
      borderColor: "#EEE"
    }
  }
})(ButtonGroup);

const CustomButton = withStyles({
  containedPrimary: {
    width: "100%",
    color: "#333",
    backgroundColor: "white",
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    "&:hover": {
      backgroundColor: "#EEE"
    }
  }
})(Button);


class NewChainstoreIdentificationSection extends Component {
  // handleClose(e){
  // }
  constructor(props) {
    super(props);
    this.state = {
      identifyPlaceName: "",
      identifyPlaceType: "",
      identifyVanishedPlaceName: "",
      identifyVanishedPlaceType: "",
    };

    this.formData = {

    }
  }

  handleVanishedClick(vanished_poi_id_data) {
    this.formData.vanished_poi_id = "";

    if (vanished_poi_id_data !== "cannot-recognize") {

      this.formData.vanished_poi_id = vanished_poi_id_data;

    }

    if (vanished_poi_id_data === "none-of-them") {

      this.props.dispatch(changeIdentifyPlaceStep(5));

    } else {

      this.postIdentification();

    }

  }


  handleAppearedClick(poi_id_data) {
    this.formData.poi_id = "";

    if (poi_id_data !== "cannot-recognize") {

      this.formData.poi_id = poi_id_data;

    }

    if (poi_id_data === "none-of-them") {

      this.props.dispatch(changeIdentifyPlaceStep(3));

    } else {

      this.props.dispatch(changeIdentifyPlaceStep(4));

    }

  }

  generateFormData() {
    let formData = new FormData();
    let { currentPanoID, identificationCategory, currentStreetview, selectedPanoIDIndex, identifyPov, pickedLatLng } = this.props;
    let leftPanoID;

    if (currentStreetview) {
      if (currentStreetview.panoids.length > 0) {

          if (selectedPanoIDIndex === 0) {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex];

          } else {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex - 1];

          }
        
      }
    }
    
    formData.append("streetview_id", currentStreetview.gid);
    formData.append("heading", identifyPov.heading);
    formData.append("pitch", identifyPov.pitch);
    formData.append("category_id", identificationCategory);
    formData.append("panoid_id", currentPanoID.id);
    formData.append("poi_lat", pickedLatLng.lat);
    formData.append("poi_lng", pickedLatLng.lng);
    formData.append("vanished_timestamp", leftPanoID.timestamp);



    _.each(this.formData, (value, key) => {
      formData.append(key, value);
    })

    return formData;
  }

  async postIdentification() {

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };



    let formData = this.generateFormData();

    const response = await fetch(`/identifications.json`, { method: "post", body: formData, headers });

    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeIdentifyPlaceStep(0));
      this.props.dispatch(changeCurrentStreetview(data.streetview));
      this.props.dispatch(changeSnackBar({
        show: true,
        msg: "Successfully added!"
      }));

      this.formData = {};

    } else {
      let data = await response.json();
      console.log(data);

      return data;

    }
  }

  renderPoiListIdentifyVanishedPlaces(){
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;

    return (
      <Content>
        <p className="desc">
          Did you identified any vanished place in the left view?<br/>
          If you did, is the vanished place one of the following?
        </p>
        <Gutter h={10} />
        <CustomButtonGroup
          orientation="vertical"
          color="primary"
          variant="contained"
          aria-label="vertical outlined primary button group"
        >
          {
            _.map(currentStreetview.near_pois, (near_poi, key) => {
              return (
                <CustomButton size="small" key={key} onClick={this.handleVanishedClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.fclass)})</CustomButton>
              )
            })
          }
        </CustomButtonGroup>
        <Gutter h={15} />
        <CustomButtonGroup
          orientation="vertical"
          color="primary"
          variant="contained"
          aria-label="vertical outlined primary button group"
        >

          <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>No place were vanished</CustomButton>
          <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>I cannot recognize the place</CustomButton>
          <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "none-of-them")}>None of them are the place</CustomButton>
        </CustomButtonGroup>
      </Content>
    );
  }

  renderPoiListIdentifyPlaces() {
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;

    return (
      <Content>
        <p className="desc">
          Great! Thanks for the contribution. <br />
          Is the place one of the following?
          </p>
        <Gutter h={10} />
        <CustomButtonGroup
          orientation="vertical"
          color="primary"
          variant="contained"
          aria-label="vertical outlined primary button group"
        >
          {
            _.map(currentStreetview.near_pois, (near_poi, key) => {
              return (
                <CustomButton size="small" key={key} onClick={this.handleAppearedClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.fclass)})</CustomButton>
              )
            })
          }
        </CustomButtonGroup>
        <Gutter h={15} />
        <CustomButtonGroup
          orientation="vertical"
          color="primary"
          variant="contained"
          aria-label="vertical outlined primary button group"
        >

          <CustomButton size="small" onClick={this.handleAppearedClick.bind(this, "cannot-recognize")}>I cannot recognize the place</CustomButton>
          <CustomButton size="small" onClick={this.handleAppearedClick.bind(this, "none-of-them")}>None of them are the place</CustomButton>
        </CustomButtonGroup>
      </Content>
    );
  }

  renderEnterNameIdentifyVanishedPlaces(){
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;
    let options = POI_CATEGORY;

    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;

    return (
      <Content>
        <p className="desc">
          Could you add <b>the name of the vanished place?</b>
        </p>

        <TextField value={identifyVanishedPlaceName} onChange={(event) => {
          const { value } = event.target;
          this.setState({
            identifyVanishedPlaceName: value
          })
        }} label="Name" fullWidth />
        <Gutter h={10} />
        <Autocomplete
          id="combo-box-demo"
          options={options}
          onChange={(event, value) => {
            this.setState({
              identifyVanishedPlaceType: value
            });
          }}
          value={identifyVanishedPlaceType}
          getOptionLabel={option => option.label}
          style={{ width: 300 }}
          renderInput={params => (
            <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
          )}
        />

        <Gutter h={10} />

        <Button variant="contained" onClick={this.handleVanishedPlaceNameSubmit.bind(this)}>Submit</Button>
        <Gutter h={20} />
      </Content>
    );
  }


  renderEnterNameIdentifyPlaces() {
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;
    let options = POI_CATEGORY;

    let { identifyPlaceName, identifyPlaceType } = this.state;

    return (
      <Content>
        <p className="desc">
          Could you add <b>the name of the place?</b>
        </p>

        <TextField value={identifyPlaceName} onChange={(event) => {
          const { value } = event.target;
          this.setState({
            identifyPlaceName: value
          })
        }} label="Name" fullWidth />
        <Gutter h={10} />
        <Autocomplete
          id="combo-box-demo"
          options={options}
          onChange={(event, value) => {
            this.setState({
              identifyPlaceType: value
            });
          }}
          value={identifyPlaceType}
          getOptionLabel={option => option.label}
          style={{ width: 300 }}
          renderInput={params => (
            <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
          )}
        />

        <Gutter h={10} />

        <Button variant="contained" onClick={this.handlePlaceNameSubmit.bind(this)}>Submit</Button>
        <Gutter h={20} />
      </Content>
    );
  }

  handlePlaceNameSubmit(e) {

    let { currentPanoID, identifyPov, GSVPOV } = this.props;
    let { identifyPlaceName, identifyPlaceType } = this.state;

    this.formData.poi_id = "";
    this.formData.poi_name = identifyPlaceName;
    this.formData.poi_type = identifyPlaceType.value;

    this.props.dispatch(changeIdentifyPlaceStep(4));
  }


  handleVanishedPlaceNameSubmit(e) {

    let { currentPanoID, identifyPov, GSVPOV } = this.props;
    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;

    this.formData.vanished_poi_id = "";
    this.formData.vanished_poi_name = identifyVanishedPlaceName;
    this.formData.vanished_poi_type = identifyVanishedPlaceType.value;
    this.postIdentification();
  }


  handlePrev(e) {
    this.props.dispatch(changeIdentifyPlaceStep(2));
  }

  render() {
    let { mouseLocation, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;


    return (
      <Fragment>
        <Header>
          <div className="left-wrap">

            {
              identifyPlaceStep === 3 ?
                <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handlePrev.bind(this)}>
                  <ChevronLeftIcon fontSize="small" />
                </IconWhiteButton> : null
            }
            <div className="title">
              Identify the place
            </div>
          </div>
        </Header>
        {
          identifyPlaceStep === 2 ?
          this.renderPoiListIdentifyPlaces() : null
        }

        {
          identifyPlaceStep === 3 ?
          this.renderEnterNameIdentifyPlaces() : null
        }

        {
          identifyPlaceStep === 4 ?
          this.renderPoiListIdentifyVanishedPlaces() : null
        }

        {
          identifyPlaceStep === 5 ?
          this.renderEnterNameIdentifyVanishedPlaces() : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    identificationCategory: state.identificationCategory,
    identifyPlaceStep: state.identifyPlaceStep,
    identifyPov: state.identifyPov,
    imageCoordinate: state.imageCoordinate,
    pickedLatLng: state.pickedLatLng,
    GSVPOV: state.GSVPOV,
    selectedPanoIDIndex: state.selectedPanoIDIndex,
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(NewChainstoreIdentificationSection);
    