import React, { Component } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LogoLink } from '../stylesheets/components';
import { Logo } from './';
import { signOut, changeNoticeMessage, changeCSRFToken, changeTutorialPopup, changeProgress, changeIsFullScreen } from '../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RED_COLOR, SHADOW_COLOR } from '../constants/defaults';

const Fragment = React.Fragment;

const HeaderContainer = styled.header`
  padding: 15px 0;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;

  display: flex;
  justify-content: space-between;

  div.wrapper {
    width: calc(100% - 20px);
    margin: 0 auto;
    position: relative;
  }
`;

const Title = styled.h1`
  a {
    font-family: "Source Sans Pro";
    font-size: 1.0em;
    color:black;
    font-weight: 600;
  }

`;

const ExpandBtn = styled.a`

  position: absolute;
  left: ${props => props.isFullScreen ? "10px": "calc(50% + 15px)" };
  top: 0px;
  
`;


const LinkArea = styled.div`

  position: absolute;
  right: 0;
  top: -6px;
  background: black;
  border: 1px solid ${SHADOW_COLOR};
  box-sizing: border-box;
  box-shadow: 3px 3px 0px ${RED_COLOR};
  border-radius: 10px;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  
`;

const MenuLink = styled(Link)`
  margin-right: 15px;
  color: ${RED_COLOR};
  font-size: 0.8em;
  @media (max-width: 414px) {
    margin-left: 0;
  }

  &:last-child {
    margin-right:0;
  }
`

const MenuLinkA = styled.a`
  margin-right: 15px;
  color: ${RED_COLOR};
  font-size: 0.8em;
  @media (max-width: 414px) {
    margin-left: 0;
  }

  &:last-child {
    margin-right:0;
  }
`

const TitleArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: ${RED_COLOR};
  box-shadow: 3px 3px 0px #5a2c49;
  border-radius: 10px;
  padding: 12px 15px;
`;


class Header extends Component {
  constructor(props){
    super(props);

    this.state = {
      minimize: window.innerWidth <= 414
    };
  }

  async handleSignOut(e){
    e.preventDefault();
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    this.props.dispatch(changeProgress(true));
    const response = await fetch("/users/sign_out.json", { method: "delete", headers });
    if (response.ok) {

      let data = await response.json();
      console.log(data);

      this.props.dispatch(signOut());
      this.props.dispatch(changeCSRFToken(data.csrf_token));
      this.props.history.push('/contribute');
      this.props.dispatch(changeProgress(false));
      this.props.dispatch(changeNoticeMessage("Successfully Signed Out."));
    } else {
      console.log("error sign out");
      this.props.dispatch(changeProgress(false));
    }
  
  }
  handleMinimize(e){
    this.setState({
      minimize: true
    })
  }

  handleMaximize(e){
    this.setState({
      minimize: false
    })
  }

  handleTutorialPopup(e){
    e.preventDefault();
    this.props.dispatch(changeTutorialPopup(true));
  }

  handleFullScreenClick(e){
    e.preventDefault();
    let { isFullScreen } = this.props;
    
    this.props.dispatch(changeIsFullScreen(!isFullScreen));
  }
  
  render() {
    let { minimize } = this.state;
    let { user_signed_in, isFullScreen } = this.props;
    return (
      <HeaderContainer>
        <div className="wrapper">
          {
            isFullScreen ? 
            null : 
            <LogoLink to="/contribute">
              <Logo scrollTop={this.state.scrollTop} />
            </LogoLink>
          }
            
          <ExpandBtn isFullScreen={isFullScreen} href="#" onClick={this.handleFullScreenClick.bind(this)}>
            {
              isFullScreen ? 
              <img src="/assets/fullscreen_cancel_btn.svg" alt="fullscreen cancel button" /> :
              <img src="/assets/fullscreen_btn.svg" alt="fullscreen button" />
            }
          </ExpandBtn>
          {
            minimize ? 
            <LinkArea>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <g style={{cursor: "pointer"}} onClick={this.handleMaximize.bind(this)}>
                  <rect x="0.5" y="0.5" width="17" height="17" stroke="#333" fill="black"/>
                  <path d="M4 5.5H14" stroke="white"/>
                  <path d="M4 9H14" stroke="white"/>
                  <path d="M4 13H14" stroke="white"/>
                </g>
              </svg>
            </LinkArea> : 
            <LinkArea>
              <MenuLink to="/contribute">
                Contribute
              </MenuLink>

              <MenuLinkA href="#" onClick={this.handleTutorialPopup.bind(this)}>
                Tutorial
              </MenuLinkA>

              <MenuLink to="/about">
                About
              </MenuLink>
              {
                user_signed_in ? 

                  <Fragment>

                    <MenuLink to={`/users/edit`}>
                      My Account
                    </MenuLink>

                    <MenuLinkA href="#" onClick={this.handleSignOut.bind(this)}>
                      Sign out
                    </MenuLinkA>
                  </Fragment>
                :
                <Fragment>
                  <MenuLink to="/users/sign_in">
                    Sign in
                  </MenuLink>
                </Fragment>
              }
              {
                !minimize ? 
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g style={{cursor: "pointer"}} onClick={this.handleMinimize.bind(this)}>
                    <rect x="0.5" y="0.5" width="17" height="17" stroke="#333" fill="black"/>
                    <path d="M4 9H14" stroke="#C8C8C8"/>
                  </g>
                </svg> : null 
              }
            </LinkArea>
          }
          
        </div>
      </HeaderContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    csrfToken: state.csrfToken,
    isFullScreen: state.isFullScreen
  }
}

export default withRouter(connect(mapStateToProps)(Header));