import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Logo } from './';
import { Link } from 'react-router-dom';
import { changeCurrentNeighborhood, changeFocusedNeighborhoods, changeTutorialPopup, changeLeftContainerScrollTop, changeProgress } from '../actions';
import { NeighborhoodSelector, FirstPopup } from './';
import { LogoLink, SmallTitle, Gutter, LeftContainer, WindowsBigtitle } from '../stylesheets/components';
import { SHADOW_COLOR, RED_COLOR } from '../constants/defaults';
import Cookies from 'js-cookie';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const WindowBarArea = styled.div`
  background: white;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid black;
  padding: 7px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WindowBarTitle = styled.div`
  color: black;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
`;

const FocusedNeighborhood = styled.div`
  padding: 15px;
  background: ${SHADOW_COLOR};
  border: 1px solid #282828;
  
  h2.title {
    font-family: "Heldane Display";
    color: ${RED_COLOR};
    font-size: 2.5em;
    line-height: 1.2;
  }

  p.collabo {  
    margin-top: 5px;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.2;
    margin-bottom: 15px;
    color: #828282;
  }

  div.neighborhoods-subtitle {
    border-top: 1px solid #434343;
    border-bottom: 1px solid #434343;
    padding: 7px 0;
    margin: 7px 0;
    margin-bottom: 0; 
    color: #888;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.2;
  }
`;

const NeighborhoodList = styled(Link)`

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dotted #555;
  padding-bottom: 10px;
  padding-top: 10px;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #222;
    opacity: 1;
  }

  div.left {
  }
  
  div.neighborhood-title {
    font-family: "Heldane Display";
    font-size: 1.5em;
    line-height: 1.0;
    color: white;
  }

  div.right {
    text-align: right;
  }

  span.small {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 0.8em;
    color: #999;
    line-height: 1.2;
  }

  span.white {
    color: white;
    font-family: "Source Sans Pro";
  }

`;

const MinimizeButton = styled.button`
  border: 1px solid black;
  padding: 5px;
  cursor: pointer;
  color: black;
`;

class FocusedNeighborhoodWindow extends Component {
  constructor(props){
    super(props);

    this.state = {
      minimize: false,
      scrollTop: 0
    }
  }
  
  componentDidMount(){
    if (!Cookies.get('tutorial-show')) {
      this.props.dispatch(changeTutorialPopup(true));
      Cookies.set('tutorial-show', true)
    }

    this.props.dispatch(changeCurrentNeighborhood(null));


    this.loadData();
  }

  async loadData(){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));
    const response = await fetch("/focused_neighborhood_boundaries.json", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));
      
      this.props.dispatch(changeFocusedNeighborhoods(data.focused_neighborhood_boundaries));


    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }

  }


  handleMinimize(e){
    this.setState({
      minimize: true
    });
  }

  handleMaximize(e){
    this.setState({
      minimize: false
    });
  }

 
  handleScroll(e){
    this.props.dispatch(changeLeftContainerScrollTop(e.target.scrollTop));
  }

  fullWindow() {
    let { focusedNeighborhoods } = this.props;
    return (
      <LeftContainer onScroll={this.handleScroll.bind(this)}>
       
        <Gutter h={30} />
        <NeighborhoodSelector />

        <WindowsBigtitle>
          Welcome to <span className="blue">wesurvived.nyc!</span><br/>
          Please contribute your memories of beloved places to advocate for commercial rent stabilization and support small businesses in NYC.
        </WindowsBigtitle>

        <FirstPopup />
        <Gutter h={30} />

        <SmallTitle>
          ...Or, view some <span className="blue">focused neighborhoods</span>:
        </SmallTitle>
        <Gutter h={10} />
        
        {
          _.map(focusedNeighborhoods, fn => {
            return (
              <FocusedNeighborhood key={fn.id}>
                <h2 className="title">
                  {fn.title}
                </h2>
                {/* {
                  fn.organization ?
                  <p className="collabo">
                    In collaboration with <br />
                    <span className="white">{fn.organization}</span>
                  </p> : null
                } */}
                <div className="neighborhoods-subtitle">
                  Neighborhoods
                </div>
                {
                  _.map(fn.neighborhood_boundaries, neighborhood => {
                    return (
                      <NeighborhoodList key={neighborhood.gid} to={`/neighborhoods/${neighborhood.permalink}`}>
                        <div className="left">
                          <div className="neighborhood-title">
                            {neighborhood.ntaname}
                          </div>
                          
                          <span className="small">
                            {neighborhood.boro_name}
                          </span>
                        </div>
                        <div className="right">

                          <span className="small">
                            <span className="white">{neighborhood.identifications_count}</span> changes identified<br />
                            <span className="white">{neighborhood.stories_count}</span> stories added
                          </span>
                        </div>
                      </NeighborhoodList>
                    );
                  })
                }

              </FocusedNeighborhood>
            );
          })
        }
        <Gutter h={50} />
      </LeftContainer>
    );
  }
  render() {

    return this.fullWindow();
  }
}

let mapStateToProps = state => {
  return {
    focusedNeighborhoods: state.focusedNeighborhoods
  }
}

export default connect(mapStateToProps)(FocusedNeighborhoodWindow);