import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as d3 from 'd3';

const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;

`;

class NeighborhoodIndicator extends Component {
  constructor(props){
    super(props);
    this.scale = d3.scaleLinear().domain([1, 10, 20, 30, 40, 50]).range(["#1e1e1e", "#434721", "#726028", "#9b7031", "#c3793a", "#ff6044"])
  }
  render() {

    let { totalChanges } = this.props;

    console.log(totalChanges);
    return (
      <Circle style={{ backgroundColor: this.scale(totalChanges)}} />
    )
  }
}

let mapStateToProps = state => {
  return {

  }
};

export default connect(mapStateToProps)(NeighborhoodIndicator);
