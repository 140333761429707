import React, { Component } from 'react';
import { ContentLink, PoiStreetView, LabelArea, BlueButton, ListTitle as Title, ListButtonArea as ButtonArea } from '../stylesheets/components';
import { convertTitle, panoDateToRichDate } from '../utils';
import { connect } from 'react-redux';
import { changeCurrentSelectedMapEntity } from '../actions';

class PoiList extends Component {
  constructor(props){
    super(props);
    this.state = {
      clicked: false
    };
  }
  handleClick(e){
    
    let { poi } = this.props;
    document.location.href=`/pois/${poi.gid}`;
  }

  handleToggleList(e){
    e.preventDefault();
    if (!this.state.clicked) {
      this.props.dispatch(changeCurrentSelectedMapEntity(this.props.poi));
    }
    this.setState({
      clicked: !this.state.clicked
    })
  }
  render() {
    let { poi } = this.props;
    return (
      <ContentLink href="#" onClick={this.handleToggleList.bind(this)} key={poi.gid}>
        <PoiStreetView>
          <img className="all" src={`https://maps.googleapis.com/maps/api/streetview?pano=${poi.possible_pano_id}&size=600x600&heading=0&key=AIzaSyC2EvWU5FdBa4xgzA-mRodCJCdY_TP5z90`} alt="streetview images of the poi" />
        </PoiStreetView>
        <LabelArea>
          <div className="left">
            <Title>
              {poi.name}
            </Title>
            <p className="small">
              {convertTitle(poi.category)}
            </p>
          </div>
          <div className="right">
            <p className="small">
              {poi.history}
            </p>
          </div>

        </LabelArea>
        {
          this.state.clicked ?
          <ButtonArea>
            <BlueButton onClick={this.handleClick.bind(this)} selected={true}>
              Show the {convertTitle(poi.category)}
            </BlueButton>
          </ButtonArea> : null
        }
      </ContentLink>
    );
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps)(PoiList);