import React, { Component } from 'react'
import styled from 'styled-components';
import { Gutter, BlueButton, LeftContainer, BackLink, LogoLink } from '../stylesheets/components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Logo } from './';
import { changeSelectedPanoidIndex, changeStreetviewTab, changeGSVPOV, changeIsCurrentPanoNow, changeLeftContainerScrollTop } from '../actions';
import moment from 'moment';
import { STORY_CATEGORY } from '../constants/defaults';
import { findNearestPanoID } from '../utils';

const Fragment = React.Fragment;
const StoryShowContainer = styled(LeftContainer)`
  background: ${props => STORY_CATEGORY[props.category_id].bgColor };
  padding-top: 20px;

  height: calc(100vh - 20px);

`;

const Title = styled.div`
  font-family: "Source Sans Pro";
  font-size: 2.0em;
  font-weight: 600;
  line-height: 1.1;
  color:${props => STORY_CATEGORY[props.category_id].fgColor };
  padding: 5px 0 20px 0;
`;


const DateArea = styled.div`
  border-top: 1px solid ${props => STORY_CATEGORY[props.category_id].borderColor };
  margin-top: 10px;
  padding-top: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
`;


const Date = styled.div`
  color: ${props => STORY_CATEGORY[props.category_id].fgColor };
  font-size:12px;
`;


const Contributor = styled.div`
  color: ${props => STORY_CATEGORY[props.category_id].fgColor };
  font-size:0.9em;
`;


const Category = styled.div`
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.2;
  color: ${props => STORY_CATEGORY[props.category_id].fgColor };
  margin-bottom: 10px;

  span.black {
    color: rgba(0, 0, 0, 1);
  }
  a {
    text-decoration: underline;
  }
`;

const BackLinkArea = styled.div`
  border-bottom: 1px solid ${props => STORY_CATEGORY[props.category_id].borderColor };
  display: flex;
  padding-bottom: 5px;
	flex-direction: row-reverse;
`;
class StoryShow extends Component {

  componentDidMount(){
    this.changeHeadingAndPanoidIdx();
  }

  componentDidUpdate(prevProps){
    if (prevProps.currentStory.id !== this.props.currentStory.id) {
      this.changeHeadingAndPanoidIdx();
    }
  }

  changeHeadingAndPanoidIdx(){
    let { GSVPOV, currentPanoID, currentStreetview, currentStory } = this.props;

    let poi = _.find(currentStreetview.pois, poi => poi.gid === currentStory.poi.gid);

    let panoidIdx = findNearestPanoID(poi, currentStreetview);//_.findIndex(currentStreetview.panoids, panoid => panoid.pano_id === currentPoi.possible_pano_id);
    let panoIDPoint = new google.maps.LatLng(currentPanoID.location[1], currentPanoID.location[0]);
    let storyPoint = new google.maps.LatLng(currentStory.geometry[1], currentStory.geometry[0]);

    let heading = google.maps.geometry.spherical.computeHeading(panoIDPoint, storyPoint);

    // console.log("changeHeadingAndPanoidIdx", Math.max(0, panoidIdx), heading);

    if (panoidIdx === currentStreetview.panoids.length) {
      // currentpano is now
      console.log(panoidIdx, currentStreetview.length);
      this.props.dispatch(changeSelectedPanoidIndex(Math.max(0, panoidIdx)));
      this.props.dispatch(changeIsCurrentPanoNow(true));
    } else {

      this.props.dispatch(changeSelectedPanoidIndex(Math.max(0, panoidIdx)));
      this.props.dispatch(changeIsCurrentPanoNow(false));
    }

    this.props.dispatch(changeGSVPOV({
      ...GSVPOV,
      heading: heading
    }));
    this.props.dispatch(changeStreetviewTab(1));

  }


  handlePoiClick(e){
    e.preventDefault();
    let { currentStory } = this.props;
    this.props.history.push(`/pois/${currentStory.poi.gid}`);
  }

  handleBackLinkClick(e){

    let { currentStory } = this.props;
    this.props.history.push(`/pois/${currentStory.poi.gid}`);

  }

  handleScroll(e){
    this.props.dispatch(changeLeftContainerScrollTop(e.target.scrollTop));
  }
  render() {
    let { currentStory } = this.props;

    return (
      <Fragment>
        <LogoLink to="/contribute">
          <Logo fgColor={STORY_CATEGORY[currentStory.category_id].fgColor} bgColor={STORY_CATEGORY[currentStory.category_id].bgColor} />
        </LogoLink>
          
        <StoryShowContainer  onScroll={this.handleScroll.bind(this)} category_id={currentStory.category_id}>
          <Gutter h={38} />
          <BackLinkArea category_id={currentStory.category_id}>
            <BackLink selected={true} onClick={this.handleBackLinkClick.bind(this)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.8125 11.7872C20.9375 11.9122 21 12.0841 21 12.3029C21 12.5216 20.9297 12.7013 20.7891 12.8419C20.6484 12.9825 20.4688 13.0529 20.25 13.0529H5.57812L8.76562 16.2872C9.14063 16.631 9.14844 16.9825 8.78906 17.3419C8.42969 17.7013 8.07813 17.6935 7.73438 17.3185L3.23438 12.8185C3.14062 12.756 3.07812 12.6779 3.04688 12.5841C3.01562 12.4904 3 12.3966 3 12.3029C3 12.2091 3.01562 12.1154 3.04688 12.0216C3.07812 11.9279 3.14062 11.8497 3.23438 11.7872L7.73438 7.28723C8.07813 6.91223 8.42969 6.90442 8.78906 7.2638C9.14844 7.62317 9.14063 7.97473 8.76562 8.31848L5.57812 11.5529H20.25C20.4688 11.5529 20.6562 11.631 20.8125 11.7872Z" fill={STORY_CATEGORY[currentStory.category_id].fgColor} />
              </svg>

              <div style={{color: STORY_CATEGORY[currentStory.category_id].fgColor}}>
              Back to {currentStory.poi.name}
              </div>
            </BackLink>
          </BackLinkArea>

          <Gutter h={10} />
          {
            currentStory.category_id === 1 ?
            <Category category_id={currentStory.category_id}>
              How did you know <a href="#" onClick={this.handlePoiClick.bind(this)}><span className="white">{currentStory.poi.name}</span></a>?
            </Category> :
            <Category category_id={currentStory.category_id}>
              What was the struggle of <a href="#" onClick={this.handlePoiClick.bind(this)}><span className="black">{currentStory.poi.name}</span></a>?
            </Category>
          }

          <Title category_id={currentStory.category_id}>
            “{currentStory.description}”
          </Title>

          <DateArea category_id={currentStory.category_id}>
            <Contributor category_id={currentStory.category_id}>
              {
                currentStory.user ? 
                <Fragment>
                  Contributed by <br/>
                  <b>{ currentStory.user.screen_name }</b>
                </Fragment> :
                <Fragment>
                  Anonymous
                </Fragment>
              }
            </Contributor>
            <Date category_id={currentStory.category_id}>
              {moment.unix(currentStory.timestamp).format("MMMM YYYY")}
            </Date>
          </DateArea>
          <Gutter h={50} />
        </StoryShowContainer>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    GSVPOV: state.GSVPOV,
    currentStory: state.currentStory,
    currentStreetview: state.currentStreetview
  }
}

export default withRouter(connect(mapStateToProps)(StoryShow));