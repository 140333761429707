import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { changeIdentifyPlaceStep, changeIdentificationCategory, changeNoticeMessage } from '../actions';
import { BlueButton, Gutter } from '../stylesheets/components';
import { IDENTIFICATION_CATEGORY, SHADOW_COLOR, RED_COLOR, STORY_CATEGORY } from '../constants/defaults';

const Container = styled.div`
  position: fixed;
  padding: 15px;
  z-index: 64;
  left: 50%;
  transform: translate(-50%, 0);
  top: 126px;
  background: #000000;
  border: 1px solid ${SHADOW_COLOR};
  box-sizing: border-box;
  box-shadow: 2px 2px 0px #404040;
  border-radius: 10px;
  div.wrap {

    display: flex;
    align-items: center;
  }
`;

const ButtonArea = styled.div`
  margin-bottom: 5px;
  text-align: right;
`;

const Title = styled.div`
  font-family: "Source Sans Pro";
  color: white;
  text-align: center;
  font-weight: 600;
  font-size:0.9em;
  line-height:1.1em;
  margin-bottom: 10px;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.11);
`;

const BlockBlueButton = styled(BlueButton)`
  width: 120px;
  margin-right: 0;
  margin-bottom: 10px;
  font-size:0.8em;
  display: block;
  &:last-child {
    margin: 0;
  }
`;


class ChangeButtonContainer extends Component {

  handleClick(identifyCategory){
    this.props.dispatch(changeIdentificationCategory(identifyCategory));
    this.props.dispatch(changeNoticeMessage(`Point out where to locate the store.`))
    this.props.dispatch(changeIdentifyPlaceStep(1));

  }

  handleCancelClick(e) {
    this.props.dispatch(changeIdentifyPlaceStep(0));
    this.props.dispatch(changeIdentificationCategory(null));
  }

  render() {
    let { identifyPlaceStep } = this.props;
    return (
      <Container>
        {
          identifyPlaceStep === 0 ? 
          <Fragment>
            {
              _.map(_.values(IDENTIFICATION_CATEGORY).reverse(), (category) => {
                return (
                  <BlockBlueButton key={category.value} selected={true} onClick={this.handleClick.bind(this, category.value)}>{category.buttonLabel}</BlockBlueButton>
                )
              })
            }
          </Fragment> :
          <Fragment>
            <BlueButton selected={true} onClick={this.handleCancelClick.bind(this, 1)}>Cancel</BlueButton>
          </Fragment>
        }
      </Container>
    )
  }
}


let mapStateToProps = state => {
  return {
    identifyPlaceStep: state.identifyPlaceStep
  }
}

export default connect(mapStateToProps)(ChangeButtonContainer);