import React, { Component } from 'react';
import { ContentLink, PoiStreetView, LabelArea, BlueButton, ListTitle as Title, ListButtonArea as ButtonArea, Gutter } from '../stylesheets/components';
import { convertTitle, panoDateToRichDate } from '../utils';
import { connect } from 'react-redux';
import { RED_COLOR, SHADOW_COLOR, STORY_CATEGORY } from '../constants/defaults';
import { changeCurrentSelectedMapEntity } from '../actions';
import styled from 'styled-components';

import moment from 'moment';
import { truncate } from '../utils';
const Fragment = React.Fragment;

const ChangesTitle = styled.div`
  font-size: 0.875em;
  font-family: "Source Sans Pro";
  color: #828282;
  line-height: 1.2;

  span.white {
    font-weight: 600;
  }
`;


const StoryTitle = styled.div`
  font-family: "Source Sans Pro";
  font-size: 1.2em;
  font-weight: 600;
  color: white;
`;

class StoryList extends Component {
  constructor(props){
    super(props);
    this.state = {
      clicked: false
    };
  }
  handleClick(e){
    
    let { story } = this.props;
    document.location.href=`/stories/${story.id}`;
  }

  handleToggleList(e){
    e.preventDefault();
    if (!this.state.clicked) {
      this.props.dispatch(changeCurrentSelectedMapEntity(this.props.story));
    }
    this.setState({
      clicked: !this.state.clicked
    })
  }

  render() {
    let { story } = this.props;
    return (
      <ContentLink href="#" className={ this.state.clicked ? "selected" : ""} onClick={this.handleToggleList.bind(this)}>
        <PoiStreetView>
          <img className="all" src={`https://maps.googleapis.com/maps/api/streetview?pano=${story.possible_pano_id}&size=600x600&heading=0&key=AIzaSyC2EvWU5FdBa4xgzA-mRodCJCdY_TP5z90`} alt="streetview images of the poi" />
        </PoiStreetView>
        <LabelArea>
          <div className="left">
            <StoryTitle>
              “{truncate(story.description, 100)}”
            </StoryTitle>
            <Gutter h={15} />
            <Title>
              { story.poi.name }
            </Title>
            <p className="category">
              { convertTitle(story.poi.category) }
            </p>
            {
              story.user ? 
              <Fragment>
                <Gutter h={10} />
                <p className="category">
                  by <span className="white"><b>{ story.user.screen_name }</b></span><br/>
                  {
                    story.user.lived_neighborhood && story.user.length_residence ?
                    <Fragment>
                      who lives { story.user.lived_neighborhood } for { story.user.length_residence } years
                    </Fragment> : null
                  }
                </p>
              </Fragment> : null
            }
          </div>
          <div className="right">
            <p className="small">
              { moment.unix(story.timestamp).format("MMMM YYYY") }<br/>
              { convertTitle(STORY_CATEGORY[story.category_id].listLabel) }
            </p>
          </div>
        </LabelArea>
        {
          this.state.clicked ?
          <ButtonArea>
            <BlueButton onClick={this.handleClick.bind(this)} selected={true}>
              Show the { STORY_CATEGORY[story.category_id].listLabel }
            </BlueButton>
          </ButtonArea> : null
        }
      </ContentLink>
    );
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps)(StoryList);