import styled from 'styled-components';
import mixins from './mixins';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Input } from 'final-form-material-ui';
import { SHADOW_COLOR, RED_COLOR } from '../constants/defaults';
import IconButton from '@material-ui/core/IconButton';
import chroma from 'chroma-js';
import media from '../stylesheets/media';
// const CustomTextField = 

const CustomTextField = withStyles({
  root: {
    width: "100%",
    borderBottom: "1px solid #333",
    '& .MuiInputBase-input': {
      color: "white"
    },
    '& label.Mui-focused': {
      color: RED_COLOR
    },
    '& .MuiInput-underline': {
      borderBottomColor: '#555',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: RED_COLOR
    }

  }
})(TextField);



const ButtonCenter = styled.div`
  display: flex;
  justify-content: center;
`;


const PrimaryButtonA = styled.a`
  background: #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  font-size:1.0em;
`;


const SmallTitle = styled.div`
  font-family: "Source Sans Pro";
  font-weight:600;
  font-size: 0.875em;
  line-height: 1.1;
  color: #555;
`;


const PrimaryButton = styled(Link)`
  background: #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  font-size:1.0em;
`;


const ButtonA = styled.a`
  background: #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  font-size:1.0em;
`;

const ButtonLink = styled(Link)`
  background: #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  font-size:1.0em;
`;

const PrimaryButtonBtn = styled.button`
  background: #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  font-size:1.0em;

  &:disabled {
    background: #999;
    box-shadow: none;
  }
`;

const SmallLabel = styled.div`
  font-size:12px;
  color: #696969;
  font-family: "Source Sans Pro";
`;

const Tab = styled.div`
  padding: 8px 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const TabButton = styled.button`

  border:none;

  background: ${props => props.selected ? SHADOW_COLOR : "#1d1d1d"};
  box-shadow: 0 2px 0 ${props => props.selected ? RED_COLOR : "#303030"};
  color: ${props => props.selected ? "#FFFFFF" : "#474747"};
  border-radius: 10px 10px 0 0;
  padding: 10px;

  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
`;

const Gutter = styled.div`
  height: ${props => props.h}px;
`;


const BlueButton = styled.button`
  border:none;
  background: ${props => props.selected ? SHADOW_COLOR : "#1d1d1d"};
  box-shadow: 2px 2px 0px ${props => props.selected ? RED_COLOR : "#303030"};
  color: ${props => props.selected ? "#FFFFFF" : "#474747"};
  
  border-radius: 5px;
  padding: 12px 15px;

  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s all;

  :disabled {
    background: #999999;
    box-shadow: 2px 2px 0px #888888;
    color: #AAAAAA;
  }

  &:hover, &:active {
    background: ${ RED_COLOR };
    box-shadow: 3px 3px 0px ${props => props.selected ? chroma(RED_COLOR).darken(1.5).hex() : "#303030"};
  }

`;


const LogoLink = styled(Link)`
  position: fixed;
  left: 15px;
  top: 15px;
  z-index: 1000;
`;

const BlueInput = styled.input`
  border:none;
  background: ${props => props.selected ? SHADOW_COLOR : "#1d1d1d"};
  box-shadow: 2px 2px 0px ${props => props.selected ? RED_COLOR : "#303030"};
  color: ${props => props.selected ? "#FFFFFF" : "#474747"};
  
  border-radius: 5px;
  padding: 12px 15px;

  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s all;

  text-align: center;
  cursor: pointer;
  

  &:hover, &:active {
    background: ${ RED_COLOR };
    box-shadow: 3px 3px 0px ${props => props.selected ? chroma(RED_COLOR).darken(1.5).hex() : "#303030"};
  }

  :disabled {
    background-color: #676767;
    box-shadow: 2px 2px 0px #868686;
    color: #9a9a9a;

    &:hover, &:active {
      background-color: #676767;
      box-shadow: 2px 2px 0px #868686;
      color: #9a9a9a;
    }

`;


const BackLink = styled.button`
  border: none;
  background:transparent;
  display: flex;
  align-items: flex-start;
  font-size: 0.9em;
  padding: 0;
  margin: 0;
  cursor: pointer;
  div {
    color: ${props => props.selected ? "white" : "#555555" };
    margin-left: 2px;
    margin-top: 2px;
  }
`;

const LeftContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: calc(50vw - 30px);
  padding: 15px;
  height: calc(100% - 95px);
  padding-top: 80px;
  overflow-y: scroll;
  background: black;
  /* background: #FBECE8;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px #1125AA;
  border-radius: 10px; */

  ${media.mobileSmall`
    width: calc(100vw - 30px);
    height: calc(50% - 80px);
  `}
    
  
`;

const WindowsSubtitle = styled.div`
  font-size: 1.0em;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  color: #333;
  border-bottom: 1px dotted #444444;
  margin-bottom: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  line-height:1.3;
`;

const WindowsBigtitle = styled.h2`
  margin: 20px 0 10px 0;
  font-family: "Heldane Display";
  font-size: 3.125em;
  color: white;
  line-height: 1.1em;

  span.blue {
    color: ${RED_COLOR};
  }

  ${media.smallDesktop`
    font-size:2.5em;
  `}

  ${media.padLandscape`
    font-size:2.2em;
  `}

  
`;


const DateIndicator = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 0.9em;
  font-family: "Source Sans Pro";
  color: white;
  z-index: 5;
  text-align: center;

  background: black;
  border: 1px solid ${SHADOW_COLOR};
  box-sizing: border-box;
  /* box-shadow: 2px 2px 0px ${RED_COLOR}; */
  border-radius: 10px;
  
`;


const IconWhiteButton = withStyles({
  root: {
    color: "white"
  }
})(IconButton);



const PositionIndicator = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9em;
  font-family: "Source Sans Pro";
  color: white;
  z-index: 5;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: crosshair;
  min-width: 150px;
`;

const ScreenNameArea = styled.div`
  padding-bottom: 15px;
  font-size:0.9em;
  color: #333;

  b {
    font-weight: 600;
  }
`;



const ContentLink = styled.a`

  border:none;
  background: transparent;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  transition: 0.4s opacity;
  opacity: 1;
  &:hover {
    background-color: #111;
    opacity: 1;
  }

  border-bottom: 1px dotted #222;
  padding-bottom: 15px;
  margin-bottom: 30px;

  &.selected {
    background: #1A1A1A
  }
`;

const PoiStreetView = styled.div`

  position: relative;
  overflow: hidden;
  height: 200px;

  img.all {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  
  img.left {
    width: calc(50% - 30px);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  img.right {
    width: calc(50% - 30px);
    position: absolute;
    left: calc(50% + 30px);
    top: 50%;
    transform: translate(0, -50%);
  }

  div.arrow {
    position: absolute;;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;



const LabelArea = styled.div`
  display: flex;
  margin-top: 10px;
  padding-bottom: 7px;
  justify-content: space-between;
  align-items: flex-start;

  div.left {
    width: calc(100% - 150px);

    ${media.mobileLarge`
      width: 100%;
    `}
  }

  div.right {
    width: 150px;
    text-align: right;
  }

  div.indicator-area {
    margin-left: 10px;
  }

  p.category {
    font-family: "Source Sans Pro";
    font-size: 12px;
    line-height: 1.2;
    color: #828282;
  }
  p.small {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;
    color: #828282;
  }

  span.black {
    font-weight: 600;
  }
`


const ListTitle = styled.h3`

  font-family: "Heldane Display";
  font-size: 20px;
  line-height: 1.2;
  color: white;
  
`;

const ListButtonArea = styled.div`
  margin: 10px 0;
`;


const WindowHeader = styled.div`
  padding: 5px 10px;
  background: black;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div.title {
    font-size: 0.85em;
    font-family: 'Source Sans Pro';
    color: white;
    margin-left: 5px;
  }

  div.left-wrap {
    display: flex;
    align-items: center;
  }
`;


const TitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.left {
    width: calc(60% - 20px);
  }

  div.right {
    width: calc(40% - 20px);
    display: flex;
    align-items: center;
  }

  h2.title {
    font-family: "Heldane Display";
    font-size: 3.125em;
    color: white;
    line-height: 0.9;


    ${media.smallDesktop`
      font-size:2.5em;
    `}

    ${media.padLandscape`
      font-size:2.2em;
    `}
  }
  p.small {  
    font-family: "Source Sans Pro";
    font-size: 0.875em;
    margin-right: 10px;
    line-height: 1.3;
    color: #A1A1A1;
  }

  a {
    color: #BBB;
    text-decoration: underline;
  }

  div.neighborhoods-subtitle {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 7px 0;
    margin: 7px 0; 
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;
  }
`;


const ConsentText = styled.div`
  font-size: 0.9em;
  font-weight: 400;
  font-style: italic;
  color: #999;
  margin: 10px 0;
`;

export {
  ConsentText,
  WindowHeader,
  PrimaryButton,
  PrimaryButtonA,
  PrimaryButtonBtn,
  ButtonA,
  Gutter,
  ButtonLink,
  SmallLabel,
  BlueButton,
  BlueInput,
  SmallTitle,
  LeftContainer,
  WindowsSubtitle,
  WindowsBigtitle,
  CustomTextField,
  DateIndicator,
  IconWhiteButton,
  PositionIndicator,
  BackLink,
  ButtonCenter,
  Tab,
  TabButton,
  ScreenNameArea,
  ContentLink,
  LabelArea,
  PoiStreetView,
  ListTitle,
  ListButtonArea,
  TitleArea,
  LogoLink
};