import React from 'react';
import _ from 'lodash';
import { logIn, changeNoticeMessage, changeCSRFToken, changeProgress } from '../actions';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { DeviseError } from '../components';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LeftContainer, Gutter, CustomTextField, WindowsBigtitle, WindowsSubtitle, PrimaryButton, ButtonA, PrimaryButtonBtn, ButtonLink, BlueButton } from '../stylesheets/components'
import { Button } from '@material-ui/core';
import { RED_COLOR } from '../constants/defaults';


const Fragment = React.Fragment;
const NewBox = styled.div`
  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  width: 414px;
  padding: 20px;
  background: white;
  padding: 20px;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.15);
  z-index: 6;


  @media (max-width: 768px) {
    
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    box-shadow: none;
    left: 0;
    top: 0;
    transform: none;
  }
`;

const Title = styled.h2`
  font-family: "Noh Optique Display";
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-align: center;
`

const CloseBtn = styled(Link)`
  position: absolute;
  right: 20px;
  top: 20px;
`
const ContLabel = styled.label`
  font-weight: 600;
  color: #333333;
  margin: 5px 0;
  display: block;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;


const FormField = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  label {
    color: #828282;
  }

  input {
    border: none;
    width: 330px;
    font-size: 1.0em;
    background:transparent;
  }

  &:last-child {
    border-bottom: none;
  }
`;


const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    margin-left: 5px;
  }
`;

const WhiteLink = styled(Link)`
  color: #AAA;
`;

const Flash = styled.div`
  
`;


class SignIn extends React.Component {
  constructor(props) {
    super(props);
  }
  handleSubmit(e) {
    // debugger;
  }


  componentDidMount() {
  }

  async handleSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));
    const response = await fetch("/users/sign_in", { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      this.props.dispatch(logIn(data.user));
      this.props.dispatch(changeCSRFToken(data.csrf_token));
      this.props.dispatch(changeNoticeMessage("Successfully Signed In."))
      this.props.history.push('/contribute');
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();

      this.props.dispatch(changeProgress(false));
      return data;

    }


    // if (response.ok) {

    // } else {
    //   let rr = response.clone().json();
    //   console.log(rr)
    //   return rr;
    // }
  }

  handleValidate(values) {
    const errors = {};
    if (values.user) {


      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = 'Required';
      }

      if (!values.user.password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password = 'Required';
      }



    }
    return errors;
  }


  render() {
    let { errors } = this.props;
    return (
      <LeftContainer>
        <WindowsBigtitle>
          Sign in
        </WindowsBigtitle>

        <WindowsSubtitle style={{color: "#AAA"}}>
          Create an account or log in to start to map the neighborhood change and add your memories of beloved places.
        </WindowsSubtitle>
        

        {
          _.isUndefined(errors) ?
            null :
            <DeviseError {...errors} />
        }
        {
          this.props.flash ? 
          <Fragment>

            {
              this.props.flash.alert ? null :
                <div>
                  {this.props.flash.alert}
                  <Gutter h={6} />
                </div>
            }

            {
              this.props.flash.notice ? null :
                <div>
                  {this.props.flash.notice}
                  <Gutter h={6} />
                </div>
            }
          </Fragment> : null
        }
        
        
        <Form
          onSubmit={this.handleSubmit.bind(this)}
          validate={this.handleValidate.bind(this)}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <FormField>
                <Field
                  fullWidth
                  required
                  name="user[email]"
                  component={CustomTextField}
                  type="email"
                  label="Email"
                />
              </FormField>
              <FormField>
                <Field
                  fullWidth
                  required
                  name="user[password]"
                  component={CustomTextField}
                  type="password"
                  label="Password"
                />
              </FormField>
              <Gutter h={20} />

              <ButtonArea>
                <div>
                  <BlueButton
                    selected={true} 
                    disabled={submitting}>
                    Sign in
                  </BlueButton>
                  &nbsp;

                  <WhiteLink to="/users/sign_up">
                    Create Account
                  </WhiteLink>
                </div>
                <div>
                  <WhiteLink to="/users/password/new">Forgot password?</WhiteLink>
                </div>
              </ButtonArea>
            </form>
          )}
        />     

      </LeftContainer>
    );
  }
}

let mapStateToProps = state => {
  return {
    new_password_path: state.new_password_path,
    new_registration_path: state.new_registration_path,
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(SignIn);