import React from 'react';
import _ from 'lodash';
import { windowResize, changeLoginInfo, changeCSRFToken, changeNeighborhoodBoundaries, changeFocusedNeighborhoods } from '../actions';
import { connect } from 'react-redux';
import { MapContainer, Header, Notice, LoadingBar, CustomSnackBar, Footer, FirstPopup, ContributeTitle, FocusedNeighborhoodWindow, NeighborhoodWindow } from '../components';
import styled from 'styled-components';
import About from './About';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Intro from './Intro';
import PasswordEdit from './PasswordEdit';
import PasswordNew from './PasswordNew';
import StreetviewShow from './StreetviewShow';
import NeighborhoodShow from './NeighborhoodShow';
import UserShow from './UserShow';
import { Route, Switch, Link } from "react-router-dom";
import { withRouter } from 'react-router';
import TutorialShow from './TutorialShow';
import StreetviewTutorialShow from './StreetviewTutorialShow';

const Fragment = React.Fragment;
const LitterReportBtn = styled.a`
  position: fixed;
  right: 10px;
  bottom: 10px;
  transform: translate(-50%, 0);
  z-index: 5;

  @media (max-width: 414px) {
    left: auto;
    transform: none;
    right: 10px;
    bottom: 10px;
  }
`;
const LegendContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: -11px;
  z-index: 5;
`;


const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;


class Welcome extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstPopup: true
    }
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    this.props.dispatch(changeCSRFToken(document.querySelector('meta[name="csrf-token"]').getAttribute('content')));

  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();

  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }

  componentDidUpdate(){
  }

  handlePopupClose(){
    this.setState({
      firstPopup: false
    });
  }
  render() {
    let { isFullScreen, progress, intro, noticeMessage, loading, tutorialPopup, streetviewTutorialPopup } = this.props;
    return (
      <Fragment>
        {
          noticeMessage ? 
          <Notice /> : null
        }
        <Switch>
          <Route exact path="/">
            <Header />
            <Intro />
            <MapContainer />
        
          </Route>


          <Route exact path="/about">
            <Header />
            <About />
            <MapContainer />
          </Route>


          <Route exact path="/contribute">
            <Header />
            {/* {
              this.state.firstPopup ?
              <FirstPopup handleClose={this.handlePopupClose.bind(this)} /> : null
            } */}

            {
              isFullScreen ? 
              null : 
              <FocusedNeighborhoodWindow />
            }
            <Footer />
            <MapContainer />
          </Route>


          <Route exact path="/neighborhoods/:permalink">
            <Header />
            <NeighborhoodShow />
            {
              isFullScreen ? 
              null : 
              <NeighborhoodWindow />
            }
            <MapContainer />
            <Footer />
          </Route>


          <Route exact path="/users/sign_up">
            <Header />
            <SignUp {...this.props} />
            <Footer />
            <MapContainer />
          </Route>

          <Route exact path="/users/sign_in">
            
            <Header />
            <SignIn {...this.props} />
            <Footer />
            <MapContainer />
          </Route>


          <Route exact path="/users/edit">
            
            <Header />
            <UserShow {...this.props} />
            <Footer />
            <MapContainer />
          </Route>



          <Route exact path="/users/password/new">
            <Header />
            <PasswordNew {...this.props} />
            <Footer />
            <MapContainer />
          </Route>

          <Route exact path="/streetviews/:streetview_id/stories">
            <StreetviewShow {...this.props} />
          </Route>

          <Route exact path="/streetviews/:streetview_id">
            <StreetviewShow {...this.props} />
          </Route>


          <Route exact path="/pois/:gid">
            <StreetviewShow {...this.props} />
          </Route>



          <Route exact path="/stories/:story_id">
            <StreetviewShow {...this.props} />
          </Route>



          <Route exact path="/users/password/new">
 
            <Header />
            <PasswordNew {...this.props} />
            <Footer />
            <MapContainer />
          </Route>


          <Route path="/users/password/edit">
            <Header />
            <PasswordEdit {...this.props} />
            <Footer />
            <MapContainer />
          </Route>

        </Switch>

        
        {
          tutorialPopup ? 
          <TutorialShow /> : null
        }

        {
          streetviewTutorialPopup ? 
          <StreetviewTutorialShow /> : null
        }

        <CustomSnackBar />
        {
          progress ?
          <LoadingBar />
          : null
        }
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    intro: state.intro,
    noticeMessage: state.noticeMessage,
    currentContribution: state.currentContribution,
    loading: state.loading,
    instructions: state.instructions,
    tutorialPopup: state.tutorialPopup,
    streetviewTutorialPopup: state.streetviewTutorialPopup,
    progress: state.progress,
    isFullScreen: state.isFullScreen
  }
}

export default withRouter(connect(mapStateToProps)(Welcome));