import React from 'react';
import _ from 'lodash';
import { windowResize, changeProgress, changeNoticeMessage } from '../actions';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TextField, Input } from 'final-form-material-ui';
import { CustomTextField, LeftContainer, Gutter, WindowsBigtitle, WindowsSubtitle, BlueButton } from '../stylesheets/components';
import { Button } from '@material-ui/core';


const Fragment = React.Fragment;
const NewBox = styled.div`
  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  width: 414px;
  padding: 20px;
  background: white;
  padding: 20px;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.15);
  z-index: 6;


  @media (max-width: 768px) {
    
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    box-shadow: none;
    left: 0;
    top: 0;
    transform: none;
  }
`;

const Title = styled.h2`
  font-family: "Noh Optique Display";
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-align: center;
`

const CloseBtn = styled(Link)`
  position: absolute;
  right: 20px;
  top: 20px;
`
const ContLabel = styled.label`
  font-weight: 600;
  color: #333333;
  margin: 5px 0;
  display: block;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;


const FormField = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  label {
    color: #828282;
  }

  input {
    border: none;
    width: 330px;
    font-size: 1.0em;
    background:transparent;
  }

  &:last-child {
    border-bottom: none;
  }
`;


const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    margin-left: 5px;
  }
`;

const Flash = styled.div`
  
`;

class PasswordNew extends React.Component {
  constructor(props) {
    super(props);
  }
  
  async handleSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));
    const response = await fetch("/users/password", { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      console.log(data);
      this.props.dispatch(changeNoticeMessage(`Just sent an email to ${data.email}. Please check your inbox!`))
      this.props.history.push('/users/sign_in');
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }


    // if (response.ok) {

    // } else {
    //   let rr = response.clone().json();
    //   console.log(rr)
    //   return rr;
    // }
  }


  componentDidMount() {
  }


  handleValidate(values) {
    const errors = {};
    if (values.user) {


      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = 'Required';
      }



    }
    return errors;
  }



  render() {
    let { errors } = this.props;


    return (
      <LeftContainer>
        <WindowsBigtitle>
          Reset Password
        </WindowsBigtitle>

        <WindowsSubtitle>
          Please enter your email address. We'll send an email to reset the password.
        </WindowsSubtitle>


        {
          _.isUndefined(errors) ?
            null :
            <DeviseError {...errors} />
        }
        {
          this.props.flash ?
            <Fragment>

              {
                _.isUndefined(this.props.flash.alert) ? null :
                  <div>
                    {this.props.flash.alert}
                    <Gutter h={6} />
                  </div>
              }

              {
                _.isUndefined(this.props.flash.notice) ? null :
                  <div>
                    {this.props.flash.notice}
                    <Gutter h={6} />
                  </div>
              }
            </Fragment> : null
        }


        <Form
          onSubmit={this.handleSubmit.bind(this)}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <FormField>
                <Field
                  fullWidth
                  required
                  name="user[email]"
                  component={CustomTextField}
                  type="email"
                  label="Email"
                />
              </FormField>
              
              <Gutter h={20} />

              <ButtonArea>
                <div>
                  <BlueButton
                    selected={true} 
                    disabled={submitting}>
                    Submit
                  </BlueButton>
                </div>
              </ButtonArea>
            </form>
          )}
        />

      </LeftContainer>
    );
  }
}

let mapStateToProps = state => {
  return {
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(PasswordNew);