import React, { Component } from 'react'
import styled from 'styled-components';
import { Legend, NeighborhoodTimeline, LegendNeighborhood } from './';
import { connect } from 'react-redux';
import { BlueButton } from '../stylesheets/components';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const LegendArea = styled.div`
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index:5;
`;

const NeighborhoodSelectArea = styled.div`
  padding-bottom:5px;
`;

const MinimizeButton = styled.div`
  border: 1px solid #333;
  padding: 0px 4px 3px 4px;
  background-color: transparent;
  cursor: pointer;
  color: black;
`;

const TimelineMinimizeBlueButton = styled(BlueButton)`
  position: fixed;
  left: 15px;
  bottom: 15px;
  z-index:5;
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimize: window.innerWidth <= 414,
      timelineMinimize: window.innerWidth <= 414
    }
  }

  handleMaximize(e){
    this.setState({
      minimize: false,
      timelineMinimize: true
    });
  }


  handleMinimize(e){
    this.setState({
      minimize: true
    });
  }


  handleTimelineMaximize(e){
    this.setState({
      minimize: true,
      timelineMinimize: false
    });
  }


  handleTimelineMinimize(e){
    this.setState({
      timelineMinimize: true
    });
  }

  
  render() {
    let { minimize, timelineMinimize } = this.state;
    let { mapZoom } = this.props;

    return (
      <Fragment>

        {       
          mapZoom > 15 ?
          (
            timelineMinimize ? 
            <TimelineMinimizeBlueButton style={{ backgroundColor: "black"}} selected={true} onClick={this.handleTimelineMaximize.bind(this)}>
              <MinimizeButton>
                <svg width="13" height="7" viewBox="0 0 13 7" fill="none">
                  <path d="M12 6.5L6 0.999999L0.5 6.5" stroke="white"/>
                </svg>
              </MinimizeButton>
            </TimelineMinimizeBlueButton> :
            <NeighborhoodTimeline handleMinimize={this.handleTimelineMinimize.bind(this)} />
          )
          : null
        }
        <LegendArea>
          {
            minimize ? 
            <BlueButton style={{ backgroundColor: "black"}} selected={true} onClick={this.handleMaximize.bind(this)}>
              <MinimizeButton>
                <svg width="13" height="7" viewBox="0 0 13 7" fill="none">
                  <path d="M12 6.5L6 0.999999L0.5 6.5" stroke="white"/>
                </svg>
              </MinimizeButton>
            </BlueButton> :
            (
              mapZoom > 15 ?
              <Legend handleMinimize={this.handleMinimize.bind(this)} /> : 
              <LegendNeighborhood handleMinimize={this.handleMinimize.bind(this)} />
            )
          }
        </LegendArea>
      </Fragment>
      
    )
  }
}

let mapStateToProps = state => {
  return {
    mapZoom: state.mapZoom
  }
}

export default connect(mapStateToProps)(Footer);