import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Gutter } from '../stylesheets/components';
import { toTitleCase, randomBetween } from '../utils';
import moment from 'moment';
import { IDENTIFICATION_CATEGORY } from '../constants/defaults';

const Container = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  padding: 10px;
  left:0;
  top: 0;
  transform: translate(-50%, -50%);
  z-index:5;
  opacity: 0.8;
  /* transform: rotate(${randomBetween(-5, 5)}deg); */


`;

const Title = styled.div`
  color: white;
  font-family: "Source Sans Pro";
  text-align: center;
  line-height:1.0;
  font-size: 0.7em;
  font-weight: 600;
  line-height: 1.0;
  border-radius: 5px;
`;


class Identification extends Component {
  constructor(props) {
    super(props);
    // dimensions of marker container (resized according to current pov)
    this.markerWidth = 200;
    this.markerHeight = 80;


    this.sheading = 0;
    this.spitch = 0;
    this.szoom = 1.0885819903568759;
  }
  getStyle() {
    var { GSVPOV, identification, containerWidth, windowHeight } = this.props;

      let style = this.povToPixel3d({
        heading: identification.heading,
        pitch: identification.pitch
      }, GSVPOV, GSVPOV.zoom, {
        offsetWidth: containerWidth,
        offsetHeight: windowHeight
      });

      return style;
    }

    get3dFov(zoom) {
      return zoom <= 2 ?
        126.5 - zoom * 36.75 :  // linear descent
        195.93 / Math.pow(1.92, zoom); // parameters determined experimentally
    }

    povToPixel3d(targetPov, currentPov, zoom, viewport) {

      // Gather required variables and convert to radians where necessary
      var width = viewport.offsetWidth;
      var height = viewport.offsetHeight;
      var target = {
        left: width / 2,
        top: height / 2
      };

      var DEG_TO_RAD = Math.PI / 180.0;
      var fov = this.get3dFov(zoom) * DEG_TO_RAD;
      var h0 = currentPov.heading * DEG_TO_RAD;
      var p0 = currentPov.pitch * DEG_TO_RAD;
      var h = targetPov.heading * DEG_TO_RAD;
      var p = targetPov.pitch * DEG_TO_RAD;

      // f = focal length = distance of current POV to image plane
      var f = (width / 2) / Math.tan(fov / 2);

      // our coordinate system: camera at (0,0,0), heading = pitch = 0 at (0,f,0)
      // calculate 3d coordinates of viewport center and target
      var cos_p = Math.cos(p);
      var sin_p = Math.sin(p);

      var cos_h = Math.cos(h);
      var sin_h = Math.sin(h);

      var x = f * cos_p * sin_h;
      var y = f * cos_p * cos_h;
      var z = f * sin_p;

      var cos_p0 = Math.cos(p0);
      var sin_p0 = Math.sin(p0);

      var cos_h0 = Math.cos(h0);
      var sin_h0 = Math.sin(h0);

      var x0 = f * cos_p0 * sin_h0;
      var y0 = f * cos_p0 * cos_h0;
      var z0 = f * sin_p0;

      var nDotD = x0 * x + y0 * y + z0 * z;
      var nDotC = x0 * x0 + y0 * y0 + z0 * z0;

      // nDotD == |targetVec| * |currentVec| * cos(theta)
      // nDotC == |currentVec| * |currentVec| * 1
      // Note: |currentVec| == |targetVec| == f

      // Sanity check: the vectors shouldn't be perpendicular because the line
      // from camera through target would never intersect with the image plane
      if (Math.abs(nDotD) < 1e-6) {

        return {
          display: "none"
        };
      }

      // t is the scale to use for the target vector such that its end
      // touches the image plane. It's equal to 1/cos(theta) ==
      //     (distance from camera to image plane through target) /
      //     (distance from camera to target == f)
      var t = nDotC / nDotD;

      // Sanity check: it doesn't make sense to scale the vector in a negative
      // direction. In fact, it should even be t >= 1.0 since the image plane
      // is always outside the pano sphere (except at the viewport center)
      if (t < 0.0) {

        return {
          display: "none"
        };
      }

      // (tx, ty, tz) are the coordinates of the intersection point between a
      // line through camera and target with the image plane
      var tx = t * x;
      var ty = t * y;
      var tz = t * z;

      // u and v are the basis vectors for the image plane
      var vx = -sin_p0 * sin_h0;
      var vy = -sin_p0 * cos_h0;
      var vz = cos_p0;

      var ux = cos_h0;
      var uy = -sin_h0;
      var uz = 0;

      // normalize horiz. basis vector to obtain orthonormal basis
      var ul = Math.sqrt(ux * ux + uy * uy + uz * uz);
      ux /= ul;
      uy /= ul;
      uz /= ul;

      // project the intersection point t onto the basis to obtain offsets in
      // terms of actual pixels in the viewport
      var du = tx * ux + ty * uy + tz * uz;
      var dv = tx * vx + ty * vy + tz * vz;

      // use the calculated pixel offsets
      target.left += du;
      target.top -= dv;
      return {
        ...target,
        display: "block"
      }
    };


  render() {
    let { identification } = this.props;
    let style = this.getStyle();

    return (
      <Container style={style}>
        <Title>
          {IDENTIFICATION_CATEGORY[identification.category_id].label}
        </Title>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    GSVPOV: state.GSVPOV,
    windowHeight: state.windowHeight
  }
}

export default connect(mapStateToProps)(Identification);