import React, { Component } from 'react'
import _ from 'lodash';
import styled from 'styled-components';

const Fragment = React.Fragment;
const Title = styled.div`
  font-weight: bold;

`;

class DeviseError extends Component {
  render() {
    return (
      <div id="error_explanation">
        
        <Title>
          {this.props.not_saved_message}
        </Title>

        <ul>
          {
            _.map(this.props.full_messages, (message, i) => {
              return (
                <li key={i}>
                  {message}
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

export default DeviseError;
