import React, { Component, Fragment } from 'react'
import styled from 'styled-components';
import { changeCurrentNeighborhood, changeSelectedPanoidIndex, changeNoticeMessage, changeNeighborhoodSearchResults, changeProgress } from '../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const Container = styled.div`
  position:fixed;
  
  left: 0;
  top: 50px;
  z-index: 5;
  width: 100vw;
  height: calc(100vh - 50px);
  background: rgba(0, 0, 0, 0.9);

  color: white;
  display: flex;
  justify-content: space-between;
`;

class NeighborhoodShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }


  componentDidMount() {
    this.loadCurrentNeighborhood();


    // this.updateNeighborhood();


  }
  componentWillUnmount() {
    // debugger;
  }

  componentDidUpdate(prevProps) {

    if (prevProps) {

      if (prevProps.match) {
        if (prevProps.match.params.permalink !== this.props.match.params.permalink) {
          // debugger;
          this.loadCurrentNeighborhood();
        } else if (!this.props.currentNeighborhood) {
          this.loadCurrentNeighborhood();
        }
      }
      
    }
  }


  async loadCurrentNeighborhood() {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    if (!this.state.loading) {
      this.props.dispatch(changeProgress(true));
      this.setState({
        loading: true
      });


      const response = await fetch(`/neighborhoods/${this.props.match.params.permalink}.json`, { method: "get" });
      if (response.ok) {
        let data = await response.json();

        this.props.dispatch(changeNeighborhoodSearchResults([]));
        this.props.dispatch(changeCurrentNeighborhood(data.neighborhood));
        
        this.setState({
          loading: false
        });
        this.props.dispatch(changeProgress(false));
  

      } else {
        let data = await response.json();

        this.setState({
          loading: false
        });
        this.props.dispatch(changeProgress(false));
        return data;
      }
    }

  }

  render() {

    return (
      <div>

      </div>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    currentNeighborhood: state.currentNeighborhood
  }
}


export default withRouter(connect(mapStateToProps)(NeighborhoodShow));

