import React, { Component } from 'react'
import { connect } from 'react-redux';
import { PoiLabel, Identification, IdentifyPlaceWindow, NearestStreetview } from './';
import { changeActivePanoIDScreen, changeGSVPOV, changeIdentificationCategory, changeCurrentStreetview, changeIdentifyPlaceStep, changeIdentifyPov } from '../actions';
import styled from 'styled-components';
import { IDENTIFICATION_CATEGORY, ZOOMFACTOR } from '../constants/defaults';
import moment from 'moment';
import PointCloud from './PointCloud';
import { toRadians, panoDateToRichDate } from '../utils';
import { DateIndicator, PositionIndicator } from '../stylesheets/components';

const Container = styled.div`
  width: 50vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: relative;
  color: white;
  overflow: hidden;
`;

class PanoIDViewer extends Component {
  constructor(props){
    super(props);
    this.containerRef = React.createRef();
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handlePointerUp = this.handlePointerUp.bind(this);
    this.handlePOVChanged = this.handlePOVChanged.bind(this);
    this.handlePositionChanged = this.handlePositionChanged.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleZoomChanged = this.handleZoomChanged.bind(this);
    this.state = {
      mouseLocation: [0, 0],
      lastMouseLocation: [0, 0]
    };

    this.pointCloud = new PointCloud();

    this.pov = null;
  }
  
  removeDevSigns() {

    this.devSignIntervalId = setInterval(() => {
      let iterateCheck = false;

      var xpath = "//div[text()='For development purposes only']";
      var matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
      var dismissButtonElem = document.querySelector(".dismissButton");

      if (dismissButtonElem ||
        matchingElement) {

        if (dismissButtonElem) {
          dismissButtonElem.parentElement.parentElement.parentElement.parentElement.remove();
        }
        if (matchingElement) {
          matchingElement.remove();
        }

      }

    }, 500);

  }


  componentDidMount() {
    this.removeDevSigns();

    let { currentPanoID, screenID } = this.props;
    let domElement = this.containerRef.current;

    this.panorama = new google.maps.StreetViewPanorama(domElement, {
      pano: currentPanoID.pano_id,
      visible: true,
      disableDefaultUI: true,
      clickToGo: false
    });
    google.maps.event.addListener(this.panorama, 'pov_changed', this.handlePOVChanged);
    google.maps.event.addListener(this.panorama, 'zoom_changed', this.handleZoomChanged);
  
  
    

    this.pointCloud.getPointCloud(currentPanoID.pano_id);


    domElement.addEventListener('mousedown', this.handlePointerDown, false);
    domElement.addEventListener('mousemove', this.handleMouseMove, false);
    document.addEventListener('mouseup', this.handlePointerUp, false);
    window.addEventListener("wheel", this.handleWheel, false);

    
  }

  componentWillUnmount() {
    let domElement = this.containerRef.current;
    document.removeEventListener('mouseup', this.handlePointerUp, false);
    domElement.removeEventListener('mousedown', this.handlePointerDown, false);
    window.removeEventListener("wheel", this.handleWheel, false);
  }

  handleZoomChanged(e){
    this.setActiveInteraction();
  }
  handlePositionChanged(e){
    // debugger;
  }


  handlePointerDown(e) {
    this.setActiveInteraction();
  }

  handlePointerUp(e) {
    // this.disableActiveInteraction();

  }

  setActiveInteraction() {
    if (this.props.identifyPlaceStep === 0) {
      this.props.dispatch(changeActivePanoIDScreen(this.props.screenID));
    }
  }

  disableActiveInteraction() {
    this.props.dispatch(changeActivePanoIDScreen(null));

  }



  handlePOVChanged(e){
    this.dispatchPov();
  }

  dispatchPov(){

    let { activePanoIDScreen, screenID } = this.props;
    if (screenID === activePanoIDScreen) {
      let pov = this.panorama.getPov();

      this.props.dispatch(changeGSVPOV(pov));

    } 

  }


  componentDidUpdate(prevProps) {
    let { width, windowHeight, currentPanoID, currentStreetview, activePanoIDScreen, screenID } = this.props;

    let domElement = this.containerRef.current;
    let gmStyle = domElement.querySelector(".gm-style");
    if (gmStyle) {

      if (this.props.now) {
        gmStyle.style.filter = "hue-rotate(180deg)";
      } else {
        gmStyle.style.filter = "none";
      }
      
    }

    if (this.props.currentPanoID) {
      if (prevProps.currentPanoID) {
        if (prevProps.currentPanoID.id !== this.props.currentPanoID.id) {
          this.updateCurrentPanoID();
        }
      } else {
        this.updateCurrentPanoID();
      }
    }


    if (screenID !== activePanoIDScreen) {
      if (this.isPOVChanged(prevProps.GSVPOV, this.props.GSVPOV)){
        this.panorama.setOptions({
          pov: this.props.GSVPOV
        });
      }
    }

    if (this.props.identificationActivated) {
      
      _.each(document.querySelectorAll(".widget-scene"), elem => {
        elem.style.cursor = "crosshair !important";
      });
    }
  }

  isPOVChanged(prevGSVPOV, GSVPOV) {
    return prevGSVPOV.zoom !== GSVPOV.zoom || 
           prevGSVPOV.pitch !== GSVPOV.pitch ||
           prevGSVPOV.heading !== GSVPOV.heading;
  }

  updateCurrentPanoID(){
    let { currentPanoID } = this.props;
    this.panorama.setPano(currentPanoID.pano_id);
    this.pointCloud.getPointCloud(currentPanoID.pano_id);
  }

  handlePosClick(e) {

    let { identifyPlaceStep, GSVPOV } = this.props;
    
    if (identifyPlaceStep === 1) {

      let pos = [e.clientX, e.clientY];
      let { windowWidth, screenID } = this.props;

      if (screenID === "right") {
        pos[0] = pos[0] - windowWidth * 0.5;
      }

      // let pov = this.unproject(pos);
      let pov = this.unproject(pos[0], pos[1]);

      let imagePos = this.calculateImageCoordinateFromPointPov({
        ...pov,
        zoom: GSVPOV.zoom
      });
      let latLng = this.toLatLng(imagePos, this.pointCloud);
      // debugger;
      this.props.dispatch(changeIdentifyPov({
        pov: pov,
        imageCoordinate: imagePos,
        pickedLatLng: latLng
      }));

      this.props.dispatch(changeIdentifyPlaceStep(2));

      this.setState({
        lastMouseLocation: pos
      });

      this.props.dispatch(changeActivePanoIDScreen(this.props.screenID));
    }
  }

  scaleImageCoordinate(x, y, r) {
    var x_ = x * r;
    var y_ = (3328 - y) * r;
    return { x: x_, y: y_ };
  }

  latlngOffset(lat, dx, dy) {
    var dlat = dy / 111111;
    var dlng = dx / (111111 * Math.cos(toRadians(lat)));
    return { dlat: dlat, dlng: dlng };
  }

  toLatLng(imageCoordinate, pointCloud){
    let { currentPanoID } = this.props;
    var minDx = 1000, minDy = 1000, i, delta, latlng,
    p, idx, dx, dy, r, minR;

    p = this.scaleImageCoordinate(imageCoordinate.x, imageCoordinate.y, 1 / 26);
    let pc = pointCloud.getPointCloud(currentPanoID.pano_id);

    idx = 3 * (Math.ceil(p.x) + 512 * Math.ceil(p.y));
    dx = pc.pointCloud[idx];
    dy = pc.pointCloud[idx + 1];
    r = dx * dx + dy * dy;
    minR = minDx * minDx + minDy + minDy;

    if (r < minR) {
      minDx = dx;
      minDy = dy;
    }
    delta = this.latlngOffset(currentPanoID.location[1], dx, dy);
    latlng = { lat: currentPanoID.location[1] + delta.dlat, lng: currentPanoID.location[0] + delta.dlng };
    return latlng;
  }

  calculateImageCoordinateFromPointPov(pov) {
    const SV_IMAGE_HEIGHT = 6656;
    const SV_IMAGE_WIDTH = 13312;

    var heading = pov.heading,
      pitch = pov.pitch,
      zoom = Math.max(1, pov.zoom);

    var imageX, imageY;
    var zoomFactor = ZOOMFACTOR[Math.floor(zoom)];

    var svImageWidth = SV_IMAGE_WIDTH * zoomFactor;
    var svImageHeight = SV_IMAGE_HEIGHT * zoomFactor;
    
    imageX = (svImageWidth * (heading / 360) + ((svImageWidth / 360) / 2)) / zoomFactor;
    imageY = ((svImageHeight / 2) * (pitch / 90)) / zoomFactor;

    return {
      x: imageX,
      y: imageY
    };
  }

  unproject(u, v){
    function sgn(x) {
      return x >= 0 ? 1 : -1;
    }
    
    let {GSVPOV, width, windowHeight} = this.props;
    var PI = Math.PI;
    var cos = Math.cos;
    var sin = Math.sin;
    var tan = Math.tan;
    var sqrt = Math.sqrt;
    var atan2 = Math.atan2;
    var asin = Math.asin;

    var fov = 180 / Math.pow(2, GSVPOV.zoom) * PI / 180.0;
    var height = windowHeight;

    var h0 = GSVPOV.heading * PI / 180.0;
    var p0 = GSVPOV.pitch * PI / 180.0;

    var f = 0.5 * width / tan(0.5 * fov);

    var x0 = f * cos(p0) * sin(h0);
    var y0 = f * cos(p0) * cos(h0);
    var z0 = f * sin(p0);
    // debugger;

    var du = u - width / 2;
    var dv = height / 2 - v;

    var ux = sgn(cos(p0)) * cos(h0);
    var uy = -sgn(cos(p0)) * sin(h0);
    var uz = 0;

    var vx = -sin(p0) * sin(h0);
    var vy = -sin(p0) * cos(h0);
    var vz = cos(p0);

    var x = x0 + du * ux + dv * vx;
    var y = y0 + du * uy + dv * vy;
    var z = z0 + du * uz + dv * vz;

    var R = sqrt(x * x + y * y + z * z);
    var h = atan2(x, y);
    var p = asin(z / R);

    return {
      heading: h * 180.0 / PI,
      pitch: p * 180.0 / PI
    };
  }


  

  handleMouseMove(e){
    let { identifyPlaceStep } = this.props;
    if (identifyPlaceStep === 1) {

      this.props.dispatch(changeActivePanoIDScreen(this.props.screenID));

    } 
    
    if (identifyPlaceStep > 0) {

      let pos = [e.clientX, e.clientY];
      let { windowWidth, screenID } = this.props;

      if (screenID === "right") {
        pos[0] = pos[0] - windowWidth * 0.5;
      }
      
      pos[1] = pos[1];

      this.setState({
        mouseLocation: pos
      });
    }
  }
  
  render() {
    let { now, currentPanoID, currentStreetview, width, identificationCategory, identifyPlaceStep } = this.props;
    let { mouseLocation, lastMouseLocation } = this.state;
    // console.log("panoidviewer render", this.props.GSVPOV);
    let pois = _.filter(currentStreetview.pois, poi => {
      // debugger

      let panoTimestamp = now ? new Date().getTime() / 1000 : moment.unix(currentPanoID.timestamp).startOf('month').unix();
      
      if (poi.disappeared_at && poi.first_showed_at) {
      
        let poiDisappearedTimestamp = moment(poi.disappeared_at).startOf('month').unix();
        let poiAppearedTimestamp = moment(poi.first_showed_at).startOf('month').unix();
        
        return panoTimestamp >= poiAppearedTimestamp && 
               panoTimestamp <= poiDisappearedTimestamp && 
               poi.is_public;  
      
      } else if (!poi.first_showed_at && poi.disappeared_at){
       
        let poiDisappearedTimestamp = moment(poi.disappeared_at).startOf('month').unix();
        return panoTimestamp <= poiDisappearedTimestamp && 
               poi.is_public;  

      } else if (poi.first_showed_at && !poi.disappeared_at){

        let poiAppearedTimestamp = moment(poi.first_showed_at).startOf('month').unix();
        
        return panoTimestamp >= poiAppearedTimestamp && 
               poi.is_public;  
      } else if (!poi.first_showed_at && !poi.disappeared_at){
        return poi.is_public;
      }
     
    });

    let indicatorLocation = mouseLocation;
    
    if (identifyPlaceStep > 1) { 
      indicatorLocation = lastMouseLocation;
    }

    // console.log(this.props.activePanoIDScreen, identifyPlaceStep);

    let prevShowCriteria = identifyPlaceStep > 0 && this.props.screenID === this.props.activePanoIDScreen;
    let prevShowWindowCriteria = identifyPlaceStep > 1 && this.props.screenID === this.props.activePanoIDScreen;
    let categoryCriteria = true;


    let showIndicator = prevShowCriteria;
    let showWindow = prevShowWindowCriteria;
    
    if (identificationCategory === 2) {
      categoryCriteria = this.props.screenID === "right";
    }
    
    showIndicator = prevShowCriteria && categoryCriteria;
    showWindow = prevShowWindowCriteria && categoryCriteria;
  




    return (
      <Container ref={this.containerRef} onClick={this.handlePosClick.bind(this)}>
{/* 
        {
          {/* _.map(currentStreetview.nearest_streetviews, nearestStreetview => {
            return (
              <NearestStreetview nearestStreetview={nearestStreetview} containerWidth={width} key={nearestStreetview.gid} panoid={currentPanoID} />
            )
          }) */}
        } */}

        {
          showIndicator ? 
          <PositionIndicator style={{ left: indicatorLocation[0], top: indicatorLocation[1] }}>
            { IDENTIFICATION_CATEGORY[identificationCategory].label } 
          </PositionIndicator> : null
        }
        {
          showWindow ?
          <IdentifyPlaceWindow currentPanoID={currentPanoID} /> : null
        }
        {
          now ? 
          <DateIndicator>
            Now
          </DateIndicator> :
          (currentPanoID ?
          <DateIndicator>
            { panoDateToRichDate(currentPanoID.date) }
          </DateIndicator> : null)
        }
        {
          _.map(currentPanoID.identifications, identification => {
            return (
              <Identification key={identification.id} identification={identification} containerWidth={width} panoid={currentPanoID} />
            )
          })
        }
        {
          _.map(pois, poi => {


            return (
              <PoiLabel poi={poi} containerWidth={width} key={poi.gid} panoid={currentPanoID} />
            )
          })
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    activePanoIDScreen: state.activePanoIDScreen,
    currentStreetview: state.currentStreetview,
    GSVPOV: state.GSVPOV,
    identificationCategory: state.identificationCategory,
    identifyPlaceStep: state.identifyPlaceStep,
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(PanoIDViewer);