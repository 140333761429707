import React, { Component } from 'react';
import { ContentLink, PoiStreetView, LabelArea, BlueButton, ListTitle as Title, ListButtonArea as ButtonArea, Gutter } from '../stylesheets/components';
import { convertTitle, panoDateToRichDate } from '../utils';
import { connect } from 'react-redux';
import { RED_COLOR, SHADOW_COLOR, STORY_CATEGORY } from '../constants/defaults';
import { changeCurrentSelectedMapEntity } from '../actions';
import styled from 'styled-components';

import moment from 'moment';
import { truncate } from '../utils';
const Fragment = React.Fragment;

const ChangesTitle = styled.div`
  font-size: 0.875em;
  font-family: "Source Sans Pro";
  color: #828282;
  line-height: 1.2;

  span.white {
    font-weight: 600;
  }
`;


const StoryTitle = styled.div`
  font-family: "Source Sans Pro";
  font-size: 1.2em;
  font-weight: 600;
  color: white;
`;

class StreetviewList extends Component {
  constructor(props){
    super(props);
    this.state = {
      clicked: false
    };
  }
  handleClick(e){
    
    let { streetview } = this.props;
    document.location.href=`/streetviews/${streetview.gid}`;
  }

  handleToggleList(e){
    e.preventDefault();
    if (!this.state.clicked) {
      this.props.dispatch(changeCurrentSelectedMapEntity(this.props.streetview));
    }
    this.setState({
      clicked: !this.state.clicked
    })
  }

  render() {
    let { streetview } = this.props;
    return (
      <ContentLink onClick={this.handleToggleList.bind(this)}>
        <PoiStreetView style={{ height: "auto" }}>
          {
            _.map(streetview.panoids, (panoid, i) => {
              return (
                <img key={panoid.pano_id + i} src={`https://maps.googleapis.com/maps/api/streetview?pano=${panoid}&size=100x100&heading=0&key=AIzaSyC2EvWU5FdBa4xgzA-mRodCJCdY_TP5z90`} alt="streetview images of the poi" />
              );
            })
          }
          
        </PoiStreetView>
        <LabelArea>
          <div className="left">
            <Title>
              {streetview.street}
            </Title>
            <p className="small">
              {convertTitle(streetview.city)}
            </p>
          </div>
          <div className="right">
            <p className="small">
              <span className="white">{streetview.total_change_count}</span> changes identified<br />
            </p>
          </div>

        </LabelArea>
        {
          this.state.clicked ?
          <ButtonArea>
            <BlueButton onClick={this.handleClick.bind(this)} selected={true}>
              Show {streetview.street}
            </BlueButton>
          </ButtonArea> : null
        }
      </ContentLink>
    );
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps)(StreetviewList);