import React, { Component, Fragment } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import {
  CustomButton,
  CustomButtonGroup,
  Content,
  IconWhiteButton,
  Header
} from './components';
import { Gutter, WindowsSubtitle, BlueButton, ScreenNameArea, BlueInput } from '../../stylesheets/components';
import { changeStoryStep, changeStoryCategory, changeCurrentStreetview, changeNoticeMessage, changeCSRFToken, changeProgress } from '../../actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as turf from '@turf/turf'
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { convertTitle, vincentyDirect } from '../../utils';
import { POI_CATEGORY } from '../../constants/defaults';


class StugglesSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      description: "",
      poi_name: "",
      poi_type: "",
      pickAlreadyIdentified: props.currentPoi ? true : false,
      formPristine: true,
      submitting: false
    }

    this.formData = {

    };
  }
  componentDidMount(){

    this.formData = {

    };

    this.setState({
      description: "",
      poi_name: "",
      poi_type: "",
      pickAlreadyIdentified: this.props.currentPoi ? true : false,
    })
  }

  handleClose(e) {

    this.props.dispatch(changeStoryStep(0));
    this.props.dispatch(changeStoryCategory(null));
  }

  handlePrev(e) {

    let { storyStep } = this.props;
    this.props.dispatch(changeStoryStep(Math.max(0, storyStep - 1)));

  }

  handlePoiClick(poi_id_data) {
    this.formData.poi_id = "";
    let { storyCategory } = this.props;
    if (poi_id_data === "none-of-them") {
      this.setState({
        pickAlreadyIdentified: false
      });
      this.props.dispatch(changeStoryStep(3));
      return false;

    } else {
      this.formData.poi_id = poi_id_data;
      this.setState({
        pickAlreadyIdentified: true,
        poi_type: "",
        poi_name: ""
      });

      this.props.dispatch(changeStoryStep(3)); 

    }
  }

  async handleSubmit(){
    let { currentPanoID, identifyPov, GSVPOV, identificationCategory } = this.props;


    if (!this.state.submitting) {

      const headers = {
        'X-CSRF-Token': this.props.csrfToken,
        'X-Requested-With': 'XMLHttpRequest'
      };

      this.setState({
        submitting: true
      });

      this.formData = {
        ...this.formData,
        description: this.state.description,
        poi_type: this.state.poi_type.value,
        poi_name: this.state.poi_name,
        poi_id: this.props.currentPoi ? this.props.currentPoi.gid : this.formData.poi_id
      };
      
      let formData = this.generateFormData();
      this.props.dispatch(changeProgress(true));

      const response = await fetch(`/stories.json`, { method: "post", body: formData, headers });

      if (response.ok) {
        let data = await response.json();

        this.props.dispatch(changeNoticeMessage("Successfully added!"));
        this.props.dispatch(changeStoryStep(0));
        this.props.dispatch(changeCurrentStreetview(data.streetview));
        this.setState({
          submitting: false
        });
        this.props.dispatch(changeProgress(false));
        // this.setState({
        //   howdidyouknowtheplace: "",
        //   poi_type: "",
        //   poi_name: ""
        // })
        // this.formData = {};

      } else {
        let data = await response.json();
        console.log(data);
        this.setState({
          submitting: false
        });
        this.props.dispatch(changeProgress(false));
        return data;

      }
    }   
  }

  generateFormData() {
    let formData = new FormData();
    let { isCurrentPanoNow, user_signed_in, current_user, currentPanoID, storyCategory, currentStreetview, identifyPov, pickedLatLng } = this.props;

    formData.append("streetview_id", currentStreetview.gid);
    formData.append("heading", identifyPov.heading);
    formData.append("pitch", identifyPov.pitch);
    formData.append("category_id", storyCategory);
    formData.append("poi_lat", pickedLatLng.lat);
    formData.append("poi_lng", pickedLatLng.lng);
    formData.append("timestamp", currentPanoID.timestamp);
    if (isCurrentPanoNow) {
      formData.append("timestamp", new Date().getTime() / 1000);
    } else {
      formData.append("timestamp", currentPanoID.timestamp);
    }

    if (user_signed_in) {
      formData.append("user_id", current_user.id);
    }

    _.each(this.formData, (value, key) => {
      formData.append(key, value);
    })

    return formData;
  }


  renderStepTwo() {
    let { currentStreetview } = this.props;

    let finalPois = [];

    _.each(currentStreetview.near_pois, near_poi => {
      finalPois.push(near_poi);
    });

    _.each(currentStreetview.pois, poi => {
      if (_.findIndex(finalPois, finalPoi => finalPoi.gid === poi.gid) === -1){
        finalPois.push(poi);
      }
    });

    return (
      <Content>
        {
          finalPois.length > 0 ?
          <Fragment>
            <WindowsSubtitle>
              Great! Thanks for the contribution. <br />
              Is <span className="blue">the place one of the following?</span>
            </WindowsSubtitle>
            <Gutter h={10} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              {
                _.map(finalPois, (near_poi, key) => {
                  return (
                    <CustomButton size="small" key={key} onClick={this.handlePoiClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.fclass ? near_poi.fclass : near_poi.category)})</CustomButton>
                  )
                })
              }
            </CustomButtonGroup>
            <Gutter h={15} />
            <WindowsSubtitle> 
              Or if none of the list are not matched, 
              Could you recognize <span className="blue">the name of the place</span> in the streetview?
            </WindowsSubtitle>
            <Gutter h={5} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              <CustomButton size="small" onClick={this.handlePoiClick.bind(this, "none-of-them")}>I can recognize the name of the place.</CustomButton>
            </CustomButtonGroup>
          </Fragment> :
          <Fragment>
            <WindowsSubtitle> 
              Great! Thanks for the contribution. <br />
              Could you recognize the name of <span className="blue">the place</span>?
            </WindowsSubtitle>
            <Gutter h={5} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              <CustomButton size="small" onClick={this.handlePoiClick.bind(this, "none-of-them")}>I can recognize the name of the place.</CustomButton>
            </CustomButtonGroup>
          </Fragment>
        }
        
      </Content>
    );
  }

  renderStepThree() {
    let { user_signed_in, current_user, mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview, currentPoi } = this.props;
    let options = POI_CATEGORY;

    let { poi_name, poi_type, description, pickAlreadyIdentified } = this.state;
     
    let selectedPoi;
    
    if (currentPoi) { 
      selectedPoi = currentPoi;
    } else {
      selectedPoi = _.find(currentStreetview.pois.concat(currentStreetview.near_pois), poi => poi.gid === this.formData.poi_id);
    } 

    return (
      <Content>
        {
          !pickAlreadyIdentified ? 
          <Fragment>
            <WindowsSubtitle>
              Could you add <b>the name of the place?</b>
            </WindowsSubtitle>

            {
              user_signed_in ? 
              <ScreenNameArea>
                Contributed by<br/>
                <b>{ current_user.screen_name }</b>
              </ScreenNameArea> : null
            }

            <TextField
              variant="outlined"
              value={poi_name} onChange={(event) => {
              const { value } = event.target;
              this.setState({
                poi_name: value,
                formPristine: false
              })
            }} label="Name" fullWidth />
            <Gutter h={10} />
            <Autocomplete
              id="combo-box-demo"
              options={options}
              onChange={(event, value) => {
                this.setState({
                  poi_type: value,
                  formPristine: false
                });
              }}
                value={poi_type}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
              )}
            />

            <Gutter h={10} />
          </Fragment> : null
        }
         {
          selectedPoi ?
          <Fragment>
            <WindowsSubtitle>
              You selected {selectedPoi.name} ({convertTitle(selectedPoi.fclass)}{selectedPoi.history ? `, ${selectedPoi.history})` : ")"}.
              
            </WindowsSubtitle> 
            {
              user_signed_in ? 
              <ScreenNameArea>
                Contributed by<br/>
                <b>{ current_user.screen_name }</b>
              </ScreenNameArea> : null
            }
          </Fragment>: null
        }
       
        <TextField
          value={description}
          variant="outlined"
          multiline rows={5}
          onChange={(event) => {
            const { value } = event.target;
            this.setState({
              description: value,
              formPristine: false
            })
          }}
          label="Story about struggles"
          fullWidth />

        <Gutter h={20} style={{
          borderBottom: "1px dotted #ccc",
          marginBottom: 20
        }} />

        <BlueInput type="submit" value="Submit" selected={true} disabled={this.state.submitting || this.state.formPristine || !this.formValidate()} onClick={this.handleSubmit.bind(this)} />

        <Gutter h={20} />
      </Content>
    );
  }

  formValidate() {
    let { currentStreetview, currentPoi } = this.props;
    let { poi_name, poi_type, description } = this.state;
    let selectedPoi;
    
    if (currentPoi) { 
      selectedPoi = currentPoi;
    } else {
      selectedPoi = _.find(currentStreetview.pois.concat(currentStreetview.near_pois), poi => poi.gid === this.formData.poi_id);
    } 

    if (!selectedPoi) {
      // validate all

      if (poi_name.length === 0) {
        return false;
      }

      if (_.isNull(poi_type) || _.isUndefined(poi_type)) {
        return false;
      }
    }

    // finally validate description

    if (description.length === 0) {
      return false;
    }

    return true;
  }

  render() {
    let { storyStep, currentPoi } = this.props;
    
    return (
      <div>
        <Header>
          <div className="left-wrap">

            {
              !currentPoi && storyStep === 3 ?
                <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handlePrev.bind(this)}>
                  <ChevronLeftIcon fontSize="small" />
                </IconWhiteButton> : null
            }
            <div className="title">
              Share a struggle
            </div>
          </div>

          <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handleClose.bind(this)}>
            <CloseIcon fontSize="small" />
          </IconWhiteButton>
        </Header>
        {
          storyStep === 2 ?
          this.renderStepTwo() : null
        }

        {
          storyStep === 3 ?
          this.renderStepThree() : null
        }
      </div>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    storyCategory: state.storyCategory,
    storyStep: state.storyStep,
    identifyPov: state.identifyPov,
    GSVPOV: state.GSVPOV,
    pickedLatLng: state.pickedLatLng,
    csrfToken: state.csrfToken,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    isCurrentPanoNow: state.isCurrentPanoNow,
    currentPoi: state.currentPoi
  }
}

export default connect(mapStateToProps)(StugglesSection);