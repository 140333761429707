import React, { Component } from 'react'
import styled from 'styled-components';
import { RED_COLOR, SHADOW_COLOR } from '../constants/defaults';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';


const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: white;
  padding: 20px;
  border:1px solid ${RED_COLOR};
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: black;
  border-radius: 20px;
  box-shadow: 2px 2px 0px ${ RED_COLOR };
  
`;

const ColorCircularProgress = withStyles({
  root: {
    color: RED_COLOR,
  },
})(CircularProgress);


const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: RED_COLOR,
  },
  barColorPrimary: {
    backgroundColor: '#888888',
  },
})(LinearProgress);

export default class LoadingBar extends Component {
  render() {
    return (
      <Container>
        <ColorCircularProgress />
      </Container>
    )
  }
}
