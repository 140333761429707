import _ from 'lodash';
import moment from 'moment';
import { SHARE_LIMIT } from '../constants/defaults';

export const calculateShareLimit = (supports) => {
  let finalLimit;
  let supportsLength = supports.length;

  for (let i = 0; i < SHARE_LIMIT.length; i++) {
    let limit = SHARE_LIMIT[i];

    if (supportsLength < limit) {
      return limit;
    }
  }

  return SHARE_LIMIT[SHARE_LIMIT.length - 1];

}

export const panoDateToRichDate = (panoDate) => {
  return moment(panoDate, "YYYY-MM").format("MMMM YYYY");
}

export const truncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};


export const findNearestPanoID = (currentPoi, streetview) => {
  console.log(currentPoi, streetview);
  if (streetview.panoids.length === 1) {
    return 0;
  }
  if (currentPoi.first_showed_at) {
    
    let firstShowedTimestamp = moment(currentPoi.first_showed_at).unix();
    let minDist = 9999999999999;
    let minIndex = 0;

    _.each(streetview.panoids, (panoid, i) => {
      let dist = firstShowedTimestamp - panoid.timestamp;
      if (Math.abs(dist) < minDist && dist <= 0) {
        minDist = dist;
        minIndex = i;
      }
    })

    let nowTimestamp = new Date().getTime() / 1000;
    let dist = firstShowedTimestamp - nowTimestamp;
    if (Math.abs(dist) < minDist && dist <= 0) {
      minDist = dist;
      minIndex = streetview.panoids.length;
    }
    return minIndex;
  
  } else if (currentPoi.disappeared_at) {

    let disappearedTimestamp = moment(currentPoi.disappeared_at).unix(); 

    let minDist = 999999999999999;
    let minIndex = null;

    _.each(streetview.panoids, (panoid, i) => {
      let dist = disappearedTimestamp - panoid.timestamp;
      if (Math.abs(dist) < minDist && dist >= 0) {
        minDist = dist;
        minIndex = i;
      }
    })

    let nowTimestamp = new Date().getTime() / 1000;
    let dist = disappearedTimestamp - nowTimestamp;
    if (Math.abs(dist) < minDist && dist >= 0) {
      minDist = dist;
      minIndex = streetview.panoids.length;
    }
    return minIndex;

  } else if (!currentPoi.disappeared_at && !currentPoi.first_showed_at) {
    return 0;
  }
  
}


export const toRadians = (angle) => {
  return angle * (Math.PI / 180);
}

export const randomBetween = (min, max) => {
  return Math.random() * (max - min) + min;
}

export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export const numberWithDelimiter = (number, delimiter, separator) => {
  try {
    var delimiter = delimiter || ",";
    var separator = separator || ".";
    
    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
    return parts.join(separator);
  } catch(e) {
    return number
  }
};

export const convertTitle = (str) => {
  try {
    return toTitleCase(str.replace(/_/g, " "));
  } catch (e) {
    return "";
  }
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function toRad(degree) {
  return degree * Math.PI / 180;
};

function toDeg(radian) {
  return radian * 180 / Math.PI;
};
