import React, { Component, Fragment } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import {
  CustomButton,
  CustomButtonGroup,
  Content,
  IconWhiteButton,
  Header
} from './components';
import { Gutter } from '../../stylesheets/components';
import { changeStoryStep, changeStoryCategory, changeCurrentStreetview, changeProgress, changeNoticeMessage } from '../../actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as turf from '@turf/turf'
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { convertTitle } from '../../utils';
import { POI_CATEGORY } from '../../constants/defaults';


class IhadmemorySection extends Component {
  constructor(props){
    super(props);
    this.state = {
      description: "",
      poi_name: "",
      poi_type: "",
      pickAlreadyIdentified: false
    }

    this.formData = {

    };
  }
  componentDidMount(){

    this.formData = {

    };

    this.setState({
      description: "",
      poi_type: "",
      poi_name: ""
    })
  }

  handleClose(e) {

    this.props.dispatch(changeStoryStep(0));
    this.props.dispatch(changeStoryCategory(null));
  }

  handlePrev(e) {

    let { storyStep } = this.props;
    this.props.dispatch(changeStoryStep(Math.max(0, storyStep - 1)));

  }

  handlePoiClick(poi_id_data) {
    this.formData.poi_id = "";
    let { storyCategory } = this.props;
    if (poi_id_data === "none-of-them") {
      this.setState({
        pickAlreadyIdentified: false
      });
      this.props.dispatch(changeStoryStep(3));
      return false;

    } else {
      this.formData.poi_id = poi_id_data;
      this.setState({
        pickAlreadyIdentified: true
      });
      this.props.dispatch(changeStoryStep(3)); 

    }
  }

  async handleSubmit(){
    let { currentPanoID, identifyPov, GSVPOV, identificationCategory } = this.props;


    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };


    this.formData = {
      ...this.formData,
      description: this.state.description,
      poi_type: this.state.poi_type.value,
      poi_name: this.state.poi_name
    };
    
    let formData = this.generateFormData();

    this.props.dispatch(changeProgress(true));
    const response = await fetch(`/stories.json`, { method: "post", body: formData, headers });

    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeProgress(false));
      this.props.dispatch(changeNoticeMessage("Successfully added!"));
      this.props.dispatch(changeStoryStep(0));
      this.props.dispatch(changeCurrentStreetview(data.streetview));

      // this.setState({
      //   howdidyouknowtheplace: "",
      //   poi_type: "",
      //   poi_name: ""
      // })
      // this.formData = {};

    } else {
      let data = await response.json();
      console.log(data);

      return data;

    }
  }

  generateFormData() {
    let formData = new FormData();
    let { currentPanoID, storyCategory, currentStreetview, identifyPov, pickedLatLng } = this.props;

    formData.append("streetview_id", currentStreetview.gid);
    formData.append("heading", identifyPov.heading);
    formData.append("pitch", identifyPov.pitch);
    formData.append("category_id", storyCategory);
    formData.append("panoid_id", currentPanoID.id);
    formData.append("poi_lat", pickedLatLng.lat);
    formData.append("poi_lng", pickedLatLng.lng);
    formData.append("timestamp", currentPanoID.timestamp);

    _.each(this.formData, (value, key) => {
      formData.append(key, value);
    })

    return formData;
  }


  renderStepTwo() {
    let { currentStreetview } = this.props;
    console.log(currentStreetview.pois.concat(currentStreetview.near_pois));
    return (
      <Content>
        <p className="desc">
          Great! Thanks for the contribution. <br />
          Is the place one of the following?
          </p>
        <Gutter h={10} />
        <CustomButtonGroup
          orientation="vertical"
          color="primary"
          variant="contained"
          aria-label="vertical outlined primary button group"
        >
          {
            _.map(currentStreetview.pois.concat(currentStreetview.near_pois), (near_poi, key) => {
              return (
                <CustomButton size="small" key={key} onClick={this.handlePoiClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.fclass)})</CustomButton>
              )
            })
          }
        </CustomButtonGroup>
        <Gutter h={15} />
        <CustomButtonGroup
          orientation="vertical"
          color="primary"
          variant="contained"
          aria-label="vertical outlined primary button group"
        >

          <CustomButton size="small" onClick={this.handlePoiClick.bind(this, "none-of-them")}>None of them are the place</CustomButton>
        </CustomButtonGroup>
      </Content>
    );
  }

  renderStepThree() {
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;
    let { poi_name, poi_type, howdidyouknowtheplace, pickAlreadyIdentified } = this.state;
    let options = POI_CATEGORY;
    
    
    let selectedPoi = _.find(currentStreetview.pois.concat(currentStreetview.near_pois), poi => poi.gid === this.formData.poi_id);
    
    return (
      <Content>
        {
          !pickAlreadyIdentified ? 
          <Fragment>
            <p className="desc">
              Could you add <b>the name of the place?</b>
            </p>

            <TextField value={poi_name} onChange={(event) => {
              const { value } = event.target;
              this.setState({
                poi_name: value
              })
            }} label="Name" fullWidth />
            <Gutter h={10} />
            <Autocomplete
              id="combo-box-demo"
              options={options}
              onChange={(event, value) => {
                this.setState({
                  poi_type: value
                });
              }}
                value={poi_type}
              getOptionLabel={option => option.label}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
              )}
            />

            <Gutter h={10} />
          </Fragment> : null
        }
        {
          selectedPoi ?
          <div>
            { selectedPoi.name} <br/>
            { convertTitle(selectedPoi.fclass) } { selectedPoi.history ? `, ${selectedPoi.history}` : "" }
          </div> : null
        }
        <TextField value={howdidyouknowtheplace} 
          multiline rows={4} onChange={(event) => {
          const { value } = event.target;
          this.setState({
            description: value
          })
        }} label="What memory was that?" fullWidth />

        <Gutter h={20} />
        <Button variant="contained" onClick={this.handleSubmit.bind(this)}>Submit!</Button>
        <Gutter h={30} />
      </Content>
    );
  }
  render() {
    let { storyStep } = this.props;
    
    return (
      <div>
        <Header>
          <div className="left-wrap">

            {
              storyStep === 3 ?
                <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handlePrev.bind(this)}>
                  <ChevronLeftIcon fontSize="small" />
                </IconWhiteButton> : null
            }
            <div className="title">
              What is the place?
            </div>
          </div>

          <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handleClose.bind(this)}>
            <CloseIcon fontSize="small" />
          </IconWhiteButton>
        </Header>
        {
          storyStep === 2 ?
          this.renderStepTwo() : null
        }

        {
          storyStep === 3 ?
          this.renderStepThree() : null
        }
      </div>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    storyCategory: state.storyCategory,
    storyStep: state.storyStep,
    identifyPov: state.identifyPov,
    GSVPOV: state.GSVPOV,
    pickedLatLng: state.pickedLatLng,
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(IhadmemorySection);