import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { withRouter } from 'react-router';
import { SmallTitle } from '../stylesheets/components';
import { changeNeighborhoodBoundaries, changeProgress } from '../actions';
import { RED_COLOR } from '../constants/defaults';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectContainer = styled.div`
  margin: 0 0 20px 0;
`;

// const CustomSelect = withStyles({
//   root: {
//     width: "100%",
//     background: "transparent",
    
//     borderBottom: "1px solid #333",
//     '&label.Mui-focused': {
//       color: RED_COLOR
//     },
//     '&.MuiInput-underline': {
//       borderBottomColor: '#555',
//     },
//     '&.MuiInput-underline:after': {
//       borderBottomColor: RED_COLOR
//     }
//   },
//   select: {
//     fontFamily: "Heldane Display",
//     fontSize: "1.875em",
//     lineHeight: 1.1,
//     color: "#7d7d7d"
//   },
//   icon: {
//     color: "#888"
//   }
// })(Select);



const CustomTextField = withStyles({
  root: {
    width: "100%",
    color: "white",
    borderBottom: "1px solid #333",
    '& .MuiInput-formControl': {
      margin: 0
    },  
    '& label.Mui-focused': {
      color: RED_COLOR,
      display: "none"
    },
    '& .MuiInput-underline': {
      borderBottomColor: '#555',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: RED_COLOR
    }
   
  },
  input: {
    color: "white"
  }
})(TextField);


class NeighborhoodSelector extends Component {
  constructor(props){
    super(props);

    this.state = {
      neighborhoodSearchValue: ""
    };
  }
  // handleNeighborhoodSelectChange(e) {
  //   this.props.history.push(`/neighborhoods/${e.target.value}`);
  // }

  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));
    const response = await fetch("/neighborhood_boundaries.json", { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      
      this.props.dispatch(changeNeighborhoodBoundaries(data.neighborhood_boundaries));
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      // return data;

    }
  }

  render() {

    let { neighborhoodBoundaries, currentNeighborhood, welcomeCurrentTime, mapZoom } = this.props;
    let { neighborhoodSearchValue } = this.state;
    return (
      <SelectContainer>
        <SmallTitle>
          <span className="white">Find your neighborhood:</span>
        </SmallTitle>

        <Autocomplete
          id="neighborhood_search_inputt"
          options={neighborhoodBoundaries}
          groupBy={option => option.boro_name}
          onChange={(event, value) => {
            if (value) {
              this.props.history.push(`/neighborhoods/${value.permalink}`);
            }
          }}
          value={neighborhoodSearchValue}
          getOptionLabel={option => option.ntaname}
          renderInput={params => {
            return (
              <CustomTextField label="" {...params} InputProps={{ ...params.InputProps, style: { 
                color: "white", 
                fontFamily: "Heldane Display",
                fontSize: "1.875em",
                lineHeight: 1.1
              } }} fullWidth />
            );
          }}
        />
      </SelectContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    neighborhoodBoundaries: state.neighborhoodBoundaries,
    currentNeighborhood: state.currentNeighborhood,
    welcomeCurrentTime: state.welcomeCurrentTime,
    mapZoom: state.mapZoom
  }
}

export default withRouter(connect(mapStateToProps)(NeighborhoodSelector));