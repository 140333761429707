import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { STORY_CATEGORY } from '../constants/defaults';
import { BlueButton } from "../stylesheets/components";
import { changeStoryStep, changeStoryCategory, changeNoticeMessage } from '../actions';
import { IDENTIFICATION_CATEGORY, SHADOW_COLOR, RED_COLOR } from '../constants/defaults';

const Container = styled.div`
  position: fixed;
  padding: 15px;
  z-index: 64;
  left: ${props => props.mode === "full" ? "50%" : "calc(50% + 15px)"};
  transform: ${props => props.mode === "full" ? "translate(-50%, 0)" : "translate(0, 0)"}; 
  top: ${props => props.mode === "full" ? "126px" : "100px"};
  background: #000000;
  border: 1px solid ${SHADOW_COLOR};
  box-sizing: border-box;
  box-shadow: 2px 2px 0px ${RED_COLOR};
  border-radius: 10px;
  div.wrap {

    display: flex;
    align-items: center;
  }
`;

const ButtonArea = styled.div`
  margin-bottom: 5px;
  text-align: right;
`;
const Title = styled.div`
  font-family: "Source Sans Pro";
  color: white;
  text-align: center;
  font-weight: 600;
  font-size:0.9em;
  line-height:1.1em;
  margin-bottom: 10px;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.11);
`;

const BlockBlueButton = styled(BlueButton)`
  width: 120px;
  margin-right: 0;
  font-size:0.8em;
  margin-bottom: 10px;
  display: block;
  &:last-child {
    margin: 0;
  }
`;
class StoryButtonContainer extends Component {

  handleClick(storyCategory){

    this.props.dispatch(changeStoryCategory(Number(storyCategory)));
    this.props.dispatch(changeNoticeMessage(`Point out where to put your ${STORY_CATEGORY[Number(storyCategory)].listLabel.toLowerCase() }; it should be on the right street view.`))
    this.props.dispatch(changeStoryStep(1));
  }

  handleCancelClick(e) {
    this.props.dispatch(changeStoryStep(0));
    this.props.dispatch(changeStoryCategory(null));
  }

  render() {
    let { storyStep, mode } = this.props;
    return (
      <Container mode={mode}>
        {
          storyStep === 0 ? 
          <Fragment>
            <div>
              {
                _.map(STORY_CATEGORY, (category, key) => {
                  return (
                    <BlockBlueButton key={key} selected={true} onClick={this.handleClick.bind(this, key)}>{category.label}</BlockBlueButton>
                  )
                })
              }
              
              
            </div>
            
          </Fragment> :
          <Fragment>
            <BlueButton selected={true} onClick={this.handleCancelClick.bind(this, 1)}>Cancel</BlueButton>
          </Fragment>
        }
      </Container>
    )
  }
}


let mapStateToProps = state => {
  return {
    storyStep: state.storyStep,
    storyCategory: state.storyCategory
  }
}

export default connect(mapStateToProps)(StoryButtonContainer);