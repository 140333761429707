import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SmallTitle, Gutter } from '../stylesheets/components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import { changeNeighborhoodSearchResults, changeProgress } from '../actions';
import _ from 'lodash';
import { RED_COLOR } from '../constants/defaults';

const SearchContainer = styled.div`
  margin: 20px 0 35px 0;
  width: 100%;
`;


const CustomTextField = withStyles({
  root: {
    width: "100%",
    borderBottom: "1px solid #333",
    '& label.Mui-focused': {
      color: RED_COLOR
    },
    '& .MuiInput-underline': {
      borderBottomColor: '#555',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: RED_COLOR
    }
   
  },
})(TextField);

class NeighborhoodSearch extends Component {
  constructor(props){
    super(props);
    this.handleSearch = _.debounce(e => {
      
      this.props.togglePristine(false);
      if (e.target.value.length > 2) {
        this.sendSearchQuery(e.target.value);
      }
      if (e.target.value.length === 0) {
        this.props.togglePristine(true);
        this.props.dispatch(changeNeighborhoodSearchResults([]));
      }
    }, 1000)
  }

  async sendSearchQuery(query) {
    let { currentNeighborhood } = this.props;
    this.props.dispatch(changeProgress(true));
    const response = await fetch(`/neighborhood_boundaries/${currentNeighborhood.gid}/search?query=${query}`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      console.log(data.results);
      this.props.dispatch(changeNeighborhoodSearchResults(data.results));
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));

      return data;

    }

  }



  render() {
    return (
      <SearchContainer>
        <SmallTitle>
          Enter <span className="blue">a store</span> or <span className="blue">a street</span> that you remember.
        </SmallTitle>
        <Gutter h={4} />
        
        <CustomTextField
          onChange={e => {
            e.persist();
            this.handleSearch(e);
          }}
          id="search"
          color="primary"
          placeholder="e.g., punjabi deli, canal st"
          InputProps={{
            style: {
              fontFamily: "Heldane Display",
              fontSize: "1.875em",
              lineHeight: 1.1,
              color: "white"
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "white" }} />
              </InputAdornment>
            ),
          }}
        />

      </SearchContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    csrfToken: state.csrfToken,
    currentNeighborhood: state.currentNeighborhood
  };
}

export default connect(mapStateToProps)(NeighborhoodSearch);