import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeProgress, changeMapZoom } from '../actions';
import _ from 'lodash';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as turf from '@turf/turf';
import { API_URL, RED_COLOR } from '../constants/defaults';
import { withStyles } from '@material-ui/core/styles';
import chroma from 'chroma-js';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const MapDiv = styled.div`
  position: fixed;
  width: ${ props => props.isFullScreen ? "100vw" : "50vw"};  
  height: 100vh;
  left: ${ props => props.isFullScreen ? 0 : "50vw"};  
  top: 0;

  ${media.mobileSmall`
    width: 100vw;
    height:${ props => props.isFullScreen ? "100%" : "50%"};  
    left:0;
    top: ${ props => props.isFullScreen ? 0 : "50%"};  
  `}
    
`;


class MapContainer extends Component {
  constructor(props){
    super(props);

    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
  }
  componentDidMount() {
    window.mapboxgl.accessToken = 'pk.eyJ1Ijoic2Vuc2VhYmxlIiwiYSI6ImxSNC1wc28ifQ.hst-boAjFCngpjzrbXrShw';

    this.map = new window.mapboxgl.Map({
      container: this.refsMapContainer,
      style: 'mapbox://styles/senseable/ck7hsagqw1q591iphzbto284y',
      zoom: 13,
      minZoom: 13,
      bearing: 29.599999999999685,
      center: [ -74.00227719790553, 40.72330068263852],
      // interactive: false
    });


    window.map = this.map;
    this.map.on('style.load', this.handleStyleLoad.bind(this));
    this.map.on('zoom', this.handleZoom.bind(this));
  
  }

  handleZoom(e){
    this.props.dispatch(changeMapZoom(this.map.getZoom()));
  }

  async handleStyleLoad(e) {

    let { welcomeCurrentTime } = this.props;

    this.map.addSource('neighborhood-boundaries', {
      "type": 'vector',
      // "tiles": ['http://localhost:5001/public.streetviews/{z}/{x}/{y}.pbf'],
      "tiles": [`${API_URL}/tiles/neighborhood_boundaries/{z}/{x}/{y}.pbf`],
      "minZoom": 14
    });

    this.map.addSource('pois', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/pois/{z}/{x}/{y}.pbf`],
    });


    this.map.addSource('removed_pois', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/removed_pois/{z}/{x}/{y}.pbf`]
    });

    this.map.addSource('streetviews', {
      "type": 'vector',
      // "tiles": ['http://localhost:5001/public.streetviews/{z}/{x}/{y}.pbf'],
      "tiles": [`${API_URL}/tiles/full_streetviews/{z}/{x}/{y}.pbf`],
      "minZoom": 15
    });


    this.map.addLayer({
      'id': 'neighborhood-boundaries-line-layer',
      'type': 'line',
      'source': 'neighborhood-boundaries',
      'source-layer': 'public.neighborhood_boundaries',
      'layout': {},
      'paint': {
        'line-color': '#222222',
        'line-opacity': 0.4
      }
    });


    this.map.addLayer({
      'id': 'neighborhood-boundaries-text-layer',
      'type': 'symbol',
      'source': 'neighborhood-boundaries',
      'source-layer': 'public.neighborhood_boundaries',
      'layout': {
        "text-size": 12,
        "text-field": [
          'format',
          ['get', 'ntaname'],
          {
            'font-scale': 1.0,
            'text-font': ["literal", ["Source Sans Pro SemiBold"]]
          },
          '\n',
          {},
          ['get', 'total_changes'],
          {
            'font-scale': 0.7,
            'text-font': ["literal", ["Source Sans Pro SemiBold"]]
          }
        ],
      },
      'paint': {
        // "text-halo-width": 1,
        // "text-halo-color": "rgba(80, 80, 80, 0.4)",
        "text-color": "#AAAAAA",
        // 'text-color': [
        //   'interpolate',
        //   ['linear'],
        //   ['get', 'total_changes'],
        //   0,
        //   chroma("#111111").darken(2).hex(),
        //   1,
        //   chroma("#1e1e1e").darken(2).hex(),
        //   10,
        //   chroma("#434721").darken(2).hex(),
        //   20,
        //   chroma("#726028").darken(2).hex(),
        //   30,
        //   chroma("#9b7031").darken(2).hex(),
        //   40,
        //   chroma("#c3793a").darken().hex(),
        //   50,
        //   chroma("#ff6044").darken().hex()
        // ],
        "text-opacity": 1
      }
    });

    this.map.addLayer({
      'id': 'pois-heat',
      'type': 'heatmap',
      'source': 'removed_pois',
      'source-layer': 'public.pois',
      'paint': {
          'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'rgb(103,169,207)',
              0.4,
              'rgb(209,229,240)',
              0.6,
              'rgb(253,219,199)',
              0.8,
              'rgb(239,138,98)',
              1,
              'rgb(178,24,43)'
          ],
          // Adjust the heatmap radius by zoom level
          'heatmap-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              2,
              16,
              20
          ],
          // Transition from heatmap to circle layer by zoom level
          'heatmap-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              14,
              0.6,
              15,
              0
          ]
      }
    });

    this.map.addLayer({
      'id': 'streetviews-layer',
      'source': 'streetviews',
      'source-layer': 'public.full_streetviews',
      'type': 'circle',
      'paint': {
        'circle-color': [
          'interpolate',
          ['linear'],
          ['get', 'total_change_count'],
          0,
          "rgba(60, 60, 60, 0.4)",
          1,
          "#1e1e1e",
          2,
          "#434721",
          3,
          "#726028",
          4,
          "#9b7031",
          6,
          "#c3793a",
          8,
          "#ff6044"
        ],
        'circle-radius': {
          stops: [[14, 2], [15, 4], [22, 15]]
        },
        'circle-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          0,
          14,
          0.2,
          15,
          1
        ]
      }
    }, "admin-0-boundary-disputed");


    this.map.addLayer({
      'id': 'neighborhood-boundaries-layer',
      'type': 'fill',
      'source': 'neighborhood-boundaries',
      'source-layer': 'public.neighborhood_boundaries',
      'layout': {},
      'paint': {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'total_changes'],
          0,
          "#111111",
          2,
          "#1e1e1e",
          4,
          "#434721",
          6,
          "#726028",
          19,
          "#9b7031",
          24,
          "#c3793a",
          45,
          "#ff6044"
        ],
        'fill-opacity': {
          stops: [[13, 0.2], [15, 0]]
        }
      }
    }, 'streetviews-layer');


    this.map.addLayer({
      'id': 'pois-layer-text',
      'source': 'pois',
      'source-layer': 'public.pois',
      'type': 'symbol',
      "minzoom": 15,
      'layout': {
        'icon-allow-overlap': true,
        'icon-image': [
          'coalesce',
          // ['image', ['concat', ['get', 'fclass'], '-15']],
          ['image', "marker-15"]
        ],
        'icon-size': 0.8,
        "text-offset": [0, -2],
        "text-field": [
          'format',
          ['get', 'name'],
          {
            'font-scale': 1.0,
            'text-font': ["literal", ["Source Sans Pro SemiBold"]]
          },
          '\n',
          {},
          ['get', 'history'],
          {
            'font-scale': 0.8,
            'text-font': ["literal", ["Source Sans Pro Regular"]]
          }
        ],
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          14,
          6,
          16,
          12
        ]
      },
      'paint': {
        'icon-halo-width': 0,
        'icon-color': [
          'match',
          ["to-string", ['get', 'is_public']],
          "true",
          "#AAAAAA",
          "false",
          "#AAAAAA",
          "#AAAAAA"
        ],
        "icon-opacity": [
          'interpolate',
          ['linear'],
          ['zoom'],
          14,
          0,
          16,
          [
            'match',
            ["to-string", ['get', 'is_public']],
            "true",
            1,
            "false",
            0.1,
            0
          ]
        ],
        'text-color': [
          "case",
          ["has", "crowd_disappeared_at"],
          RED_COLOR,
          "#AAAAAA"
        ],
        "icon-opacity": [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          16,
          [
            "case",
            ["has", "crowd_disappeared_at"],
            0.7,
            1
          ]
        ],
        "text-opacity": [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          16,
          [
            "case",
            ["has", "crowd_disappeared_at"],
            0.7,
            1
          ]
        ]
      }
    });


    this.map.addLayer({
      'id': 'streetviews-layer-hover',
      'source': 'streetviews',
      'source-layer': 'public.full_streetviews',
      'type': 'circle',
      'filter': ["==", ["get", "gid"], ""],
      'paint': {
        'circle-color': "#FF0000",
        'circle-opacity': 0.8,
        'circle-radius': {
          stops: [[14, 3], [15, 5], [22, 15]]
        }
      }
    });

    this.map.addLayer({
      'id': 'neighborhood-boundaries-layer-hover',
      'type': 'line',
      'source': 'neighborhood-boundaries',
      'source-layer': 'public.neighborhood_boundaries',
      'filter': ["==", ["get", "gid"], ""],
      'layout': {},
      'paint': {
        'line-color': RED_COLOR,
        'line-width': 1,
        'line-opacity': 0.6
      }
    });

    this.map.addLayer({
      'id': 'neighborhood-boundaries-layer-clicked',
      'type': 'line',
      'source': 'neighborhood-boundaries',
      'source-layer': 'public.neighborhood_boundaries',
      'filter': ["==", ["get", "gid"], ""],
      'layout': {},
      'paint': {
        'line-color': RED_COLOR,
        'line-width': 2,
        'line-opacity': 0.6
      }
    });




    this.map.on("mousemove", "streetviews-layer", this.handleMouseMove);
    this.map.on("mouseleave", "streetviews-layer", this.handleMouseLeave);
    
    this.map.on("click", "pois-layer-text", this.handlePoiClick.bind(this))
    

    this.map.on("mousemove", "neighborhood-boundaries-layer", this.handleBoundariesMouseMove.bind(this));
    this.map.on("mouseleave", "neighborhood-boundaries-layer", this.handleBoundariesMouseLeave.bind(this));

    this.map.on("click", this.handleClick);
    this.loadJenks();
  }

  async loadJenks(){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // this.props.dispatch(changeProgress(true));
    const response = await fetch("/neighborhoods/jenks.json", { method: "get", headers });
    if (response.ok) {

      let data = await response.json();
      // this.props.dispatch(changeProgress(false));
      this.updateJenks(data.neighborhood_boundary_jenks, data.streetview_jenks);

    } else {
      let data = await response.json();
      // this.props.dispatch(changeProgress(false));

      return data;

    }

  }

  updateJenks(neighborhood_boundary_jenks, streetview_jenks){

    let streetviewColorsBlocks = [
      'interpolate',
      ['linear'],
      ['get', 'total_change_count']
    ];

    let streetviewColors = [ 
      "rgba(60, 60, 60, 0.4)",
      "#1e1e1e",
      "#434721",
      "#726028",
      "#9b7031",
      "#c3793a",
      "#ff6044"
    ];


    _.each(streetview_jenks, (j, i) => {
      streetviewColorsBlocks.push(j);
      streetviewColorsBlocks.push(streetviewColors[i]);
    });

    this.map.setPaintProperty('streetviews-layer', 'circle-color', streetviewColorsBlocks);
    


    let neighborhoodsColorsBlocks = [
      'interpolate',
      ['linear'],
      ['get', 'total_changes']
    ];

    let neighborhoodsColors = [ 
      "#111111",
      "#1e1e1e",
      "#434721",
      "#726028",
      "#9b7031",
      "#c3793a",
      "#ff6044"
    ];


    _.each(neighborhood_boundary_jenks, (j, i) => {
      neighborhoodsColorsBlocks.push(j);
      neighborhoodsColorsBlocks.push(neighborhoodsColors[i]);
    });

    this.map.setPaintProperty('neighborhood-boundaries-layer', 'fill-color', neighborhoodsColorsBlocks);
    
  }

  handleBoundariesMouseMove(e){
    if (e.features.length > 0) {
      let feature = e.features[0];
      this.map.setFilter('neighborhood-boundaries-layer-hover', ["==", ["get", "gid"], feature.properties.gid]);
      // console.log(feature.properties.gid, e.features.length);
    } else {
      this.map.setFilter('neighborhood-boundaries-layer-hover', ["==", ["get", "gid"], ""]);
    }

    this.map.getCanvas().style.cursor = e.features.length > 0 ? 'pointer' : 'auto';

  }

  handleBoundariesMouseLeave(e) {
    this.map.setFilter('neighborhood-boundaries-layer-hover', ["==", ["get", "gid"], ""]);
    this.map.getCanvas().style.cursor = "auto";
  }


  handleClick(e) {

    var features = this.map.queryRenderedFeatures(e.point);

    if (features.length > 0) {
      let feature = features[0];


      if (this.map.getZoom() > 15) {
        if (feature.source === "streetviews") {

          document.location.href = `/streetviews/${feature.properties.gid}`;
          // this.props.history.push(`/streetviews/${feature.properties.gid}`);
  
        } else if (feature.source === "pois") {
  
          document.location.href = `/pois/${feature.properties.gid}`;
          // this.props.history.push(`/pois/${feature.properties.gid}`);
          
        }
      } else {

        if (feature.source === "neighborhood-boundaries") {

          this.props.history.push(`/neighborhoods/${feature.properties.permalink}`);
  
        } 
        
      }
      


    } else {



    }

  }


  componentDidUpdate(prevProps){
    
    if (this.props.currentNeighborhood){
      if (!prevProps.currentNeighborhood) {
        this.updateBoundary();
      } else if (prevProps.currentNeighborhood.permalink !== this.props.currentNeighborhood.permalink) {
        this.updateBoundary();
      }

    } else if (prevProps.currentNeighborhood && !this.props.currentNeighborhood){
      this.map.flyTo({
        zoom: 13,
        bearing: 29.599999999999685,
        center: [-74.00227719790553, 40.72330068263852]
      });
    }

    if (this.props.welcomeCurrentTime !== prevProps.welcomeCurrentTime) {
      this.updatePoiFilter();
    }
    
    this.checkUpdateCurrentMapEntity(prevProps);

    if (prevProps.isFullScreen !== this.props.isFullScreen) {
      this.map.resize();
    }
  }

  checkUpdateCurrentMapEntity(prevProps){
    // console.log(prevProps.currentSelectedMapEntity, this.props.currentSelectedMapEntity);

    if (!prevProps.currentSelectedMapEntity && this.props.currentSelectedMapEntity) {
      
      this.updateCurrentMapEntity(this.props.currentSelectedMapEntity);

    } else if (prevProps.currentSelectedMapEntity && this.props.currentSelectedMapEntity) {

      if (this.props.currentSelectedMapEntity.type === "Poi" || 
          this.props.currentSelectedMapEntity.type === "streetview") {
            
        if (prevProps.currentSelectedMapEntity.gid !== this.props.currentSelectedMapEntity.gid ||
            prevProps.currentSelectedMapEntity.type !== this.props.currentSelectedMapEntity.type) {
          // debugger;
          this.updateCurrentMapEntity(this.props.currentSelectedMapEntity);

        }
        
      } else if (this.props.currentSelectedMapEntity.type === "identification" || 
                 this.props.currentSelectedMapEntity.type === "story") {

        if (prevProps.currentSelectedMapEntity.id !== this.props.currentSelectedMapEntity.id ||
            prevProps.currentSelectedMapEntity.type !== this.props.currentSelectedMapEntity.type) {

          this.updateCurrentMapEntity(this.props.currentSelectedMapEntity);

        }
      } 
    
    }



  }

  updateCurrentMapEntity(currentSelectedMapEntity){
    let location;
    
    if (currentSelectedMapEntity.type === "Poi" || currentSelectedMapEntity.type === "streetview") {
      location = currentSelectedMapEntity.location;
    } else if (currentSelectedMapEntity.type === "identification") {
      location = currentSelectedMapEntity.streetview_location;
    } else if (currentSelectedMapEntity.type === "story") {
      location = currentSelectedMapEntity.geometry;
    }

    this.map.flyTo({
      center: location,
      zoom: 19
    });
  }

  updatePoiFilter(){
    let { welcomeCurrentTime } = this.props;

    this.map.setFilter('pois-layer-text', [
      "all",
      ["==", ["to-string", ["get", "is_public"]], "true"],
      ["all",
        [
          "any",
          ["!", ["has", "first_showed_at"]],
          [
            "all",
            ["<=", ["get", "first_showed_at"], welcomeCurrentTime[1]]
          ]
        ],
        [
          "any",
          ["!", ["has", "crowd_disappeared_at"]],
          [
            "all",
            [">=", ["get", "crowd_disappeared_at"], welcomeCurrentTime[0]]
          ]
        ],
      ]
    ]);

  }

  updateBoundary(){
    let { currentNeighborhood } = this.props;

    try {

      let bbox = turf.bbox(currentNeighborhood.boundary);
      this.map.fitBounds([
        bbox[0], bbox[1],
        bbox[2], bbox[3]
      ], {
        padding: { top: 100, bottom: 100, left: 15, right: 5 }
      });

      this.map.setFilter('neighborhood-boundaries-layer-clicked', ["==", ["get", "gid"], currentNeighborhood.gid]);
    } catch(e){
      console.log("style is not done loading, skipped")
    }
  }


  handleMouseMove(e) {
    if (e.features.length > 0) {
      let feature = e.features[0];
      this.map.setFilter('streetviews-layer-hover', ["==", ["get", "gid"], feature.properties.gid]);
      // console.log(feature.properties.gid);
    } else {
      this.map.setFilter('streetviews-layer-hover', ["==", ["get", "gid"], ""]);
    }

    // this.map.getCanvas().style.cursor = e.features.length > 0 ? 'pointer' : 'auto';
  }

  handleMouseLeave(e) {
    this.map.setFilter('streetviews-layer-hover', ["==", ["get", "gid"], ""]);

  }

  handlePoiClick(e){
    if (e.features.length > 0) {
      let feature = e.features[0];

      console.log(feature.properties);
      // debugger;
    }
  }
  

  render() {
    let { isFullScreen } = this.props;
    return (
      <MapDiv isFullScreen={isFullScreen} ref={c => { this.refsMapContainer = c; }} className="map-container">
      </MapDiv>
    );
  }
}

let mapStateToProps = state => {

  return {
    isFullScreen: state.isFullScreen,
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    mapLoaded: state.mapLoaded,
    currentNeighborhood: state.currentNeighborhood,
    welcomeCurrentTime: state.welcomeCurrentTime,
    currentSelectedMapEntity: state.currentSelectedMapEntity
  }
}

export default withRouter(connect(mapStateToProps)(MapContainer));