import React, { Component } from 'react';
import { ContentLink, PoiStreetView, LabelArea, BlueButton, ListTitle as Title, ListButtonArea as ButtonArea, Gutter } from '../stylesheets/components';
import { convertTitle, panoDateToRichDate } from '../utils';
import { connect } from 'react-redux';
import { changeCurrentSelectedMapEntity } from '../actions';
import styled from 'styled-components';

const Fragment = React.Fragment;

const ChangesTitle = styled.div`
  font-size: 0.875em;
  font-family: "Source Sans Pro";
  color: #828282;
  line-height: 1.2;

  span.white {
    font-weight: 600;
  }
`;

class IdentificationList extends Component {
  constructor(props){
    super(props);
    this.state = {
      clicked: false
    };
  }
  handleClick(e){
    
    let { identification } = this.props;
    document.location.href=`/streetviews/${identification.streetview_id}`;
  }

  handleToggleList(e){
    e.preventDefault();
    if (!this.state.clicked) {
      this.props.dispatch(changeCurrentSelectedMapEntity(this.props.identification));
    }
    this.setState({
      clicked: !this.state.clicked
    })
  }

  convertIdentificationToDescription(identification) {
    if (identification.category_id === 2) {

      return (
        <Fragment>
          <span className="white">{identification.vanished_poi ? identification.vanished_poi.name : "An unknown place"}</span> vanished around <span className="white">{panoDateToRichDate(identification.current_pano_date)}</span>
        </Fragment>
      );

    } else if (identification.category_id === 3 || identification.category_id === 4) {

      if (identification.vanished_poi) {

        return (
          <Fragment>
            <span className="white">{identification.vanished_poi ? identification.vanished_poi.name : "An unknown place"}</span> vanished around <span className="white">{panoDateToRichDate(identification.previous_pano_date)}</span> and <br/><span className="white">{identification.poi ? identification.poi.name : "another place"}</span> started business around <span className="white">{panoDateToRichDate(identification.current_pano_date)}</span>
          </Fragment>
        );

      } else {
        return (
          <Fragment>
            <span className="white">{identification.poi ? identification.poi.name : "another place"}</span> started business around <span className="white">{panoDateToRichDate(identification.current_pano_date)}</span>
          </Fragment>
        );
      }
    }
  }

  render() {
    let { identification } = this.props;
    return (
      <ContentLink href="#" className={ this.state.clicked ? "selected" : ""} onClick={this.handleToggleList.bind(this)} key={identification.id}>
        <PoiStreetView>
          <img className="left" src={`https://maps.googleapis.com/maps/api/streetview?pano=${identification.previous_pano_id}&size=400x400&heading=0&key=AIzaSyC2EvWU5FdBa4xgzA-mRodCJCdY_TP5z90`} alt="streetview images of the poi" />
          <div className="arrow">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3.1875 12.2128C3.0625 12.0878 3 11.9159 3 11.6971C3 11.4784 3.07031 11.2987 3.21094 11.1581C3.35156 11.0175 3.53125 10.9471 3.75 10.9471L18.4219 10.9471L15.2344 7.71277C14.8594 7.36901 14.8516 7.01746 15.2109 6.65808C15.5703 6.2987 15.9219 6.30652 16.2656 6.68152L20.7656 11.1815C20.8594 11.244 20.9219 11.3221 20.9531 11.4159C20.9844 11.5096 21 11.6034 21 11.6971C21 11.7909 20.9844 11.8846 20.9531 11.9784C20.9219 12.0721 20.8594 12.1503 20.7656 12.2128L16.2656 16.7128C15.9219 17.0878 15.5703 17.0956 15.2109 16.7362C14.8516 16.3768 14.8594 16.0253 15.2344 15.6815L18.4219 12.4471L3.75 12.4471C3.53125 12.4471 3.34375 12.369 3.1875 12.2128Z" fill="#4F4F4F" />
            </svg>
          </div>
          <img className="right" src={`https://maps.googleapis.com/maps/api/streetview?pano=${identification.current_pano_id}&size=400x400&heading=0&key=AIzaSyC2EvWU5FdBa4xgzA-mRodCJCdY_TP5z90`} alt="streetview images of the poi" />
        </PoiStreetView>
        <LabelArea>
          <div className="left">
            <ChangesTitle>
              {this.convertIdentificationToDescription(identification)}
            </ChangesTitle>
            {
              identification.user ? 
              <Fragment>
                <Gutter h={10} />
                <p className="category">
                  by <span className="white"><b>{ identification.user.screen_name }</b></span><br/>
                  {
                    identification.user.lived_neighborhood && identification.user.length_residence ?
                    <Fragment>
                      who lives { identification.user.lived_neighborhood } for { identification.user.length_residence } years
                    </Fragment> : null
                  }
                </p>
              </Fragment> : null
            }
          </div>
          <div className="right">
            <p className="small">
              {identification.current_pano_street}<br />{identification.current_pano_city}
            </p>
          </div>
          
        </LabelArea>
        {
          this.state.clicked ?
          <ButtonArea>
            <BlueButton onClick={this.handleClick.bind(this)} selected={true}>
              Show the change
            </BlueButton>
          </ButtonArea> : null
        }
      </ContentLink>
    );
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps)(IdentificationList);