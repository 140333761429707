import React, { Component } from 'react'
import { connect } from 'react-redux';
import { convertTitle, vincentyDirect } from '../utils';
import { Gutter } from '../stylesheets/components';
import { IknowthisplaceSection, ShareSupportSection, StrugglesSection } from './storywindows';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  background: white;
  left: 50%;
  bottom: 15px;
  min-width: 400px;
  transform: translate(-50%, 0);
  z-index:10000;

  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 10px;
  overflow: hidden;
`;


class StoryWindow extends Component {
  // handleClose(e){
  // }
  constructor(props) {
    super(props);
  }

  render() {
    let { mouseLocation, storyCategory, storyStep, currentPanoID, currentStreetview } = this.props;
    return (
      <Container>

        {
          storyCategory === 0 ?
          <ShareSupportSection currentPanoID={currentPanoID} /> : null
        }
        {
          storyCategory === 1 ?
          <IknowthisplaceSection currentPanoID={currentPanoID} /> : null
        }
        {
          storyCategory === 3 ?
          <StrugglesSection currentPanoID={currentPanoID} /> : null
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    storyCategory: state.storyCategory,
    storyStep: state.storyStep,
    identifyPov: state.identifyPov,
    GSVPOV: state.GSVPOV
  }
}

export default connect(mapStateToProps)(StoryWindow);