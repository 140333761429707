import React, { Component } from 'react'
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { point } from '@turf/turf';
import { BlueButton as GlobalBlueButton, WindowsSubtitle, IconWhiteButton, ScreenNameArea, WindowHeader as Header, Gutter } from '../stylesheets/components';
import { withRouter } from 'react-router-dom';
import { Content } from './storywindows/components';
import { changeNoticeMessage, changeCurrentStreetview, changeStoryCategory, changeStoryStep, changeProgress } from '../actions';
import { STORY_CATEGORY } from '../constants/defaults';
const Container = styled.div`
  background: white;
  min-width: 300px;
  transform: translate(-50%, 0);
  z-index:6;

  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 0;
  max-height: 300px;
  transform: translate(-50%, -100%);
  overflow: hidden;
  overflow-y: scroll;
`;

const BlueButton = styled(GlobalBlueButton)`
  font-size:0.8em;
  margin-bottom: 10px;
`;

class PoiWindow extends Component {
  constructor(props){
    super(props);
    this.state = {
      submitting: false
    }
  }
  handleShow(e){
    let { currentStreetview, poi } = this.props;
    this.props.history.push(`/pois/${poi.gid}`);
  }

  render() {
    let { poi } = this.props;
    console.log(poi);
    return (
      <Container>
        <Header>
          <div className="left-wrap">
            <div className="title">
              {poi.name}
            </div>
          </div>

          <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.props.handleClose}>
            <CloseIcon fontSize="small" />
          </IconWhiteButton>
        </Header>
        { 
          this._renderDisappearPopup()
        }
      </Container>
    )
  }

  async handleAppeared(e){
    let { currentPanoID, identifyPov, GSVPOV, identificationCategory } = this.props;

    if (!this.state.submitting) {
      this.setState({
        submitting: true
      });
      const headers = {
        'X-CSRF-Token': this.props.csrfToken,
        'X-Requested-With': 'XMLHttpRequest'
      };
  
      let formData = this.generateFormData(3);// category_id = 3 = appeared
      
      this.props.dispatch(changeProgress(true));
      const response = await fetch(`/identifications/poi_appeared.json`, { method: "post", body: formData, headers });
  
      if (response.ok) {
        let data = await response.json();
  
        this.setState({
          submitting: false
        });
        this.props.dispatch(changeNoticeMessage("Successfully updated!"));
        this.props.dispatch(changeProgress(false));
        // poi?
        this.props.dispatch(changeCurrentStreetview(data.streetview));
        this.props.handleClose();
  
      } else {
        let data = await response.json();
        console.log(data);
  
        this.setState({
          submitting: false
        });
        this.props.dispatch(changeProgress(false));
        return data;
  
      }
    }
  }

  async handleVanished(e){

    let { currentPanoID, identifyPov, GSVPOV, identificationCategory } = this.props;
    if (!this.state.submitting) {
      this.setState({
        submitting: true
      });
        
      const headers = {
        'X-CSRF-Token': this.props.csrfToken,
        'X-Requested-With': 'XMLHttpRequest'
      };

      let formData = this.generateFormData(2); // category_id = 2 = vanished

      this.props.dispatch(changeProgress(true));
      const response = await fetch(`/identifications/poi_vanished.json`, { method: "post", body: formData, headers });

      if (response.ok) {
        let data = await response.json();

        this.props.dispatch(changeNoticeMessage("Successfully updated!"));
        this.props.dispatch(changeProgress(false));
        this.setState({
          submitting: false
        });
        // poi?
        this.props.dispatch(changeCurrentStreetview(data.streetview));
        this.props.handleClose();


      } else {
        let data = await response.json();
        this.setState({
          submitting: false
        });
        this.props.dispatch(changeProgress(false));
        return data;

      }
    }
  }

  getPov(){
    let { panoid, poi } = this.props;

    let panoIDPoint = new google.maps.LatLng(panoid.location[1], panoid.location[0]);
    let poiPoint = new google.maps.LatLng(poi.location[1], poi.location[0]);

    let heading = google.maps.geometry.spherical.computeHeading(panoIDPoint, poiPoint);
    return {
      heading,
      pitch: -15
    };
  }

  generateFormData(category_id) {
    let formData = new FormData();
    let { user_signed_in, current_user, panoid, currentStreetview, poi, isCurrentPanoNow} = this.props;
    
    formData.append("streetview_id", currentStreetview.gid);
    formData.append("panoid_id", panoid.id);
    formData.append("poi_id", poi.gid);
    
    let pov = this.getPov();
    formData.append("heading", pov.heading);
    formData.append("pitch", pov.pitch);
    
    formData.append("category_id", category_id); // vanished
    
    if (category_id === 2) {
      if (isCurrentPanoNow) {
        formData.append("vanished_timestamp", new Date().getTime() / 1000);
      } else {
        formData.append("vanished_timestamp", panoid.timestamp);
      }  
    } else if (category_id === 3){
      if (isCurrentPanoNow) {
        formData.append("appeared_timestamp", new Date().getTime() / 1000);
      } else {
        formData.append("appeared_timestamp", panoid.timestamp);
      } 
    }
    
    if (user_signed_in) {
      formData.append("user_id", current_user.id);
    }


    return formData;
  }

  handleStoryClick(storyCategory){

    this.props.dispatch(changeStoryCategory(Number(storyCategory)));
    this.props.dispatch(changeNoticeMessage(`Point out where to put your ${STORY_CATEGORY[Number(storyCategory)].listLabel.toLowerCase() }; it should be on the right street view.`))
    this.props.dispatch(changeStoryStep(1));
    this.props.handleClose();
  }

  _renderDisappearPopup(){
    let { poi } = this.props;
    let { submitting } = this.state;
    return (
      <Content>
        <WindowsSubtitle>
          Some stores' information is incomplete/incorrect and you can update it. 
          Did {poi.name } first appear/disappear in this streetview?
        </WindowsSubtitle>

        <BlueButton selected={true} disabled={submitting} onClick={this.handleAppeared.bind(this)}>
          It first appeared in here; I cannot find {poi.name} before.
        </BlueButton>
        
        <BlueButton selected={true} disabled={submitting} onClick={this.handleVanished.bind(this)}>
          It disappeared in here; I cannot find {poi.name} after.
        </BlueButton>
        <Gutter h={10} />
        <WindowsSubtitle>
          Want to add a story about {poi.name}?
        </WindowsSubtitle>
        {
          _.map(STORY_CATEGORY, (category, key) => {
            return (
              <BlueButton key={key} selected={true} disabled={submitting} onClick={this.handleStoryClick.bind(this, key)}>{category.label}</BlueButton>
            )
          })
        }
        
        <Gutter h={10} />
        <WindowsSubtitle>
          To check more about {poi.name}:
        </WindowsSubtitle>
        <BlueButton selected={true} disabled={submitting}  onClick={this.handleShow.bind(this)}>
          Check {poi.name} more
        </BlueButton>
      </Content>
    )
  }

  _renderShowPopup(){
    let { poi } = this.props;
    return (
      <Content>
        <WindowsSubtitle>
          You can check more about the place.
        </WindowsSubtitle>
        <BlueButton selected={true} onClick={this.handleShow.bind(this)}>
          Check {poi.name} more
        </BlueButton>
      </Content>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    isCurrentPanoNow: state.isCurrentPanoNow,
    csrfToken: state.csrfToken,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user
  }
}

export default withRouter(connect(mapStateToProps)(PoiWindow));