import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { convertTitle, vincentyDirect } from '../../utils';
import { Gutter } from '../../stylesheets/components';
import { changeIdentifyPlaceStep, changeSnackBar, changeCurrentStreetview, changeCSRFToken, changeIdentificationCategory, changeNoticeMessage, changeProgress } from '../../actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as turf from '@turf/turf'
import { POI_CATEGORY } from '../../constants/defaults';
import { BlueButton, WindowsSubtitle, IconWhiteButton, ScreenNameArea, WindowHeader as Header, BlueInput } from '../../stylesheets/components';
import CloseIcon from '@material-ui/icons/Close';


const Content = styled.div`
  padding: 10px 15px;

`;

const PLACE_LABEL = {
  1: { value: 1, label: "the survived place" },
  2: { value: 2, label: "the vanished place" },
  3: { value: 3, label: "the new place" },
  4: { value: 4, label: "the new chain store" }
}

const CustomButtonGroup = withStyles({
  vertical: {
    width: "100%"
  },
  groupedContainedPrimary: {
    "&:not(:last-child)": {
      borderColor: "#EEE"
    }
  }
})(ButtonGroup);

const CustomButton = withStyles({
  containedPrimary: {
    width: "100%",
    color: "#333",
    backgroundColor: "white",
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    "&:hover": {
      backgroundColor: "#EEE"
    }
  }
})(Button);


class VanishedIdentificationSection extends Component {
  // handleClose(e){
  // }
  constructor(props) {
    super(props);
    this.state = {
      identifyVanishedPlaceName: "",
      identifyVanishedPlaceType: "",
      formPristine: true,
      submitting: false
    };

    this.formData = {

    }
  }

  handleVanishedClick(vanished_poi_id_data) {
    this.formData.vanished_poi_id = "";

    if (vanished_poi_id_data !== "cannot-recognize") {

      this.formData.vanished_poi_id = vanished_poi_id_data;

    }

    if (vanished_poi_id_data === "none-of-them") {

      this.props.dispatch(changeIdentifyPlaceStep(3));

    } else {


      if (!this.state.submitting) {

        this.postIdentification();

      }
    }

  }

  

  generateFormData() {
    let formData = new FormData();
    let { isCurrentPanoNow, current_user, user_signed_in, currentPanoID, identificationCategory, currentStreetview, selectedPanoIDIndex, identifyPov, pickedLatLng } = this.props;
    let leftPanoID;
    
    if (currentStreetview) {
      if (currentStreetview.panoids.length > 0) {

          if (selectedPanoIDIndex === 0) {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex];

          } else {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex - 1];

          }
        
      }
    }

    formData.append("streetview_id", currentStreetview.gid);
    formData.append("heading", identifyPov.heading);
    formData.append("pitch", identifyPov.pitch);
    formData.append("category_id", identificationCategory);
    formData.append("panoid_id", currentPanoID.id);
    formData.append("poi_lat", pickedLatLng.lat);
    formData.append("poi_lng", pickedLatLng.lng);
    formData.append("vanished_timestamp", leftPanoID.timestamp);
    
    if (user_signed_in) {
      formData.append("user_id", current_user.id);
    }


    _.each(this.formData, (value, key) => {
      formData.append(key, value);
    })

    return formData;
  }

  async postIdentification() {

    //TODOS: scalar projection if a user wants to correct the position! 


    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };

    if (!this.state.submitting) {

      let formData = this.generateFormData();
      this.setState({
        submitting: true
      });

      this.props.dispatch(changeProgress(true));
      const response = await fetch(`/identifications.json`, { method: "post", body: formData, headers });
  
      if (response.ok) {
        let data = await response.json();
        // this.props.dispatch(changeCSRFToken(data.csrfToken));
        this.props.dispatch(changeIdentifyPlaceStep(0));
        this.props.dispatch(changeCurrentStreetview(data.streetview));
        this.props.dispatch(changeProgress(false));
        this.props.dispatch(changeNoticeMessage("Successfully added!"));
        this.setState({
          submitting: false
        });
        
        this.formData = {};
  
      } else {
        let data = await response.json();
        console.log(data);
  
        this.setState({
          submitting: false
        });
        this.props.dispatch(changeProgress(false));
        return data;
  
      }
    }

  }

  renderStepTwo() {
    let { mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;

    return (
      <Content>
        {
          currentStreetview.pois.length > 0 ?
          <Fragment>
            <WindowsSubtitle>
              Great! Thanks for the contribution. <br />
              Is <span className="blue">the disappeared place</span> one of the following? 
            </WindowsSubtitle>
            <Gutter h={10} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              {
                _.map(currentStreetview.pois, (near_poi, key) => {
                  return (
                    <CustomButton size="small" key={key} onClick={this.handleVanishedClick.bind(this, near_poi.gid)}>{near_poi.name} ({convertTitle(near_poi.category)})</CustomButton>
                  )
                })
              }
            </CustomButtonGroup>
            <Gutter h={15} />
            <WindowsSubtitle> 
              Or if none of the list are not matched, 
              Could you recognize the name of <span className="blue">the disappeared place</span>?
            </WindowsSubtitle>
            <Gutter h={5} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "none-of-them")}>I can recognize the name of the disappeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>I cannot recognize the name of the disappeared place.</CustomButton>
            </CustomButtonGroup>
          </Fragment> :
          <Fragment>
            <WindowsSubtitle>
              Great! Thanks for the contribution. <br />
              Could you recognize the name of <span className="blue">the disappeared place</span>?
            </WindowsSubtitle>
            <Gutter h={10} />
            <CustomButtonGroup
              orientation="vertical"
              color="primary"
              variant="contained"
              aria-label="vertical outlined primary button group"
            >
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "none-of-them")}>I can recognize the name of the disappeared place.</CustomButton>
              <CustomButton size="small" onClick={this.handleVanishedClick.bind(this, "cannot-recognize")}>I cannot recognize the name of the disappeared place.</CustomButton>
            </CustomButtonGroup>
          </Fragment>
        }

        
      </Content>
    );
  }

  renderStepThree() {
    let { current_user, user_signed_in, mouseLocation, step, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;
    let options = POI_CATEGORY;

    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;
    
    return (
      <Content>
        <WindowsSubtitle>
          Could you add <span className="blue">the name of the disappeared place?</span>
        </WindowsSubtitle>

        <Gutter h={5} />
        {
          user_signed_in ? 
          <ScreenNameArea>
            Contributed by<br/>
            <b>{ current_user.screen_name }</b>
          </ScreenNameArea> : null
        }
        <TextField 
          required
          value={identifyVanishedPlaceName} onChange={(event) => {
            const { value } = event.target;
            
            this.setState({
              identifyVanishedPlaceName: value,
              formPristine: false
            })
          }} 
          label="Name" 
          InputProps={{
            style: {
              fontFamily: "Source Sans Pro",
              fontSize: "1.0em",
              lineHeight: 1.1,
              color: "black"
            }
          }}
          variant="outlined" 
          fullWidth />
        <Gutter h={20} />
        <Autocomplete
          id="combo-box-demo"
          options={options}
          onChange={(event, value) => {
            console.log("input identifyPlaceType", value);
            this.setState({
              identifyVanishedPlaceType: value,
              formPristine: false
            });
          }}
          value={identifyVanishedPlaceType}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField {...params} label="Type of the place" variant="outlined" fullWidth />
          )}
        />

        <Gutter h={20} style={{
          borderBottom: "1px dotted #ccc",
          marginBottom: 20
        }} />
          
        <BlueInput value="Submit" type="submit" selected={true} disabled={this.state.formPristine || this.state.submitting || !this.formValidate()} onClick={this.handleVanishedPlaceNameSubmit.bind(this)} />
        <Gutter h={20} />
      </Content>
    );
  }

  formValidate(){
    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;
    if (identifyVanishedPlaceName.length === 0) {
      return false;
    }

    if (_.isNull(identifyVanishedPlaceType) || _.isUndefined(identifyVanishedPlaceType) || identifyVanishedPlaceType === "") {
      return false;
    }
    return true;
  }


  handleVanishedPlaceNameSubmit(e) {
    let { currentPanoID, identifyPov, GSVPOV } = this.props;
    let { identifyVanishedPlaceName, identifyVanishedPlaceType } = this.state;
    

    this.formData.vanished_poi_id = "";
    this.formData.vanished_poi_name = identifyVanishedPlaceName;
    this.formData.vanished_poi_type = identifyVanishedPlaceType.value;
    this.postIdentification();
  }


  handlePrev(e) {
    this.props.dispatch(changeIdentifyPlaceStep(2));
  }


  handleClose(e) {
    this.props.dispatch(changeIdentifyPlaceStep(0));
    this.props.dispatch(changeIdentificationCategory(null));
  }

  render() {
    let { mouseLocation, identificationCategory, identifyPlaceStep, currentStreetview } = this.props;


    return (
      <Fragment>
        <Header>
          <div className="left-wrap">

            {
              identifyPlaceStep === 3 ?
                <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handlePrev.bind(this)}>
                  <ChevronLeftIcon fontSize="small" />
                </IconWhiteButton> : null
            }
            <div className="title">
              Identify the place
            </div>
          </div>

          <IconWhiteButton color="primary" aria-label="prev streetview" size="small" onClick={this.handleClose.bind(this)}>
            <CloseIcon fontSize="small" />
          </IconWhiteButton>
        </Header>
        {
          identifyPlaceStep === 2 ?
          this.renderStepTwo() : null
        }

        {
          identifyPlaceStep === 3 ?
          this.renderStepThree() : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    selectedPanoIDIndex: state.selectedPanoIDIndex,
    identificationCategory: state.identificationCategory,
    identifyPlaceStep: state.identifyPlaceStep,
    identifyPov: state.identifyPov,
    imageCoordinate: state.imageCoordinate,
    pickedLatLng: state.pickedLatLng,
    GSVPOV: state.GSVPOV,
    csrfToken: state.csrfToken,
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    isCurrentPanoNow: state.isCurrentPanoNow
  }
}

export default connect(mapStateToProps)(VanishedIdentificationSection);
    