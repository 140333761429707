import React, { Component } from 'react'
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { changeSnackBar } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import { SnackbarContent } from '@material-ui/core';

const Fragment = React.Fragment;

const CustomSnackbarContent = withStyles({
  root: {
    backgroundColor: "black"
  },
  message: {
    color: "white"
  }
})(SnackbarContent);

const IconWhiteButton = withStyles({
  root: {
    color: "white"
  }
})(IconButton);

class CustomSnackBar extends Component {
  constructor(props){
    super(props);

  }

  handleSnackbarClose(e){
    this.props.dispatch(changeSnackBar({
      show: false
    }));
  }

  render() {
    let { snackBar } = this.props;
    return (
      <Snackbar 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }} 
        open={snackBar.show} 
        autoHideDuration={6000} 
        onClose={this.handleSnackbarClose.bind(this)}>      
        <CustomSnackbarContent 
          message={snackBar.msg} 
          action={
          <Fragment>
            <IconWhiteButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose.bind(this)}>
              <CloseIcon fontSize="small" />
            </IconWhiteButton>
          </Fragment>
        } />   
      </Snackbar>
    )
  }
}

let mapStateToProps = state => {
  return {
    snackBar: state.snackBar
  };
};

export default connect(mapStateToProps)(CustomSnackBar);