import { DEFAULT_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';
import * as THREE from 'three';

export default function welcomeReducer(state = DEFAULT_WELCOME_STATE, action){

  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      };
  
    case "CHANGE_IS_FULL_SCREEN":
      return {
        ...state,
        isFullScreen: action.payload.isFullScreen
      }

    case "CHANGE_PROGRESS":
        return {
          ...state,
          progress: action.payload.progress
        }
    case "CHANGE_LEFT_CONTAINER_SCROLL_TOP": 
      return {
        ...state,
        leftContainerScrollTop: action.payload.leftContainerScrollTop
      }

    case "CHANGE_STREETVIEW_TUTORIAL_POPUP":
      return {
        ...state,
        streetviewTutorialPopup: action.payload.streetviewTutorialPopup
      };

    case "CHANGE_CURRENT_SELECTED_MAP_ENTITY": 
      return {
        ...state,
        currentSelectedMapEntity: action.payload.currentSelectedMapEntity
      }
    case "CHANGE_IS_CURRENT_PANO_NOW":
      return {
        ...state,
        isCurrentPanoNow: action.payload.isCurrentPanoNow
      }
    case "CHANGE_TUTORIAL_POPUP":
      return {
        ...state,
        tutorialPopup: action.payload.tutorialPopup
      }
    case "CHANGE_CURRENT_STORY":
      return {
        ...state,
        currentStory: action.payload.currentStory
      }
    case "CHANGE_NEIGHBORHOOD_SEARCH_RESULTS":
      return {
        ...state,
        neighborhoodSearchResults: action.payload.neighborhoodSearchResults
      }
    case "CHANGE_FOCUSED_NEIGHBORHOODS":
      return {
        ...state,
        focusedNeighborhoods: action.payload.focusedNeighborhoods
      };
    case "CHANGE_CURRENT_POI":
      return {
        ...state,
        currentPoi: action.payload.currentPoi
      }
    case "CHANGE_MAP_ZOOM":
      return {
        ...state,
        mapZoom: action.payload.mapZoom
      }
    case "CHANGE_STORY_CATEGORY":
      return {
        ...state,
        storyCategory: action.payload.storyCategory
      };
    case "CHANGE_STORY_STEP":
      return {
        ...state,
        storyStep: action.payload.storyStep
      };
    case "CHANGE_STREETVIEW_TAB":
      return {
        ...state,
        streetviewTab: action.payload.streetviewTab
      };
      
    case "CHANGE_WELCOME_CURRENT_TIME":
      return {
        ...state,
        welcomeCurrentTime: action.payload.welcomeCurrentTime
      };
    case "CHANGE_CURRENT_NEIGHBORHOOD":
      return {
        ...state,
        currentNeighborhood: action.payload.currentNeighborhood
      }
    case "CHANGE_NEIGHBORHOOD_BOUNDARIES":
      return {
        ...state,
        neighborhoodBoundaries: action.payload.neighborhoodBoundaries
      }

    case "CHANGE_SNACK_BAR":
      return {
        ...state,
        snackBar: action.payload.snackBar
      };
    case "CHANGE_IDENTIFY_POV":
      return {
        ...state,
        identifyPov: action.payload.identifyPov,
        imageCoordinate: action.payload.imageCoordinate,
        pickedLatLng: action.payload.pickedLatLng
      }
    case "CHANGE_IDENTIFY_PLACE_STEP":
      return {
        ...state,
        identifyPlaceStep: action.payload.identifyPlaceStep
      };
    case "CHANGE_IDENTIFICATION_ACTIVATED":
      return {
        ...state,
        identificationActivated: action.payload.identificationActivated
      }
    case "CHANGE_IDENTIFICATION_CATEGORY":
      return {
        ...state,
        identificationCategory: action.payload.identificationCategory
      }
    case "CHANGE_GSVPOV":
      return {
        ...state,
        GSVPOV: action.payload.GSVPOV
      };
    case "CHANGE_SELECTED_PANOID_INDEX":
      return {
        ...state,
        selectedPanoIDIndex: action.payload.selectedPanoIDIndex
      }
    case "CHANGE_CAMERA_MATRIX_WORLD":
      return {
        ...state,
        cameraMatrixWorld: action.payload.cameraMatrixWorld
      }
    case "CHANGE_ACTIVE_PANOID_SCREEN":
      return {
        ...state, 
        activePanoIDScreen: action.payload.activePanoIDScreen
      }
    case "CHANGE_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      }
    case "CHANGE_CSRF_TOKEN":
      return {
        ...state,
        csrfToken: action.payload.csrfToken
      }
    case "LOG_IN": 
      return {
        ...state,
        current_user: action.payload.current_user,
        user_signed_in: true
      }
    case "CHANGE_MAP_LOADED":
      return {
        ...state,
        mapLoaded: action.payload.mapLoaded
      }
    case "CHANGE_NOTICE_MESSAGE":
      return {
        ...state,
        noticeMessage: action.payload.noticeMessage
      }
    case "CHANGE_CURRENT_STREETVIEW":
      return {
        ...state,
        currentStreetview: action.payload.currentStreetview
      }
    case "CHANGE_LOGIN_INFO":
      return {
        ...state,
        new_user_session_path: action.payload.new_user_session_path,
        current_user: action.payload.current_user,
        user_signed_in: action.payload.user_signed_in,
        destroy_user_session_path: action.payload.destroy_user_session_path
      }
    case "SIGN_OUT":
      return {
        ...state,
        current_user: null,
        user_signed_in: false
      }
    default: 
      return {
        ...state
      };
  }

};