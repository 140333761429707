import React, { Component } from 'react'
import _ from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { signOut } from '../actions';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const Fragment = React.Fragment;

const LoginComponent = styled.div`
  margin-left: 10px;

  a {
    margin-left: 10px;
  }
`;

class UserLoginComponent extends Component {

  async handleLogout(url) {
    try {
      const headers = {
        'X-CSRF-Token': this.props.csrfToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }

      const response = await fetch(url, { method: "delete", headers });
      if (response.ok) {
        this.props.dispatch(signOut());
        document.location.href = "/";
      }

    } catch (error) {
      console.error(error);
    }
  }

  footerLogin() {

    let { mode, isLogoutEnabled, user_signed_in, current_user, destroy_user_session_path, new_user_registration_path } = this.props;

    if (user_signed_in) {
      return (
        <Fragment>
          <a href={`/users/${current_user.id}`}>My Account</a>
          {
            isLogoutEnabled ?
              <a href="javascript:void(0);" onClick={this.handleLogout.bind(this, destroy_user_session_path)}>Log Out</a> : null
          }
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <a href="/users/sign_in">Log in</a>
          <a href={new_user_registration_path}>Sign up</a>
        </Fragment>
      );
    }
  }
  render() {
    let {  isLogoutEnabled, user_signed_in, current_user, destroy_user_session_path, new_user_registration_path } = this.props;

    return (
     
      <LoginComponent>
        {
          user_signed_in ?

          <div>
            {/* <a href={`/users/${current_user.id}`}>My Account</a> */}
            {
              isLogoutEnabled ?
                <a href="javascript:void(0);" onClick={this.handleLogout.bind(this, destroy_user_session_path)}>Log Out</a> : null
            }
          </div> :
          <Fragment>
              <Link to="/users/sign_in">Log in</Link>
              <Link to={new_user_registration_path}>Sign up</Link>
          </Fragment>
        }
      </LoginComponent>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    destroy_user_session_path: state.destroy_user_session_path,
    new_user_registration_path: state.new_user_registration_path,
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(UserLoginComponent);