import React, { Component } from 'react'
import { StoryList, IdentificationList } from '../components';
import { changeLoginInfo, changeNoticeMessage, logIn, changeLeftContainerScrollTop, changeProgress } from '../actions';
import { LeftContainer, Gutter, WindowsBigtitle, WindowsSubtitle, BlueButton, CustomTextField, Tab, TabButton, LogoLink } from '../stylesheets/components';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Redirect } from 'react-router-dom';

const Fragment = React.Fragment;
const FormField = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  label {
    color: #828282;
  }

  input {
    border: none;
    width: 330px;
    font-size: 1.0em;
    background:transparent;
  }

  &:last-child {
    border-bottom: none;
  }
`;



const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    margin-left: 5px;
  }
`;

class UserShow extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    
    this.state = {
      mode: "stories"
    };
  }
  async handleSubmit(values){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    this.props.dispatch(changeProgress(true));
    const response = await fetch("/users", { method: "put", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeNoticeMessage(data.notice));
      this.props.dispatch(logIn(data.user));
      this.props.dispatch(changeProgress(false));
      // this.props.history.push('/contribute');

    } else {
      let data = await response.json();
      // console.log(data);
      this.props.dispatch(changeNoticeMessage(data.alert));
      this.props.dispatch(changeProgress(false));
      return {
        user: data.errors
      };
    }

  }

  async handlePWSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    this.props.dispatch(changeProgress(true));
    const response = await fetch("/users", { method: "put", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeNoticeMessage("Your password is successfully changed and you need to sign in again."));
      this.props.history.push('/users/sign_in');
      this.props.dispatch(changeProgress(false));

    } else {
      let data = await response.json();
      // console.log(data);
      this.props.dispatch(changeNoticeMessage(data.alert));
      this.props.dispatch(changeProgress(false));
      return {
        user: data.errors
      };
    }


    // if (response.ok) {

    // } else {
    //   let rr = response.clone().json();
    //   console.log(rr)
    //   return rr;
    // }
  }


  handleValidate(values) {
    const errors = {};
    if (values.user) {


      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = 'Required';
      }

      if (!values.user.screen_name) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.screen_name = 'Required';
      }


      if (!values.user.lived_neighborhood) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.lived_neighborhood = "Required; please type NA if you don't live in NYC";
      }


      if (!values.user.length_residence) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.length_residence = "Required; please type 0 if you don't live in NYC";
      }



    }
    return errors;
  }


  handlePWValidate(values) {
    const errors = {};
    if (values.user) {


      if (!values.user.password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password = 'Required';
      }

      if (!values.user.password_confirmation) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password_confirmation = 'Required';
      }


      if (!values.user.current_password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.current_password = "Required";
      }

    }
    return errors;
  }

  _renderUserEdit(){
    let { user_signed_in, current_user, csrfToken } = this.props;
    
    return (
      <Fragment>
        <Form
          onSubmit={this.handleSubmit.bind(this)}

          validate={this.handleValidate.bind(this)}
          initialValues={{
            user: this.props.current_user
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (

            <form onSubmit={handleSubmit}>

              <input type="hidden" name="_method" value="put" />
              <input type="hidden" name="authenticity_token" value={csrfToken} />

              <FormField>
                <Field
                  fullWidth
                  name="user[email]"
                  component={CustomTextField}
                  type="email"
                  label="Email"
                />
              </FormField>
              <FormField>
                <Field
                  fullWidth
                  name="user[screen_name]"
                  component={CustomTextField}
                  type="text"
                  label="Screen Name"
                />
              </FormField>

              <FormField>
                <Field
                  fullWidth
                  name="user[lived_neighborhood]"
                  component={CustomTextField}
                  type="text"
                  label="Lived Neighborhoods (West Village, Park Slope, ...)"
                />
              </FormField>

              <FormField>
                <Field
                  fullWidth
                  name="user[length_residence]"
                  component={CustomTextField}
                  type="text"
                  label="Length of Residence (Year)"
                />
              </FormField>

              <Gutter h={20} />
              <ButtonArea>
                <div>
                  <BlueButton type="submit"
                    selected={true} disabled={submitting || pristine}>
                    Update Information
                  </BlueButton>
                </div>
              </ButtonArea>
            </form>
          )}
        />
        <Gutter h={100} />
        <WindowsSubtitle style={{color: "white"}}>
          Update Password
        </WindowsSubtitle>
        <Form
          onSubmit={this.handlePWSubmit.bind(this)}

          validate={this.handlePWValidate.bind(this)}
          initialValues={{
            user: this.props.current_user
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (

            <form onSubmit={handleSubmit}>

              <input type="hidden" name="_method" value="put" />
              <input type="hidden" name="authenticity_token" value={csrfToken} />
              <input type="hidden" name="mode" value="password_change" />

              <FormField>
                <Field
                  fullWidth
                  name="user[password]"
                  component={CustomTextField}
                  type="password"
                  label="Password (leave blank if you don't want to change it)"
                />
              </FormField>

              <FormField>
                <Field
                  fullWidth
                  name="user[password_confirmation]"
                  component={CustomTextField}
                  type="password"
                  label="Password confirmation (leave blank if you don't want to change it)"
                />
              </FormField>



              <FormField>
                <Field
                  fullWidth
                  name="user[current_password]"
                  component={CustomTextField}
                  type="password"
                  label="Current password (we need your current password to confirm your changes)"
                />
              </FormField>

              <Gutter h={20} />
              <ButtonArea>
                <div>
                  <BlueButton type="submit"
                    selected={true} disabled={submitting || pristine}>
                    Update Password
                  </BlueButton>
                </div>
              </ButtonArea>
            </form>
          )}
        />
      </Fragment>
    );
  }


  handleTabButtonClick(mode) {
    this.setState({
      mode: mode
    });
  }


  handleScroll(e){
    this.props.dispatch(changeLeftContainerScrollTop(e.target.scrollTop));
  }

  _renderStories(){
    let { user_signed_in, current_user, csrfToken } = this.props;

    return (
      <Fragment>
        {
          current_user.stories.length > 0 ? 
          _.map(current_user.stories, story => {
            return (
              <StoryList story={story} key={story.id} />
            );
          }) : 
          <p className="small">
            No stories and support yet. 
          </p>
        }
      </Fragment>
    );
  }

  _renderChanges(){
    let { user_signed_in, current_user, csrfToken } = this.props;

    return (
      <Fragment>
        {
          current_user.identifications.length > 0 ?
          _.map(current_user.identifications, identification => {
            return (
              <IdentificationList identification={identification} key={identification.id} />
            );
          })
         :
        <p className="small">
          No historical changes identified yet.
        </p>
      
        }
      </Fragment>
    );
  }


  render() {
    let { mode } = this.state;
    let { user_signed_in, current_user, csrfToken } = this.props;
    
    
    if (user_signed_in) {
      return (
        <LeftContainer onScroll={this.handleScroll.bind(this)}>
          
          <WindowsBigtitle>
            {current_user.screen_name}
          </WindowsBigtitle>
          <Tab>
            <TabButton selected={this.state.mode === "stories"} onClick={this.handleTabButtonClick.bind(this, "stories")}>
              Stories
            </TabButton>

            <TabButton selected={this.state.mode === "changes"} onClick={this.handleTabButtonClick.bind(this, "changes")}>
              Identified Storefront Changes
            </TabButton>

            <TabButton selected={this.state.mode === "user_edit"} onClick={this.handleTabButtonClick.bind(this, "user_edit")}>
              Edit User Information
            </TabButton>
          </Tab>
          {
            mode === "stories" ?
            this._renderStories() : null
          }

          {
            mode === "changes" ?
            this._renderChanges() : null
          }

          {
            mode === "user_edit" ?
            this._renderUserEdit() : null
          }
        </LeftContainer>
      )
    } else {
      return (
        <Redirect to="/users/sign_in" />
      );
    }
  }
}

let mapStateToProps = state => {
  return {
    csrfToken: state.csrfToken,
    current_user: state.current_user,
    user_signed_in: state.user_signed_in
  }
}

export default connect(mapStateToProps)(UserShow);