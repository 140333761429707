import React from 'react';
import _ from 'lodash';
import { windowResize, changeCurrentContribution, changeNoticeMessage, logIn, changeProgress } from '../actions';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { DeviseError } from '../components';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BlueButton, CustomTextField, LeftContainer, Gutter, WindowsBigtitle, ConsentText } from '../stylesheets/components'
import { Button } from '@material-ui/core';
import { TextField, Input } from 'final-form-material-ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


const Fragment = React.Fragment;
const NewBox = styled.div`
  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  width: 414px;
  padding: 20px;
  background: white;
  padding: 20px;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.15);
  z-index: 6;



  @media (max-width: 768px) {
    
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    box-shadow: none;
    left: 0;
    top: 0;
    transform: none;
  }
  
`;

const Title = styled.h2`
  font-family: "Noh Optique Display";
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-align: center;
`

const CloseBtn = styled(Link)`
  position: absolute;
  right: 20px;
  top: 20px;
`
const ContLabel = styled.label`
  font-weight: 600;
  color: #333333;
  margin: 5px 0;
  display: block;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;


const FormField = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  label {
    color: #828282;
    min-width: 120px;
  }

  input {
    border: none;
    width: 330px;
    font-size: 1.0em;
    background:transparent;
  }

  &:last-child {
    border-bottom: none;
  }
`;


const WhiteLink = styled(Link)`
  color: #AAA;
`;


const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    margin-left: 5px;
  }
`;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
  }
  async handleSubmit(values) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    this.props.dispatch(changeProgress(true));
    const response = await fetch("/users", { method: "post", body: JSON.stringify(values), headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(logIn(data.user));
      this.props.dispatch(changeNoticeMessage("Successfully Signed Up."))
      this.props.dispatch(changeProgress(false));
      this.props.history.push('/contribute');
      return true;
    } else {
      let data = await response.json();
      this.props.dispatch(changeProgress(false));
      return data;
    }
    
    
    // if (response.ok) {

    // } else {
    //   let rr = response.clone().json();
    //   console.log(rr)
    //   return rr;
    // }
  }


  componentDidMount() {
  }

  handleValidate(values) {
    const errors = {};
    if (values.user) {

      if (!values.user.screen_name) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.screen_name = 'Required';
      }

      if (!values.user.email) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.email = 'Required';
      }

      if (!values.user.password) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password = 'Required';
      }

      if (!values.user.lived_neighborhood) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.lived_neighborhood = "Required: please type NA if you don't live in NYC";
      }

      if (!values.user.length_residence) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.length_residence = "Required: please type 0 if you don't live in NYC";
      }

      

      if (!values.user.password_confirmation) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password_confirmation = 'Required';
      }

      if (values.user.password !== values.user.password_confirmation) {
        if (!errors.user) {
          errors.user = {};
        }
        errors.user.password_confirmation = 'Should match with the password';
      }



    }
    return errors;
  }

  render() {
    let { errors } = this.props;

    return (
      <LeftContainer>
        
        <WindowsBigtitle>
          Sign up
        </WindowsBigtitle>

        {
          _.isUndefined(errors) ?
            null :
            <DeviseError {...errors} />
        }
        {
          this.props.flash ?
            <Fragment>

              {
                _.isUndefined(this.props.flash.alert) ? null :
                  <div>
                    {this.props.flash.alert}
                    <Gutter h={6} />
                  </div>
              }

              {
                _.isUndefined(this.props.flash.notice) ? null :
                  <div>
                    {this.props.flash.notice}
                    <Gutter h={6} />
                  </div>
              }
            </Fragment> : null
        }

        <Form
          initialValues={{}}
          onSubmit={this.handleSubmit.bind(this)}
          validate={this.handleValidate.bind(this)}
          render={({ handleSubmit, reset, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <FormField>
                <Field
                  fullWidth
                  required
                  name="user[screen_name]"
                  component={CustomTextField}
                  type="text"
                  label="Screen Name"
                />
              </FormField>
              <FormField>
                <Field
                  fullWidth
                  required
                  name="user[email]"
                  component={CustomTextField}
                  type="email"
                  label="Email"
                />
              </FormField>
              <FormField>
                <Field
                  fullWidth
                  name="user[lived_neighborhood]"
                  component={CustomTextField}
                  type="text"
                  label="Lived Neighborhoods (West Village, Park Slope, ...)"
                />
              </FormField>

              <FormField>
                <Field
                  fullWidth
                  name="user[length_residence]"
                  component={CustomTextField}
                  type="text"
                  label="Length of Residence (Year)"
                />
              </FormField>
              
              <FormField>
                <Field
                  fullWidth
                  required
                  name="user[password]"
                  component={CustomTextField}
                  type="password"
                  label="Password"
                />
              </FormField>
              <FormField>

                <Field
                  fullWidth
                  required
                  name="user[password_confirmation]"
                  component={CustomTextField}
                  type="password"
                  label="Password Confirmation"
                />
              </FormField>
              <Gutter h={20} />
              <ConsentText>
                * By clicking "Sign up", you are confirming your consent to participate in this platform. <span className="white">Your contribution will be presented publicly</span>, including showing your screen name. However, it won't show your email address and the email address works as a unique identifier and finding password. <span className="white">If you want to contibute anonymously, please contribute without signing up.</span>
              </ConsentText>
              <Gutter h={20} />
              <ButtonArea>
                <div>
                  <BlueButton
                    selected={true} 
                    disabled={submitting || pristine}>
                    Sign up
                  </BlueButton>
                  &nbsp;

                  <WhiteLink to="/users/sign_in">
                    Log in
                  </WhiteLink>
                </div>
                <div>
                  <WhiteLink to="/users/password/new">Forgot password?</WhiteLink>
                </div>
              </ButtonArea>
              <Gutter h={10} />
            </form>
          )}
        />

      </LeftContainer>
    );
  }
}

let mapStateToProps = state => {
  return {
    new_password_path: state.new_password_path,
    new_registration_path: state.new_registration_path,
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(SignUp);