import React, { Component } from 'react'
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 15px;
  z-index: 5;
  transform: translate(-50%, 0);

  background: white;
  border: 1px solid #E4F6F7;
  box-sizing: border-box;
  box-shadow: 3px 3px 0px #1125AA;
  border-radius: 10px;
  padding: 12px 15px;

  div.desc {
    font-family: "Source Sans Pro";
    font-size: 12px;
    line-height: 15px;
    color: #6C6C6C;
  }
`;

const Title = styled.h3`
  font-family: Heldane Display Test;
  font-size: 1.2em;
  line-height: 1.2;
  color: #1125AA;
`;


class ContributeTitle extends Component {
  render() {
    return (
      <Container>
        <Title>
          Streets and Places
        </Title>
        <div className="desc">
          Navigate your neighborhoods and find your favorite places either survived or disappeared.
        </div>
      </Container>
    )
  }
}

export default ContributeTitle;