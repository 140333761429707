import React, { Component } from 'react'
import styled from 'styled-components';
import { Gutter, Tab as GlobalTab, TabButton, BackLink } from '../stylesheets/components';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { changeStreetviewTutorialPopup } from '../actions';
import { connect } from 'react-redux';
import { RED_COLOR } from '../constants/defaults';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const Fragment = React.Fragment;

const BlackBack = styled.div`
  position: fixed;
  z-index: 7;
  width: 100vw;
  height: 100vh;
  background: rgba( 0, 0, 0, 0.8);
`;

const Container = styled.div`
  position: fixed;
  z-index: 4;
  width: calc(100vw - 150px);
  height: calc(100vh - 150px);
  max-width:930px;
  max-height:763px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
  z-index: 9;
  border-radius: 20px;
  border: 1px solid #111;
  box-shadow: 2px 2px 0px ${props => props.selected ? RED_COLOR : "#303030"};
  overflow: hidden;
`;

const Tab = styled(GlobalTab)`
  margin-bottom: 0;
`;

const Title = styled.div`
  font-family: "Heldane Display";
  color: white;
  font-size: 3.3em;
  line-height: 1.1;
  color: white;
`;

const Description = styled.div`
  
  font-family: "Source Sans Pro";
  font-weight: 400;
  color: #999;
  width: 60%;
  line-height: 1.4;
  margin-bottom: 20px;
  font-size:0.9em;

  span.white {
    font-weight: 600;
  }
`;

const CenterWrapper = styled.div`
  
  position: absolute;
  left: 50%;
  top: 30px;
  width: calc(100% - 60px);
  transform: translate(-50%, 0);


  div.wrapper { 
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  h1 {
    font-family: "Source Sans Pro";
    font-weight: 600;
    color: white;
  }


  div.btn-area {
    display: flex;
  }
`;


const CenterContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;


const ClosebtnArea = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const TUTORIAL_DATA = {
  "identify": [
    {
      url: "/assets/step_03.png",
      description: 
        <Description>
          <span className="white">1. Navigate the storefront changes:</span> Drag the slider to nagivate the street changes, especially which store disappeared and which one appeared. For instance, in the right side, we can see here a new store appeared in June 2011.
        </Description>
    },
    {
      url: "/assets/step_04.png",
      description: 
        <Description>
          <span className="white">2. Click the button "New place appeared" then a small indicator will follow your mouse pointer. click the location of the store.</span> 
        </Description>
    },
    {
      url: "/assets/step_05.png",
      description: 
        <Description>
          <span className="white">3. A window pops up to add the store information. In here, we know this place is Organic Avenue so I clicked them.</span> 
        </Description>
    },
    {
      url: "/assets/step_06.png",
      description: 
        <Description>
          <span className="white">4. Choose or type the disappeared place if there is one. In here it was an empty space. So click "No, it was an empty space before."</span> 
        </Description>
    },
    {
      url: "/assets/step_07.png",
      description: 
        <Description>
          <span className="white">5. Done! </span> you just marked the store appeared in 2011. 
        </Description>
    }
  ],
  "stories": [
    {
      url: "/assets/step_08.png",
      description: 
        <Description>
          <span className="white">1. Add a memory and story:</span> In the place page, click one of the three buttons on the center and position the indicator on the street view.
        </Description>
    },
    {
      url: "/assets/step_09.png",
      description: 
        <Description>
          <span className="white">2. Add your memory or support.</span> Done! 
        </Description>
    }
  ]
}
const StepImg = styled.img `
  width: 100%;
`;

const PrevNextArea = styled.div`
  display: flex;
  align-items: center;
`;

const IconWhiteButton = withStyles({
  root: {
    color: "white"
  }
})(IconButton);

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

class StreetviewTutorialShow extends Component {
  constructor(props){
    super(props);

    this.state = {
      mode: "identify",
      idx: 0
    }
  }

  handleClose(e) {
    this.props.dispatch(changeStreetviewTutorialPopup(false));
  }

  handleTabButtonClick(mode) {
    this.setState({
      mode: mode
    });
  }

  handlePrevClick(e){
    this.setState({
      idx: Math.max(0, this.state.idx - 1)
    })
  }

  handleNextClick(e){
    
    this.setState({
      idx: Math.min(TUTORIAL_DATA[this.state.mode].length - 1, this.state.idx + 1)
    })
  }

  render() {

    let currentStep = TUTORIAL_DATA[this.state.mode][this.state.idx];

    return (
      <Fragment>
        <BlackBack />
        <Container>
                <CenterWrapper>
                  <div>
                    
                    <CenterContainer>
                      <Title>
                        How to use the streetview and contribute data
                      </Title>
                      <ClosebtnArea>
                        <IconWhiteButton color="primary" aria-label="prev streetview" onClick={this.handleClose.bind(this)}>
                          <CloseIcon fontSize="default" />
                        </IconWhiteButton>
                      </ClosebtnArea>
                      <div className="desc-area">
                          <TabContainer>
                            <Tab>
                              <TabButton selected={this.state.mode === "identify"} onClick={this.handleTabButtonClick.bind(this, "identify")}>
                                How to Identify Changes
                              </TabButton>

                              <TabButton selected={this.state.mode === "stories"} onClick={this.handleTabButtonClick.bind(this, "stories")}>
                                How to Add Stories
                              </TabButton>
                            </Tab>
                            <PrevNextArea>
                              <BackLink selected={this.state.idx !== 0} onClick={this.handlePrevClick.bind(this)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M20.8125 11.7872C20.9375 11.9122 21 12.0841 21 12.3029C21 12.5216 20.9297 12.7013 20.7891 12.8419C20.6484 12.9825 20.4688 13.0529 20.25 13.0529H5.57812L8.76562 16.2872C9.14063 16.631 9.14844 16.9825 8.78906 17.3419C8.42969 17.7013 8.07813 17.6935 7.73438 17.3185L3.23438 12.8185C3.14062 12.756 3.07812 12.6779 3.04688 12.5841C3.01562 12.4904 3 12.3966 3 12.3029C3 12.2091 3.01562 12.1154 3.04688 12.0216C3.07812 11.9279 3.14062 11.8497 3.23438 11.7872L7.73438 7.28723C8.07813 6.91223 8.42969 6.90442 8.78906 7.2638C9.14844 7.62317 9.14063 7.97473 8.76562 8.31848L5.57812 11.5529H20.25C20.4688 11.5529 20.6562 11.631 20.8125 11.7872Z" fill={this.state.idx !== 0 ? "white" : "#555555"} />
                                </svg>

                                <div>
                                  Prev
                                </div>
                              </BackLink>
                              <BackLink selected={this.state.idx !== TUTORIAL_DATA[this.state.mode].length - 1} onClick={this.handleNextClick.bind(this)}>

                                <div>
                                  Next
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M3.1875 11.7872C3.0625 11.9122 3 12.0841 3 12.3029C3 12.5216 3.0703 12.7013 3.2109 12.8419C3.3516 12.9825 3.5312 13.0529 3.75 13.0529H18.4219L15.2344 16.2872C14.8594 16.631 14.8516 16.9825 15.2109 17.3419C15.5703 17.7013 15.9219 17.6935 16.2656 17.3185L20.7656 12.8185C20.8594 12.756 20.9219 12.6779 20.9531 12.5841C20.9844 12.4904 21 12.3966 21 12.3029C21 12.2091 20.9844 12.1154 20.9531 12.0216C20.9219 11.9279 20.8594 11.8497 20.7656 11.7872L16.2656 7.28723C15.9219 6.91223 15.5703 6.90442 15.2109 7.2638C14.8516 7.62317 14.8594 7.97473 15.2344 8.31848L18.4219 11.5529H3.75C3.5312 11.5529 3.3438 11.631 3.1875 11.7872Z" fill={this.state.idx !== TUTORIAL_DATA[this.state.mode].length - 1 ? "white" : "#555555"} />
                                </svg>
                              </BackLink>
                            </PrevNextArea>
                          </TabContainer>        
                          <Gutter h={10} />
                          {
                            currentStep ? 
                            <Fragment>
                              { currentStep.description }

                              <StepImg src={currentStep.url} alt={`${this.state.mode} / ${this.state.idx}`} />
                            </Fragment> : null
                          }
                          
                      
                      </div>

                    </CenterContainer>
                  </div>
                </CenterWrapper>
              </Container>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    streetviewTutorialPopup: state.streetviewTutorialPopup
  }
}

export default withRouter(connect(mapStateToProps)(StreetviewTutorialShow));