import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { VanishedIdentificationSection, NewStoreIdentificationSection, NewChainstoreIdentificationSection } from './identifywindows'

const Container = styled.div`
  position: absolute;
  background: white;
  left: 50%;
  bottom: 20px;
  min-width: 400px;
  transform: translate(-50%, 0);
  z-index:1000;

  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 10px;
  overflow: hidden;
`;


class IdentifyPlaceWindow extends Component {

  render() {
    let { mouseLocation, identificationCategory, identifyPlaceStep, currentStreetview, currentPanoID} = this.props;
    return (
      <Container>
        {
          Number(identificationCategory) === 2 ?
          <VanishedIdentificationSection currentPanoID={currentPanoID} /> : null
        }
        {
          Number(identificationCategory) === 3 ?
          <NewStoreIdentificationSection currentPanoID={currentPanoID} /> : null
        }
        {
          Number(identificationCategory) === 4 ?
          <NewChainstoreIdentificationSection currentPanoID={currentPanoID} /> : null
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    identificationCategory: state.identificationCategory,
    identifyPlaceStep: state.identifyPlaceStep,
    identifyPov: state.identifyPov,
    imageCoordinate: state.imageCoordinate,
    pickedLatLng: state.pickedLatLng,
    GSVPOV: state.GSVPOV
  }
}

export default connect(mapStateToProps)(IdentifyPlaceWindow);