import React, { Component } from 'react'
import styled from 'styled-components';
import { RED_COLOR } from '../constants/defaults';
import { calculateShareLimit } from '../utils';

const Container = styled.div`
  width: 100%;
  height: 18px;
  background: #222;
  position: relative;
  margin: 10px 0;
`;

const Graph = styled.div`
  @keyframes yoyo {
    0% {
      width: 0;
    }
    100% {
      width: ${props => props.w};
    }
  }

  animation: yoyo 1s ease-out 2s 1 normal forwards;
  position: absolute;
  left: 0;
  background: linear-gradient(90deg, #222 0%, ${ RED_COLOR } 100%);
  top: 0;
  height: 18px;
`;


class EngageGraph extends Component {
  render() {
    let { supports } = this.props;
    return (
      <Container>
        <Graph w={`${supports.length / calculateShareLimit(supports) * 100}%`} />
      </Container>
    )
  }
}

export default EngageGraph;