import React, { Component } from 'react'
import styled from 'styled-components';
import { Gutter, BlueButton } from '../stylesheets/components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { RED_COLOR } from '../constants/defaults';
import { connect } from 'react-redux';
import { changeTutorialPopup } from '../actions';

const Container = styled.div`
  /* position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 700px;
  background: #FFFFFF;
  border: 1px solid #E4F6F7;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px #1125AA;
  border-radius: 10px;
  padding: 15px; */
`;

const Title = styled.h2`
  font-size: 1.563em;
  font-family: "Heldane Display";
  color: ${RED_COLOR};
  padding-right: 20px;
  line-height: 1.2;
`;

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled.h3`
  font-size: 1.125em;
  font-family: "Heldane Display";
  color: white;
  margin: 10px 0;
`;


const Column = styled.div`
  width: calc(50% - 10px);
  
  p.desc {
    font-size: 0.875em;
    color: #999;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  b {
    font-weight: 600;
    color: #444;
  }
`;


const ClosebtnArea = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index:5;
`;


class FirstPopup extends Component {
  handlePopupTutorial(e){
    e.preventDefault();
    this.props.dispatch(changeTutorialPopup(true));
  }

  render() {
    return (
      <Container>

 
        {/* <Title>
          Please contribute your experience to <br/>expose storefront change issues!
        </Title> */}
        <Gutter h={20} />
        {/* <img src="/assets/identify_changes.gif" alt="an example of identifying changes" width="100%"/> */}
        <Gutter h={10} />
        <ColumnContainer>
          <Column>
            <p className="desc">
              1. If you are (or were) <span className="white">a resident in NYC</span>:
            </p>

            <Subtitle>
              Please identify the storefront changes using Street View Images, and document your memories of places.
            </Subtitle>
            <p className="desc">
              Find your neighborhood; nagivate the map or search streets with which you are familar. Find your beloved places, identify it, and add your memories. More tutorial: <a href="#" onClick={this.handlePopupTutorial.bind(this)}>here.</a>
            </p>
          </Column>

          <Column>
            <p className="desc">
              2. Or if you are (or were) <span className="white">a small business owner or community space organizer</span>: 
            </p>
            <Subtitle>
              Please tell us your experiences of surviving.
            </Subtitle>
            <p className="desc">
              Find your neighborhood and navigate to your store either using the right map or the search bar. You will find your place in a streetview. Identify it and enter how you survived. More tutorial: <a href="#" onClick={this.handlePopupTutorial.bind(this)}>here.</a>
            </p>
          </Column>

          
        </ColumnContainer>
      </Container>
    )
  }
}

export default connect()(FirstPopup);