import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';

const SmallWrap = styled.div`
  ${media.mobileSmall`
    transform: scale(0.8);
    transform-origin: right bottom;
  `}
`;


export default class LegendNeighborhood extends Component {
  render() {
    return (
      <SmallWrap>
        <svg width="283" height="166" viewBox="0 0 283 166" fill="none">
          <g filter="url(#filter0_d)">
          <rect width="280" height="163" rx="10" fill="black"/>
          <rect x="0.5" y="0.5" width="279" height="162" rx="9.5" stroke="#343434"/>
          </g>
          <rect x="13" y="43" width="76" height="49" fill="#9B7031" fill-opacity="0.4"/>
          <rect x="13" y="109" width="134" height="14" fill="url(#paint0_linear)"/>
          <rect x="173" y="43" width="32" height="49" fill="#7A7A7A" fill-opacity="0.4"/>
          <text fill="white" fontFamily="Source Sans Pro" font-size="13" fontWeight="600" letterSpacing="0em"><tspan x="18.1919" y="64.6215">East Village</tspan></text>
          <text fill="white" fontFamily="Source Sans Pro" font-size="12" letterSpacing="0em"><tspan x="44.0352" y="78.766">32 </tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" font-size="11" letterSpacing="0em"><tspan x="13" y="137.66">Less&#10;</tspan><tspan x="13" y="149.16">Changes</tspan></text>
          <text fill="#CBCBCB" fontFamily="Source Sans Pro" font-size="11" letterSpacing="0em"><tspan x="96" y="78.9105">Identified </tspan><tspan x="96" y="90.9105">changes</tspan></text>
          <text fill="#CBCBCB" fontFamily="Source Sans Pro" font-size="11" letterSpacing="0em"><tspan x="210" y="78.9105">Commercial&#10;</tspan><tspan x="210" y="90.9105">Area</tspan></text>
          <text fill="#CBCBCB" fontFamily="Source Sans Pro" font-size="11" letterSpacing="0em"><tspan x="210" y="127.91">Disappeared&#x2028;</tspan><tspan x="210" y="139.91">Stores</tspan></text>
          <text fill="#FF6746" fontFamily="Source Sans Pro" font-size="13" fontWeight="600" letterSpacing="0em"><tspan x="13" y="25.3715">Neighborhoods</tspan></text>
          <text fill="#797979" fontFamily="Source Sans Pro" font-size="11" letterSpacing="0em"><tspan x="124.883" y="137.66">More&#10;</tspan><tspan x="108.716" y="149.16">Changes</tspan></text>
          <g style={{ "mix-blend-mode": "screen" }} filter="url(#filter1_f)">
          <circle cx="189" cy="130" r="7" fill="#67A9CF"/>
          </g>
          <g style={{ "mix-blend-mode": "lighten" }} filter="url(#filter2_f)">
          <circle cx="189.5" cy="130.5" r="2.5" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_d" x="0" y="0" width="283" height="166" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dx="3" dy="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.403922 0 0 0 0 0.27451 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter1_f" x="178" y="119" width="22" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
          </filter>
          <filter id="filter2_f" x="183" y="124" width="13" height="13" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
          </filter>
          <linearGradient id="paint0_linear" x1="13" y1="116" x2="147" y2="116" gradientUnits="userSpaceOnUse">
          <stop stopColor="#051E0E"/>
          <stop offset="0.197917" stopColor="#434721"/>
          <stop offset="0.401042" stopColor="#726028"/>
          <stop offset="0.604167" stopColor="#9B7031"/>
          <stop offset="0.755208" stopColor="#C3793A"/>
          <stop offset="1" stopColor="#FF6044"/>
          </linearGradient>
          </defs>
        </svg>
      </SmallWrap>
    )
  }
}
