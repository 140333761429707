import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Gutter } from '../stylesheets/components';
import { toTitleCase } from '../utils';
import { withRouter } from 'react-router';
import moment from 'moment';
import { STORY_CATEGORY } from '../constants/defaults';
import { RED_COLOR } from '../constants/defaults';
import { truncate } from '../utils'; 
import { changeCurrentStory } from '../actions';
import { scaleLinear } from 'd3';

const Fragment = React.Fragment;

const Container = styled.div`
  position: absolute;

  background: ${props => STORY_CATEGORY[props.category_id].bgColor };
  border: 1px solid #1B1B1B;
  box-sizing: border-box;
  box-shadow: 3px 3px 0px black;
  border-radius: 10px;
  padding: 12px;
  left: 0;
  top: 0;
  z-index:5;
  width: 260px;
  transform: translate(-50%, -50%) scale(0.6);
  cursor: pointer;
`;

const Title = styled.div`
  color: ${props => STORY_CATEGORY[props.category_id].fgColor};
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 25px;
  font-size: 20px;
`;


const Category = styled.div`
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  color: ${props => STORY_CATEGORY[props.category_id].fgColor};
  margin-bottom: 3px;

  span.black {
    color: rgba(0, 0, 0, 1);
  }
`;

const DateArea = styled.div`
  border-top: 1px solid ${props => STORY_CATEGORY[props.category_id].borderColor};
  margin-top: 10px;
  padding-top: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
`;


const Date = styled.div`
  color: ${props => STORY_CATEGORY[props.category_id].fgColor};
  font-size:12px;
`;


const Contributor = styled.div`
  color: ${props => STORY_CATEGORY[props.category_id].fgColor};
  font-size:12px;
`;


class Story extends Component {
  constructor(props) {
    super(props);
    // dimensions of marker container (resized according to current pov)
    this.markerWidth = 200;
    this.markerHeight = 80;

    this.state = {
      zIndex: 5
    };

    this.zoomScale = scaleLinear().domain([1, 5]).range([0.6, 1.5]);
  }
  getStyle() {
    let { zIndex } = this.state;
 
    var { GSVPOV, panoid, geometry, windowHeight, containerWidth, pitch } = this.props;

    let panoIDPoint = new google.maps.LatLng(panoid.location[1], panoid.location[0]);
    let storyPoint = new google.maps.LatLng(geometry[1], geometry[0]);

    let heading = google.maps.geometry.spherical.computeHeading(panoIDPoint, storyPoint);
    

    let style = this.povToPixel3d({
      heading: heading,
      pitch: pitch
    }, GSVPOV, GSVPOV.zoom, {
      offsetWidth: containerWidth,
      offsetHeight: windowHeight
    });

    return {
      ...style,
      transform: `translate(-50%, -50%) scale(${this.zoomScale(GSVPOV.zoom)})`,
      zIndex: zIndex
    }
  }

    get3dFov(zoom) {
      return zoom <= 2 ?
        126.5 - zoom * 36.75 :  // linear descent
        195.93 / Math.pow(1.92, zoom); // parameters determined experimentally
    }

    povToPixel3d(targetPov, currentPov, zoom, viewport) {

      // Gather required variables and convert to radians where necessary
      var width = viewport.offsetWidth;
      var height = viewport.offsetHeight;
      var target = {
        left: width / 2,
        top: height / 2
      };

      var DEG_TO_RAD = Math.PI / 180.0;
      var fov = this.get3dFov(zoom) * DEG_TO_RAD;
      var h0 = currentPov.heading * DEG_TO_RAD;
      var p0 = currentPov.pitch * DEG_TO_RAD;
      var h = targetPov.heading * DEG_TO_RAD;
      var p = targetPov.pitch * DEG_TO_RAD;

      // f = focal length = distance of current POV to image plane
      var f = (width / 2) / Math.tan(fov / 2);

      // our coordinate system: camera at (0,0,0), heading = pitch = 0 at (0,f,0)
      // calculate 3d coordinates of viewport center and target
      var cos_p = Math.cos(p);
      var sin_p = Math.sin(p);

      var cos_h = Math.cos(h);
      var sin_h = Math.sin(h);

      var x = f * cos_p * sin_h;
      var y = f * cos_p * cos_h;
      var z = f * sin_p;

      var cos_p0 = Math.cos(p0);
      var sin_p0 = Math.sin(p0);

      var cos_h0 = Math.cos(h0);
      var sin_h0 = Math.sin(h0);

      var x0 = f * cos_p0 * sin_h0;
      var y0 = f * cos_p0 * cos_h0;
      var z0 = f * sin_p0;

      var nDotD = x0 * x + y0 * y + z0 * z;
      var nDotC = x0 * x0 + y0 * y0 + z0 * z0;

      // nDotD == |targetVec| * |currentVec| * cos(theta)
      // nDotC == |currentVec| * |currentVec| * 1
      // Note: |currentVec| == |targetVec| == f

      // Sanity check: the vectors shouldn't be perpendicular because the line
      // from camera through target would never intersect with the image plane
      if (Math.abs(nDotD) < 1e-6) {
        return {
          display: "none"
        };
      }


      // t is the scale to use for the target vector such that its end
      // touches the image plane. It's equal to 1/cos(theta) ==
      //     (distance from camera to image plane through target) /
      //     (distance from camera to target == f)
      var t = nDotC / nDotD;

      // Sanity check: it doesn't make sense to scale the vector in a negative
      // direction. In fact, it should even be t >= 1.0 since the image plane
      // is always outside the pano sphere (except at the viewport center)
      if (t < 0.0) {
        return {
          display: "none"
        };
      }

      // (tx, ty, tz) are the coordinates of the intersection point between a
      // line through camera and target with the image plane
      var tx = t * x;
      var ty = t * y;
      var tz = t * z;

      // u and v are the basis vectors for the image plane
      var vx = -sin_p0 * sin_h0;
      var vy = -sin_p0 * cos_h0;
      var vz = cos_p0;

      var ux = cos_h0;
      var uy = -sin_h0;
      var uz = 0;

      // normalize horiz. basis vector to obtain orthonormal basis
      var ul = Math.sqrt(ux * ux + uy * uy + uz * uz);
      ux /= ul;
      uy /= ul;
      uz /= ul;

      // project the intersection point t onto the basis to obtain offsets in
      // terms of actual pixels in the viewport
      var du = tx * ux + ty * uy + tz * uz;
      var dv = tx * vx + ty * vy + tz * vz;

      // use the calculated pixel offsets

      // use the calculated pixel offsets
      target.left += du;
      target.top -= dv;
      return {
        ...target,
        display: "block"
      }
      
    };


  handleClick(e){
    // this.props.dispatch(changeCurrentStory(this.props));

    this.props.history.push(`/stories/${this.props.id}`);
  }

  handleMouseEnter(e){
    this.setState({
      zIndex: 9999
    })
  }

  handleMouseLeave(e){

    this.setState({
      zIndex: 5
    })
  }

  _renderCategory(){
    let { category_id } = this.props;
    if (category_id === 0) {

      return (
        <Category category_id={category_id}>
          Support <span className="black">{this.props.poi.name}</span>:
        </Category>
       )

    } else if (category_id === 1) {
       return (
        <Category category_id={category_id}>
          How did you know <span className="white">{this.props.poi.name}</span>?
        </Category>
       )
    } else {
      return (
        <Category category_id={category_id}>
          What was the struggle of <span className="black">{this.props.poi.name}</span>?
        </Category>
      )
    }
  }

  render() {
    let style = this.getStyle();
    let { category_id } = this.props;
    // console.log(style);
    return (
      <Container category_id={category_id} style={style} onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)} onClick={this.handleClick.bind(this)}>
        {
          this._renderCategory()
        }
      
        <Title category_id={category_id}>
          “{truncate(this.props.description, 50)}”
        </Title>

        {/* <DateArea category_id={category_id}>
          <Contributor>
            {
              this.props.user ? 
              <Fragment>
                { this.props.user.screen_name }
              </Fragment> :
              <Fragment>
                Anonymous
              </Fragment>
            }
          </Contributor>
          <Date>
            {moment.unix(this.props.timestamp).format("MMMM YYYY")}
          </Date>
        </DateArea> */}
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    GSVPOV: state.GSVPOV,
    windowHeight: state.windowHeight
  }
}

export default withRouter(connect(mapStateToProps)(Story));