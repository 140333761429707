export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const changeIsFullScreen = (isFullScreen) => {
  return {
    type: "CHANGE_IS_FULL_SCREEN",
    payload: {
      isFullScreen: isFullScreen
    } 
  }
}

export const changeProgress = (progress) => {
  return {
    type: "CHANGE_PROGRESS",
    payload: {
      progress: progress
    }
  }
}

export const changeLeftContainerScrollTop = (leftContainerScrollTop) => {
  return {
    type: "CHANGE_LEFT_CONTAINER_SCROLL_TOP",
    payload: {
      leftContainerScrollTop: leftContainerScrollTop
    }
  }
}

export const changeCurrentSelectedMapEntity = (currentSelectedMapEntity) => {
  return {
    type: "CHANGE_CURRENT_SELECTED_MAP_ENTITY",
    payload: {
      currentSelectedMapEntity: currentSelectedMapEntity
    }
  }
}

export const changeIsCurrentPanoNow = (isCurrentPanoNow) => {
  return {
    type: "CHANGE_IS_CURRENT_PANO_NOW",
    payload: {
      isCurrentPanoNow: isCurrentPanoNow
    }
  }
}

export const changeTutorialPopup = (tutorialPopup) => {
  return {
    type: "CHANGE_TUTORIAL_POPUP",
    payload: {
      tutorialPopup: tutorialPopup
    }
  }
}


export const changeStreetviewTutorialPopup = (streetviewTutorialPopup) => {
  return {
    type: "CHANGE_STREETVIEW_TUTORIAL_POPUP",
    payload: {
      streetviewTutorialPopup: streetviewTutorialPopup
    }
  }
}

export const changeCurrentStory = (currentStory) => {
  return {
    type: "CHANGE_CURRENT_STORY",
    payload: {
      currentStory: currentStory
    }
  }
}

export const changeNeighborhoodSearchResults = (neighborhoodSearchResults) => {
  return {
    type: "CHANGE_NEIGHBORHOOD_SEARCH_RESULTS",
    payload: {
      neighborhoodSearchResults: neighborhoodSearchResults
    }
  }
}

export const changeFocusedNeighborhoods = (focusedNeighborhoods) => {
  return {
    type: "CHANGE_FOCUSED_NEIGHBORHOODS",
    payload: {
      focusedNeighborhoods: focusedNeighborhoods
    }
  }
}

export const changeCurrentPoi = (currentPoi) => {
  return {
    type: "CHANGE_CURRENT_POI",
    payload: {
      currentPoi: currentPoi
    }
  }
}

export const changeMapZoom = (mapZoom) => {
  return {
    type: "CHANGE_MAP_ZOOM",
    payload: {
      mapZoom: mapZoom
    }
  }
}
export const changeStoryCategory = (storyCategory) => {
  return {
    type: "CHANGE_STORY_CATEGORY",
    payload: {
      storyCategory: storyCategory
    }
  }
}


export const changeStoryStep = (storyStep) => {
  return {
    type: "CHANGE_STORY_STEP",
    payload: {
      storyStep: storyStep
    }
  }
}


export const changeStreetviewTab = (streetviewTab) => {
  return {
    type: "CHANGE_STREETVIEW_TAB",
    payload: {
      streetviewTab: streetviewTab
    }
  }
}

export const changeCurrentNeighborhood = (currentNeighborhood) => {
  return {
    type: "CHANGE_CURRENT_NEIGHBORHOOD",
    payload: {
      currentNeighborhood: currentNeighborhood
    }
  }
}

export const changeWelcomeCurrentTime = (welcomeCurrentTime) => {
  return {
    type: "CHANGE_WELCOME_CURRENT_TIME",
    payload: {
      welcomeCurrentTime: welcomeCurrentTime
    }
  }
}

export const changeNeighborhoodBoundaries = (neighborhoodBoundaries) => {
  return {
    type: "CHANGE_NEIGHBORHOOD_BOUNDARIES",
    payload: {
      neighborhoodBoundaries: neighborhoodBoundaries
    }
  }
}

export const changeSnackBar = (snackBar) => {
  return {
    type: "CHANGE_SNACK_BAR",
    payload: {
      snackBar: snackBar
    }
  }
};

export const changeIdentifyPov = (params) => {
  return {
    type: "CHANGE_IDENTIFY_POV",
    payload: {
      identifyPov: params.pov,
      imageCoordinate: params.imageCoordinate,
      pickedLatLng: params.pickedLatLng
    }
  }
}

export const changeIdentifyPlaceStep = (identifyPlaceStep) => {
  return {
    type: "CHANGE_IDENTIFY_PLACE_STEP",
    payload: {
      identifyPlaceStep: identifyPlaceStep
    }
  }
}

export const changeIdentificationActivated = (identificationActivated) => {
  return {
    type: "CHANGE_IDENTIFICATION_ACTIVATED",
    payload: {
      identificationActivated: identificationActivated
    }
  }
}

export const changeIdentificationCategory = (identificationCategory) => {
  return {
    type: "CHANGE_IDENTIFICATION_CATEGORY",
    payload: {
      identificationCategory: identificationCategory
    }
  }
}

export const changeGSVPOV = (GSVPOV) => {
  return {
    type: "CHANGE_GSVPOV",
    payload: {
      GSVPOV: GSVPOV
    }
  }
}
export const changeSelectedPanoidIndex = (selectedPanoIDIndex) => {
  return {
    type: "CHANGE_SELECTED_PANOID_INDEX",
    payload: {
      selectedPanoIDIndex: selectedPanoIDIndex
    }
  }
}

export const changeCameraMatrixWorld = (cameraMatrixWorld) => {


  return {
    type: "CHANGE_CAMERA_MATRIX_WORLD",
    payload: {
      cameraMatrixWorld: cameraMatrixWorld
    }
  }
}

export const changeActivePanoIDScreen = (activePanoIDScreen) => {
  return {
    type: "CHANGE_ACTIVE_PANOID_SCREEN",
    payload: {
      activePanoIDScreen: activePanoIDScreen
    }
  }
}

export const changeCurrentStreetview = (currentStreetview) => {
  return {
    type: "CHANGE_CURRENT_STREETVIEW",
    payload: {
      currentStreetview: currentStreetview
    }
  }
}
export const changeLoading = (loading) => {
  return {
    type: "CHANGE_LOADING",
    payload: {
      loading: loading
    }
  }
}

export const changeCSRFToken = (csrfToken) => {
  return {
    type: "CHANGE_CSRF_TOKEN",
    payload: {
      csrfToken: csrfToken
    }
  }
}

export const changeMapLoaded = (mapLoaded) => {
  return {
    type: 'CHANGE_MAP_LOADED',
    payload: {
      mapLoaded: mapLoaded
    }
  }
}

export const changeNoticeMessage = (noticeMessage) => {
  return {
    type: "CHANGE_NOTICE_MESSAGE",
    payload: {
      noticeMessage: noticeMessage
    }
  }
}

export const logIn = (current_user) => {
  return {
    type: "LOG_IN",
    payload: {
      current_user: current_user
    }
  }
}
export const signOut = () => {
  return {
    type: "SIGN_OUT",
    payload: {
    }
  }
}

export const changeLoginInfo = (
  new_user_session_path,
  current_user,
  user_signed_in,
  destroy_user_session_path
) => {
  // debugger;
  return {
    type: 'CHANGE_LOGIN_INFO',
    payload: {
      new_user_session_path,
      current_user,
      user_signed_in,
      destroy_user_session_path
    }
  }
}