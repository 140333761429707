import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeWelcomeCurrentTime } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import _ from 'lodash';
import { SmallLabel } from '../stylesheets/components';
import moment from 'moment';
import { withRouter } from 'react-router';
import { RED_COLOR } from '../constants/defaults';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const Container = styled.div`
  position: absolute;
  left: calc(50% + 20px);
  bottom: 20px;
  background: black;
  z-index: 5;
  border: 1px solid #111111;
  box-sizing: border-box;
  box-shadow: 3px 3px 0px ${RED_COLOR};
  border-radius: 10px;
  padding: 12px 15px;

  ${media.mobileLarge`
    left: 15px;
    bottom: 15px;
    right: inherit;
  `}

`;

const SelectArea = styled.div`
  margin-right: 10px;
  width: 200px;
`;


const TimelineArea = styled.div`

  div.wrapper {

    width: calc(50vw - 393px);
    display: flex;
    align-items: center;


    ${media.mobileLarge`
      width: calc(100vw - 135px);
    `}


    div.left, div.right {
      font-family: "Source Sans Pro";
      color: white;
      font-size:0.8em;
      width: 50px;
    }

    div.left {
      text-align: left;
      padding-right: 79px;
    }

    div.right {
      text-align: right;
      padding-left: 15px;
    }
  }
`;


const CustomSlider = withStyles({
  thumb: {
    backgroundColor: 'white'
  },
  valueLabel: {
    left: 'calc(-50% - 10px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: RED_COLOR,
      width: 150,
      fontFamily: "Source Sans Pro"
    },
  },
  track: {
    background: "#888"
  },
  colorPrimary: {
    color: "white"
  },
  markLabel: {
    display: "none"
  },
  mark: {
    width: 6,
    height: 6,
    transform: "translate(0, -2px)",
    borderRadius: 4
  },
  marked: {
    margin: 0
  },
  rail: {
    background: "rgba(80, 80, 80, 0.8)"
  },
})(Slider);

const RightMinize = styled.button`
  position: absolute;
  right: 13px;
  top: 11px;
  border: none;
  z-index: 999;
  padding:0;
  margin:0;
  background: transparent;
`;

class NeighborhoodTimeline extends Component {
  constructor(props){
    super(props);
  }

  handleSliderChange(e, value){
    this.props.dispatch(changeWelcomeCurrentTime(value));
  }

  render() {
    let { neighborhoodBoundaries, currentNeighborhood, welcomeCurrentTime, mapZoom } = this.props;
    let groupedBoundaries = _.groupBy(neighborhoodBoundaries, b => b.boro_name );

    return (
      <Container>
        <TimelineArea>
          <RightMinize>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g transform="translate(0 0)"  style={{cursor: "pointer"}} onClick={this.props.handleMinimize}>
                <rect x="0.5" y="0.5" width="17" height="17" stroke="#333" fill="black"/>
                <path d="M4 9H14" stroke="#C8C8C8"/>
              </g>
            </svg>
          </RightMinize>
          <SmallLabel>
            Timeline
          </SmallLabel>
          <div className="wrapper">
            <div className="left">
              2007
            </div>
            <CustomSlider
              value={welcomeCurrentTime}
              onChange={this.handleSliderChange.bind(this)}
              min={moment("2007-01-01").unix()}
              max={moment().unix()}
              valueLabelDisplay="on"
              valueLabelFormat={x => moment.unix(x).format("YYYY-MM")}
            />
            <div className="right">
              Now
            </div>
          </div>
        </TimelineArea> 
      
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    neighborhoodBoundaries: state.neighborhoodBoundaries,
    currentNeighborhood: state.currentNeighborhood,
    welcomeCurrentTime: state.welcomeCurrentTime,
    mapZoom: state.mapZoom
  };
}
export default withRouter(connect(mapStateToProps)(NeighborhoodTimeline));