import React, { Component, Fragment } from 'react'
import styled from 'styled-components';
import { changeCurrentStreetview, changeSelectedPanoidIndex, changeStreetviewTab, changeCurrentPoi, changeCurrentStory, changeNoticeMessage, changeStreetviewTutorialPopup, changeProgress } from '../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { PanoIDViewer, StreetviewHeader, ChangeButtonContainer, StoryPanoIDViewer, StoryButtonContainer, CurrentPoiHandler, StoryShow } from '../components';
import Cookies from 'js-cookie';

const Container = styled.div`
  position:fixed;
  
  left: 0;
  top: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);

  color: white;
  display: flex;
  justify-content: space-between;
`;

const ArrowArea = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 60;
  transform: translate(-50%, -50%);
`;

class StreetviewShow extends Component {
  constructor(props){
    super(props);

    this.state = {
      storyLoading: false,
      poiLoading: false,
      streetviewLoading: false
    };
  }


  componentDidMount() {
    if (!Cookies.get('streetview-tutorial-show')) {
      this.props.dispatch(changeStreetviewTutorialPopup(true));
      Cookies.set('streetview-tutorial-show', true)
    }


    // this.props.dispatch(changeTutorialPopup(true));
    this.updatePage();

    if (this.props.streetviewTab === 1) {

      this.props.dispatch(changeSelectedPanoidIndex(0));

    } else if (this.props.streetviewTab === 0) {

      this.props.dispatch(changeSelectedPanoidIndex(1));
    }

  }
  
  componentWillUnmount(){

    this.props.dispatch(changeCurrentPoi(null));
    // debugger;
  }
  componentDidUpdate(prevProps) {

    this.updatePage(prevProps);
    
  }

  updatePage(prevProps){
    if (this.props.match.path === "/pois/:gid") {

      this.props.dispatch(changeCurrentStory(null));
      this.updateCurrentPoi(prevProps);

    } else if (this.props.match.path === "/streetviews/:streetview_id/stories") {
      // debugger;
      this.props.dispatch(changeCurrentPoi(null));
      this.props.dispatch(changeCurrentStory(null));
      if (prevProps) {
        if (this.props.match.path !== prevProps.match.path) {
          this.updateTab();
        }
      }
     
      this.updateStreetView(prevProps);
    } else if (this.props.match.path === "/stories/:story_id") {
      
      if (prevProps) {
        if (this.props.match.path !== prevProps.match.path) {
          this.updateTab();
        }
      }

      this.updateCurrentStory(prevProps);
      
    } else if (this.props.match.path === "/streetviews/:streetview_id") {
      this.props.dispatch(changeCurrentPoi(null));
      this.props.dispatch(changeCurrentStory(null));
      // debugger;
      if (prevProps) {
        if (this.props.match.path !== prevProps.match.path) {
          this.updateTab();
        }
      }
      
      this.updateStreetView(prevProps);

    }

  }

  updateCurrentStory(prevProps){
    if (prevProps) {

      if (prevProps.match) {
        if (Number(prevProps.match.params.story_id) !== Number(this.props.match.params.story_id)) {
          // debugger;
          this.loadCurrentStory(this.props.match.params.story_id);
        } else if (!this.props.currentStory) {
          this.loadCurrentStory(this.props.match.params.story_id);
        }
      }
      
    }
    
  }

  updateCurrentPoi(prevProps){
    if (prevProps) {

      if (prevProps.match) {
        if (Number(prevProps.match.params.gid) !== Number(this.props.match.params.gid)) {
          // debugger;
         this.loadCurrentPoi(this.props.match.params.gid);
        } else if (!this.props.currentPoi) {
          this.loadCurrentPoi(this.props.match.params.gid);
        }
      }
      
    }
  }

  updateTab(){
    
    if (this.props.match.path.indexOf("stories") > -1){
      this.props.dispatch(changeStreetviewTab(1));
    } else {
      this.props.dispatch(changeStreetviewTab(0));  
    }
    
  }

  updateStreetView(prevProps) {
    if (prevProps) {

      if (prevProps.match) {
        if (Number(prevProps.match.params.streetview_id) !== Number(this.props.match.params.streetview_id)) {
          // debugger;
          this.loadCurrentStreetview(this.props.match.params.streetview_id);
        } else if (!this.props.currentStreetview) {
          this.loadCurrentStreetview(this.props.match.params.streetview_id);
        }
      }
      
    }

  }


  async loadCurrentStory(story_id) {
    if (!this.state.storyLoading) {
      this.setState({
        storyLoading: true
      });
      const headers = {
        'X-CSRF-Token': this.props.csrfToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
  
      // this.props.dispatch(changeNoticeMessage("Loading..."));
      var url = `/stories/${story_id}.json`;
      var skipStreet = false;
      if (this.props.currentStreetview) {
        // console.log(this.props.match.params.streetview_id, this.props.currentStreetview.gid);
        if (Number(this.props.match.params.streetview_id) === Number(this.props.currentStreetview.gid)) {
          skipStreet = true;
        }
      }
      if (skipStreet) {
        url = `${url}?skip_street=1`;
      }
      this.props.dispatch(changeProgress(true));
      const response = await fetch(url, { method: "get" });
      if (response.ok) {
        let data = await response.json();
        if (!skipStreet) {
          this.props.dispatch(changeCurrentStreetview(data.streetview));
        }
        this.props.dispatch(changeStreetviewTab(1));
  
        this.props.dispatch(changeCurrentStory(data.story));
        this.props.dispatch(changeProgress(false));

        this.setState({
          storyLoading: false
        });
      } else {
        let data = await response.json();
  
        this.setState({
          storyLoading: false
        });
        this.props.dispatch(changeProgress(false));
        return data;
      }
    }
    
  }
  async loadCurrentPoi(gid) {
    if (!this.state.poiLoading) {
      this.setState({
        poiLoading: true
      });

      const headers = {
        'X-CSRF-Token': this.props.csrfToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
      
      var url = `/pois/${gid}.json`;
      var skipStreet = false;
      if (this.props.currentStreetview) {
        // console.log(this.props.match.params.streetview_id, this.props.currentStreetview.gid);
        if (Number(this.props.match.params.streetview_id) === Number(this.props.currentStreetview.gid)) {
          skipStreet = true;
        }
      }
      if (skipStreet) {
        url = `${url}?skip_street=1`;
      }

      this.props.dispatch(changeProgress(true));
      const response = await fetch(url, { method: "get" });
      if (response.ok) {
        let data = await response.json();
        if (!skipStreet) {
          this.props.dispatch(changeCurrentStreetview(data.streetview));
          if (data.streetview.panoids.length === 1) {
            this.props.dispatch(changeSelectedPanoidIndex(0));
          }
        }
        
        // since it's about poi's story, 
        this.props.dispatch(changeStreetviewTab(1));
        this.props.dispatch(changeCurrentPoi(data.current_poi));
        this.setState({
          poiLoading: false
        });
        this.props.dispatch(changeProgress(false));
  
      } else {
        let data = await response.json();
        this.setState({
          poiLoading: false
        });
        this.props.dispatch(changeProgress(false));
        return data;
      }
    }
  }
  
  async loadCurrentStreetview(gid) {
    if (!this.state.streetviewLoading) {

      this.setState({
        streetviewLoading: true
      });
      var skipStreet;

      if (this.props.currentStreetview) {
        // console.log(this.props.match.params.streetview_id, this.props.currentStreetview.gid);
        if (Number(this.props.match.params.streetview_id) === Number(this.props.currentStreetview.gid)) {
          skipStreet = true;
        }
      }

      if (!skipStreet) {
        const headers = {
          'X-CSRF-Token': this.props.csrfToken,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
    
        this.props.dispatch(changeProgress(true));
        const response = await fetch(`/streetviews/${gid}.json`, { method: "get" });
        if (response.ok) {
          let data = await response.json();
    
          this.props.dispatch(changeCurrentStreetview(data.streetview));
    
          if (data.streetview.panoids.length > 0) {
            if (this.props.streetviewTab === 1) {
    
              this.props.dispatch(changeSelectedPanoidIndex(0));
            
            } else if (this.props.streetviewTab === 0) {
            
              this.props.dispatch(changeSelectedPanoidIndex(1));
            }
          }
          this.updateTab();
          this.props.dispatch(changeProgress(false));
    
        } else {
          let data = await response.json();
          this.props.dispatch(changeProgress(false));
    
          return data;
        }
      }
    } 
   
  }

  _renderPoi() {
    let { leftPanoID, rightPanoID } = this.getLeftRightPanoID();
    let { isCurrentPanoNow, currentStreetview, windowWidth, selectedPanoIDIndex, streetviewTab, currentPoi, currentStory } = this.props;
    let panoidCount = 0;
    
    if (currentStreetview) {
      panoidCount = currentStreetview.panoids.length;
    }

    return (
      <Container>
        {
          panoidCount > 0 ? 
          <StreetviewHeader mode="half" /> : null

        }
        {
          currentPoi ?
          <Fragment>
            <CurrentPoiHandler currentPanoID={rightPanoID} />
            <StoryPanoIDViewer screenID="story" mode="half" now={isCurrentPanoNow} currentPanoID={rightPanoID} width={windowWidth * 0.5} />
          </Fragment>
           : null
        }
        <StoryButtonContainer mode="half" />
      </Container>
    )
  }

  _renderStory(){
    let { leftPanoID, rightPanoID } = this.getLeftRightPanoID();
    let { isCurrentPanoNow, currentStreetview, windowWidth, selectedPanoIDIndex, streetviewTab, currentPoi, currentStory } = this.props;
    let panoidCount = 0;
    
    if (currentStreetview) {
      panoidCount = currentStreetview.panoids.length;
    }

    return (
      <Container>
        {
          panoidCount > 0 ? 
          <StreetviewHeader mode="half" /> : null
        }
        {
          currentStory ? 
          <Fragment>
            <StoryShow  currentPanoID={rightPanoID}  />
            <StoryPanoIDViewer screenID="story" mode="half" now={isCurrentPanoNow} currentPanoID={rightPanoID} width={windowWidth * 0.5} />
          </Fragment> : null 
        }
        <StoryButtonContainer mode="half"  />
      </Container>
    )

  }

  _renderIdentify(){
    let { leftPanoID, rightPanoID } = this.getLeftRightPanoID();
    let { isCurrentPanoNow, currentStreetview, windowWidth, selectedPanoIDIndex, streetviewTab, currentPoi, currentStory } = this.props;
    let panoidCount = 0;
    
    if (currentStreetview) {
      panoidCount = currentStreetview.panoids.length;
    }

    return (
      <Container>
        {
          panoidCount > 0 ? 
          <StreetviewHeader mode="full" /> : null

        }
        {
          leftPanoID ?
            <PanoIDViewer screenID="left" now={false} currentPanoID={leftPanoID} width={windowWidth * 0.5} /> : null
        }
        <ArrowArea>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" transform="scale(2)">
            <path d="M3.1875 12.2128C3.0625 12.0878 3 11.9159 3 11.6971C3 11.4784 3.07031 11.2987 3.21094 11.1581C3.35156 11.0175 3.53125 10.9471 3.75 10.9471L18.4219 10.9471L15.2344 7.71277C14.8594 7.36901 14.8516 7.01746 15.2109 6.65808C15.5703 6.2987 15.9219 6.30652 16.2656 6.68152L20.7656 11.1815C20.8594 11.244 20.9219 11.3221 20.9531 11.4159C20.9844 11.5096 21 11.6034 21 11.6971C21 11.7909 20.9844 11.8846 20.9531 11.9784C20.9219 12.0721 20.8594 12.1503 20.7656 12.2128L16.2656 16.7128C15.9219 17.0878 15.5703 17.0956 15.2109 16.7362C14.8516 16.3768 14.8594 16.0253 15.2344 15.6815L18.4219 12.4471L3.75 12.4471C3.53125 12.4471 3.34375 12.369 3.1875 12.2128Z" fill="white" />
          </svg>
        </ArrowArea>
        {
          rightPanoID ?
            <PanoIDViewer screenID="right" now={false} currentPanoID={rightPanoID} width={windowWidth * 0.5} /> : null
        }
        {
          isCurrentPanoNow ?
          <PanoIDViewer screenID="right" now={true} currentPanoID={leftPanoID} width={windowWidth * 0.5} /> : null
        }

        <ChangeButtonContainer />
      </Container>
    )
  }

  _renderAddStories() {
    let { leftPanoID, rightPanoID } = this.getLeftRightPanoID();
    let { isCurrentPanoNow, currentStreetview, windowWidth, selectedPanoIDIndex, streetviewTab, currentPoi, currentStory } = this.props;
    let panoidCount = 0;
    
    if (currentStreetview) {
      panoidCount = currentStreetview.panoids.length;
    }

    return (
      <Container>
        {
          panoidCount > 0 ? 
          <StreetviewHeader mode="full" /> : null

        }
        <StoryPanoIDViewer screenID="story" mode="full" now={isCurrentPanoNow} currentPanoID={rightPanoID} width={windowWidth} />
        <StoryButtonContainer mode="full" />
      </Container>
    )
  }

  getLeftRightPanoID(){
    let leftPanoID, rightPanoID;
    let { isCurrentPanoNow, currentStreetview, windowWidth, selectedPanoIDIndex, streetviewTab, currentPoi, currentStory } = this.props;
    
    if (currentStreetview) {
      if (currentStreetview.panoids.length > 0) {

        if (streetviewTab === 1) { // if the tab is add stories, then pano id is allocated individually.
           if (isCurrentPanoNow) {
            rightPanoID = currentStreetview.panoids[currentStreetview.panoids.length - 1];  
          } else {
            rightPanoID = currentStreetview.panoids[selectedPanoIDIndex];
          }
          
          

        } else if (streetviewTab === 0) { // if it's not, left right pano ..

          if (selectedPanoIDIndex === 0) {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex];
            rightPanoID = currentStreetview.panoids[selectedPanoIDIndex + 1];

          } else {

            leftPanoID = currentStreetview.panoids[selectedPanoIDIndex - 1];
            rightPanoID = currentStreetview.panoids[selectedPanoIDIndex];


          }

        }
        
      }
    }

    return {
      leftPanoID,
      rightPanoID
    };
  }  

  render() {

    let { streetviewTab, currentPoi, currentStory } = this.props;
    
    if (currentStory) {
      return this._renderStory();
    } else if (currentPoi) {
      return this._renderPoi();
    } else if (streetviewTab === 0) {
      return this._renderIdentify();
    } else {
      return this._renderAddStories();
    }
     
  }
}

let mapStateToProps = state => {
  return {
    currentStreetview: state.currentStreetview,
    csrfToken: state.csrfToken,
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    selectedPanoIDIndex: state.selectedPanoIDIndex,
    streetviewTab: state.streetviewTab,
    currentPoi: state.currentPoi,
    currentStory: state.currentStory,
    isCurrentPanoNow: state.isCurrentPanoNow
  }
}


export default withRouter(connect(mapStateToProps)(StreetviewShow));

