import React, { Component } from 'react'
import { connect } from 'react-redux';
import { IdentificationList, StoryList, Logo, EngageGraph } from './';
import { Gutter, BlueButton, LeftContainer, BackLink, TitleArea, Tab, TabButton, LogoLink } from '../stylesheets/components';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { changeGSVPOV, changeSelectedPanoidIndex, changeStreetviewTab, changeIsCurrentPanoNow, changeNoticeMessage, changeStoryCategory, changeStoryStep, changeLeftContainerScrollTop } from '../actions';
import { convertTitle, findNearestPanoID, calculateShareLimit, numberWithDelimiter } from '../utils';

const Fragment = React.Fragment;
const PoiShowContainer = styled(LeftContainer)`
  background: black;
  padding-top: 20px;

  height: calc(100vh - 20px);

`;

const Content = styled.div`

`;

const BackLinkArea = styled.div`
  border-bottom: 1px solid #565656;
  display: flex;
  padding-bottom: 5px;
	flex-direction: row-reverse;
`;

const EngageArea = styled.div`

  border-top: 1px solid #565656;
  border-bottom: 1px solid #565656;
  padding: 15px 0 10px 0;

  display: flex;
  justify-content: space-between;

  div.left {
    width: calc(60% - 20px);
  }

  div.right {
    width: calc(40% - 20px);

  }

  p.small {
    font-size:0.9em;
  }
`;

const GInfoArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  div.ginfo-left {
    margin-right: 10px;
    margin-top:2px;
  }

  div.ginfo-right {
    font-size:0.9em;
    color: white;

    a {
      color: white;
      text-decoration: underline;
    }
  }
`;


const EngageTitleArea = styled.div`
  display: flex;
  align-items: center;
`;

const EngageTitle = styled.div`
  font-size:0.9em;
  margin-left: 10px; 
`;

class CurrentPoiHandler extends Component {
  constructor(props){
    super(props);
    this.state = {
      mode: "stories"
    };
  }

  componentDidMount(){
    this.changeHeadingAndPanoidIdx();
  }

  componentDidUpdate(prevProps){
    if (prevProps.currentPoi.id !== this.props.currentPoi.id) {
      this.changeHeadingAndPanoidIdx();
    }
  }

  changeHeadingAndPanoidIdx(){
    let { currentPoi, currentPanoID, GSVPOV, currentStreetview } = this.props;

    let panoidIdx = findNearestPanoID(currentPoi, currentStreetview);//_.findIndex(currentStreetview.panoids, panoid => panoid.pano_id === currentPoi.possible_pano_id);
    let panoIDPoint = new google.maps.LatLng(currentPanoID.location[1], currentPanoID.location[0]);
    let poiPoint = new google.maps.LatLng(currentPoi.location[1], currentPoi.location[0]);

    let heading = google.maps.geometry.spherical.computeHeading(panoIDPoint, poiPoint);

    // console.log("changeHeadingAndPanoidIdx", Math.max(0, panoidIdx), heading);

    if (panoidIdx === currentStreetview.panoids.length) {
      // currentpano is now
      this.props.dispatch(changeSelectedPanoidIndex(Math.max(0, panoidIdx)));
      this.props.dispatch(changeIsCurrentPanoNow(true));
    } else {

      this.props.dispatch(changeSelectedPanoidIndex(Math.max(0, panoidIdx)));
      this.props.dispatch(changeIsCurrentPanoNow(false));
    }

    this.props.dispatch(changeGSVPOV({
      ...GSVPOV,
      heading: heading
    }));
    this.props.dispatch(changeStreetviewTab(1));

  }

  handleBackLinkClick(e){
    let { currentStreetview } = this.props;
    this.props.history.push(`/streetviews/${currentStreetview.gid}`);
  }
  
  handleTabButtonClick(mode) {
    this.setState({
      mode: mode
    });
  }

  _renderHistories() {
    let { currentPoi } = this.props;
    return (
      <Content>
      
        {
          currentPoi.identifications.length > 0 ?
          _.map(currentPoi.identifications, identification => {
            return (
              <IdentificationList identification={identification} key={identification.id} />
            );
          })
         :
        <p className="small">
          No historical changes identified yet.
        </p>
      
        }
      </Content>
    )
  }

  _renderStories() {
    let { currentPoi } = this.props;
    return (
      <Content>
        {
          currentPoi.stories.length > 0 ? 
          _.map(currentPoi.stories, story => {
            return (
              <StoryList story={story} key={story.id} />
            );
          }) : 
          <p className="small">
            No stories and support yet. 
          </p>
        }
      </Content>
    )

  }
  

  handleTwitterClick(e){

    this.props.dispatch(changeStoryCategory(0)); // support twitter
    this.props.dispatch(changeNoticeMessage("Point out where to put your support; it should be on the right street view."))
    this.props.dispatch(changeStoryStep(1));

  }

  handleScroll(e){
    this.props.dispatch(changeLeftContainerScrollTop(e.target.scrollTop));
  }
  render() {
    let { mode } = this.state;
    let { currentPoi } = this.props;

    let supports = _.filter(currentPoi.stories, story => story.category_id === 0);
    return (
      <Fragment>
        <LogoLink to="/contribute">
          <Logo />
        </LogoLink>
        <PoiShowContainer onScroll={this.handleScroll.bind(this)}>
          <Gutter h={38} />
          <BackLinkArea>
            <BackLink selected={true} onClick={this.handleBackLinkClick.bind(this)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.8125 11.7872C20.9375 11.9122 21 12.0841 21 12.3029C21 12.5216 20.9297 12.7013 20.7891 12.8419C20.6484 12.9825 20.4688 13.0529 20.25 13.0529H5.57812L8.76562 16.2872C9.14063 16.631 9.14844 16.9825 8.78906 17.3419C8.42969 17.7013 8.07813 17.6935 7.73438 17.3185L3.23438 12.8185C3.14062 12.756 3.07812 12.6779 3.04688 12.5841C3.01562 12.4904 3 12.3966 3 12.3029C3 12.2091 3.01562 12.1154 3.04688 12.0216C3.07812 11.9279 3.14062 11.8497 3.23438 11.7872L7.73438 7.28723C8.07813 6.91223 8.42969 6.90442 8.78906 7.2638C9.14844 7.62317 9.14063 7.97473 8.76562 8.31848L5.57812 11.5529H20.25C20.4688 11.5529 20.6562 11.631 20.8125 11.7872Z" fill="white" />
              </svg>

              <div>
                Back to Streetview
              </div>
            </BackLink>
          </BackLinkArea>
          <Gutter h={10} />
          <TitleArea>
              <div className="left">
                <h2 className="title">
                  {currentPoi.name}
                </h2>
                <Gutter h={5} />
                <p className="small">
                  { convertTitle(currentPoi.category) }, { currentPoi.disappeared_at ? 
                    <Fragment>
                      currently identified as <span className="blue"><b>closed business</b></span>
                    </Fragment> : 
                    <Fragment>
                      currently identified as <span className="white"><b>running business</b></span>
                    </Fragment>
                  }
                </p>
              </div>
              <div className="right">
                <p className="small">
                  {
                    currentPoi.history ?
                    <b><span className="white">{ currentPoi.history } </span></b> :
                    <span className="white"><b>Currently not identified any history yet.</b> <br/>
                    <a href="#">You can help to identify when {currentPoi.name } appeared or disappeared.</a></span>
                  }
                </p>
              </div>
            </TitleArea>
            <Gutter h={10} />
            <EngageArea>
              <div className="left">
                <EngageTitleArea>
                  <img src={`/assets/${currentPoi.disappeared_at ? "commermorate_icon" : "support_icon"}.svg`} alt="campaign icon" /> 
                  <EngageTitle>
                    {
                      currentPoi.disappeared_at ?
                      <span className="white"><b>Commemorate the place:</b></span> :
                      <span className="white"><b>Don’t let them die:</b></span>
                    }
                    <br/>
                    Social Media Outreach Campaign
                  </EngageTitle>
                </EngageTitleArea>
                <EngageGraph supports={supports} />
                <p className="small">
                <span className="white"><b>{ supports.length }</b></span> people made {currentPoi.name} reach out to their social media.&nbsp;
                  <span className="white"><b>Let’s get to {numberWithDelimiter(calculateShareLimit(supports))}!</b></span>
                </p>
                <Gutter h={10} />
                <BlueButton selected={true} onClick={this.handleTwitterClick.bind(this)}>
                  Share support of {currentPoi.name} on Twitter
                </BlueButton>
                <Gutter h={10} />
              </div>

              <div className="right">
                {
                  currentPoi.google_place_added ? 
                  <Fragment>
                    <GInfoArea >
                      <div className="ginfo-left">
                        <img src="/assets/external_link_icon.svg" alt="external_link_icon" />
                      </div>

                      <div className="ginfo-right">
                        <a href={currentPoi.google_place_url} target="_blank">View on Google Places</a>
                      </div>
                    </GInfoArea>
                    <GInfoArea>
                      <div className="ginfo-left">
                        <img src="/assets/phone_icon.svg" alt="external_link_icon" />
                      </div>

                      <div className="ginfo-right">
                        { currentPoi.google_place_phone }
                      </div>
                    </GInfoArea>

                    <GInfoArea>
                      <div className="ginfo-left">
                        <img src="/assets/address_icon.svg" alt="external_link_icon" />
                      </div>

                      <div className="ginfo-right">
                        { currentPoi.google_place_address }
                      </div>
                    </GInfoArea>
                  </Fragment> : 
                  <p className="small">
                    <span className="white"><b>No information on Google Places.</b></span>
                  </p>
                }
              </div>
            </EngageArea>
            <Gutter h={10} />
            <Tab>
              <TabButton selected={this.state.mode === "stories"} onClick={this.handleTabButtonClick.bind(this, "stories")}>
                Stories and Support
              </TabButton>

              <TabButton selected={this.state.mode === "histories"} onClick={this.handleTabButtonClick.bind(this, "histories")}>
                Changes
              </TabButton>
            </Tab>
            
            {
              mode === "histories" ?
              this._renderHistories() : null
            }
            {
              mode === "stories" ?
              this._renderStories() : null
            }
        </PoiShowContainer>
      </Fragment>
      
    )
  }
}

let mapStateToProps = state => {
  return {
    currentPoi: state.currentPoi,
    GSVPOV: state.GSVPOV,
    currentStreetview: state.currentStreetview
  }
}

export default withRouter(connect(mapStateToProps)(CurrentPoiHandler));
